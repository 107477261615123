import { PropsWithChildren } from 'react';

import * as Styles from '../product-page.styled';

import { IPropsModalImage } from '../types';

export const ModalImage = ({
    children,
    isModalVisible,
    handleCancel,
}: PropsWithChildren<IPropsModalImage>) => {
    return (
        <Styles.ModalImage
            visible={isModalVisible}
            footer={null}
            onCancel={handleCancel}
            className="modalStyle"
        >
            {children}
        </Styles.ModalImage>
    );
};

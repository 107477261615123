import { PropsWithChildren, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { IProfileSettingsBlockProps } from './types';
import { ProfileSettingItem } from './profile-setting-item';

import * as Styles from './profile-settings-block.styled';

const ProfileSettingsBlock = ({
    items,
    styles,
    children,
}: PropsWithChildren<IProfileSettingsBlockProps>) => {
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
    const [activeElement, setActiveElement] = useState<number | null>(
        isMobile ? null : 0
    );
    return (
        <Styles.Wrapper styles={styles}>
            {items.map((item, i) => {
                return (
                    <ProfileSettingItem
                        i={i}
                        key={item.name + i}
                        setActiveElement={setActiveElement}
                        activeElement={activeElement}
                        item={item}
                        children={children}
                    />
                );
            })}
        </Styles.Wrapper>
    );
};

export default ProfileSettingsBlock;

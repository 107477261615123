import styled from 'styled-components';
import { Menu } from 'antd';
import { theme } from 'theme';

export const UserMenu = styled(Menu)`
    .logout-section.ant-dropdown-menu-item-active {
        background-color: ${theme.colors.background_red};
    }
`;

export const MenuItem = styled.label<{ isLogout?: boolean }>`
    font-size: ${theme.fonts.sizes.xxs};
    font-width: ${theme.fonts.weights.medium};
    cursor: pointer;
    color: ${({ isLogout }) =>
        isLogout ? theme.colors.primary_red : theme.colors.dark_grey};
    font-family: ${theme.fonts.families.poppins};
`;

export const MenuIconWrapper = styled.div<{ isLogout?: boolean }>`
    .menu-icon {
        padding-top: ${theme.spaces.xxxs};
        color: ${({ isLogout }) =>
            isLogout ? theme.colors.primary_red : theme.colors.dark_grey};
        font-size: 13px;
    }
`;

export const Avatar = styled.img<{ isPointer?: boolean; }>`
    border-radius: 100%;
    object-fit: cover;
    width: 30px;
    height: 30px;
    margin: ${theme.spaces.xxs} ${theme.spaces.xs};
    cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};
    @media screen and (max-width: 470px) {
        margin-right: ${theme.spaces.xxs};
        margin-left: 0;
    }
    &:hover {
        filter: brightness(97%);
    }
`;

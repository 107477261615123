import { ChangeEvent, Dispatch, MutableRefObject, SetStateAction } from 'react';

import { IUser } from 'common/types';
import { ICollection } from 'common/types';

export enum CreatePageItemTypes {
    Collab = 'collab',
    Nft = 'nft',
}

export interface IFormItemProps {
    name: string;
    message: string;
    placeholder?: string;
    type?: 'email' | 'string';
    pattern?: RegExp;
    isCreateNft: boolean;
    optionsArray: (
        | never
        | {
              name: string;
          }
        | ICollection
    )[];
    setIsError: Dispatch<string>;
    isError: string;
}

export interface IFormInputItemProps {
    name: string;
    message: string;
    placeholder?: string;
    type?: 'email' | 'string';
    pattern?: RegExp;
}

export interface IPropsActionSelectFiled {
    inputValue: string;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    InputRef: MutableRefObject<any>;
    isError?: string;
}

export interface IPropsAddCollectionField extends IPropsActionSelectFiled {
    handleCreateCollection: () => void;
    isInputVisible: boolean;
}

export interface IFile {
    uid: string;
    name: string;
    status: string;
    url: string;
}

export interface IPropsRenderUploadComponent {
    fileList: any[];
    setFileList: Dispatch<SetStateAction<IFile[]>>;
    setFieldsValue: (values: any) => void;
    setIsErrorUpload: Dispatch<SetStateAction<string>>;
    isErrorUpload: string;
}

export interface IPropsSelectTags {
    tags: IUser['tags'];
    setNewOption: Dispatch<SetStateAction<string[]>>;
    newOption: string[];
}

import styled from 'styled-components';
import { Button } from 'antd';

import { theme } from 'theme';

export const NotLogInWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${theme.spaces.md};
    @media screen and (max-width: 570px) {
        justify-content: flex-end;
        margin-right: ${theme.spaces.xxs};
    }
`;

export const LogInButton = styled(Button)`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: 16px;
    cursor: pointer;
    color: #333560;
    padding: 0 ${theme.spaces.xl};
    height: 40px;
    background: white;
    border-radius: 26px;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spaces.md};
    margin-left: ${theme.spaces.xxs};
    border: 1px solid transparent;

    @media screen and (max-width: 1100px) {
        padding: 0 ${theme.spaces.md};
    }
    @media screen and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 924px) {
        margin-right: 5px;
        font-size: ${theme.fonts.sizes.xs};
    }
    @media screen and (max-width: 805px) {
        font-size: 14px;
    }
    @media screen and (max-width: 700px) {
        margin-right: 5px;
    }
    @media screen and (max-width: 580px) {
        margin-right: 5px;
    }
`;

export const SignUpButton = styled(Button)`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: 16px;
    background-color: ${theme.colors.light_purple};
    border-radius: 26px;
    color: ${theme.colors.primary_white};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 ${theme.spaces.xl};
    height: 40px;
    margin-right: 20px;
    @media screen and (max-width: 1100px) {
        padding: 0 ${theme.spaces.md};
    }
    @media screen and (max-width: 700px) {
        margin-right: 5px;
    }
    @media screen and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 925px) {
        font-size: ${theme.fonts.sizes.xs};
    }
    @media screen and (max-width: 805px) {
        font-size: 14px;
    }
    @media screen and (max-width: 690px) {
        margin-left: ${theme.spaces.lg};
    }
`;

import {
    IInitialUserState,
    IInitialCollectionState,
    IInitialCollectionItemsState,
    IInitialCountryState,
} from 'common/types';

export const changeIsLoading = (
    state:
        | IInitialUserState
        | IInitialCollectionState
        | IInitialCollectionItemsState
        | IInitialCountryState
) => {
    state.isLoading = !state.isLoading;
};

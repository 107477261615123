import { LINKS_SOCIAL, SOCIAL_ICONS } from '../constants';
import { IPropsRenderUserInfo } from '../types';

import { SocialBox, SocialItem, SocialImage } from '../account-page.styled';

export const RenderSocialIcons = ({ user }: IPropsRenderUserInfo) => {
    return (
        <>
            <SocialBox>
                {SOCIAL_ICONS.map(({ name, image }) => {
                    const url: string | undefined = user[name];
                    const link = url
                        ? `${LINKS_SOCIAL[name]}${url.split('@')[1]}`
                        : '';

                    return (
                        url && (
                            <SocialItem key={name} href={link}>
                                <SocialImage src={image} alt={name} />
                            </SocialItem>
                        )
                    );
                })}
            </SocialBox>
        </>
    );
};

import { useMediaQuery } from 'react-responsive';

import { LoginLayout } from './LoginLayout';
import Header from 'components/header';

import { MobileContainer, Container } from './login.styled';

const Login = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <Container>
            {isMobile ? (
                <MobileContainer>
                    <Header />
                    <LoginLayout />
                </MobileContainer>
            ) : (
                <LoginLayout />
            )}
        </Container>
    );
};

export default Login;

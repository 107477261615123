import moment from 'moment';

import { IPropsRenderUserInfo } from '../types';

import {
    UserInformBlock,
    InfoTitle,
    InfoTextAbout,
    InfoTextMember,
    InfoTextReport,
} from '../user-account-page.styled';

export const UserInfo = ({ user }: IPropsRenderUserInfo) => {
    const date = moment((user?.date as unknown as string).split('T')[0]).format(
        'LL'
    );

    return (
        <UserInformBlock>
            {user.about && (
                <>
                    <InfoTitle>ABOUT</InfoTitle>
                    <InfoTextAbout>{user.about}</InfoTextAbout>
                </>
            )}

            <InfoTextMember>MEMBER SINCE: {date.toUpperCase()}</InfoTextMember>

            <InfoTextReport>Report</InfoTextReport>
        </UserInformBlock>
    );
};

import { FormInstance } from 'antd';
import { toast } from 'react-toastify';

import { getToken } from 'common/helpers';

export const notify = () => {
    toast.error('Something went wrong', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const errorCheck = (
    history: () => void,
    isAuth: boolean,
    form?: FormInstance<any>
) => {
    const token = getToken();
    if (!token && isAuth) {
        notify();
    }
    if (token) {
        form && form.resetFields();
        history();
    }
};

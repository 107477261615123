import { useState } from 'react';

import { RenderBlockInfo } from './render-block-info';
import { ButtonsRender } from './render-buttons';
import { ABOUT_INFO, ADVANCE_INFO } from '../constants';
import { IPropsProduct } from '../types';

import { InfoBlock, ButtonsWrapper } from '../product-page.styled';

export const InfoNftExtended = ({ product }: IPropsProduct) => {
    const [currentBlock, setCurrentBlock] = useState<'About' | 'Advance'>(
        'About'
    );
    const folders =
        currentBlock === 'About' ? [...ABOUT_INFO] : [...ADVANCE_INFO];

    return (
        <InfoBlock>
            <ButtonsWrapper>
                <ButtonsRender
                    currentBlock={currentBlock}
                    setCurrentBlock={setCurrentBlock}
                />
            </ButtonsWrapper>
            <RenderBlockInfo product={product} folders={folders} />
        </InfoBlock>
    );
};

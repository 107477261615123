import Styled from 'styled-components';
import { theme } from 'theme';
import { Input, Upload, Form, Button } from 'antd';

const { Dragger } = Upload;

export const PageWrapper = Styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 500px) {
        padding: ${theme.spaces.xl} ${theme.spaces.xs};
       align-items: center;
    }
`;

export const ProfileWrapper = Styled.div`
    box-shadow: 0px 4px 30px rgba(0, 10, 65, 0.1);
    width: 96%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 3% 2% 3% 3%;
    margin: 3% 2% 5% 2%;

    @media screen and (max-width: 500px) {
        background: #F9F9F9;
        padding: 0;
        margin: 0;
    }
`;

export const ProfileHeader = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.lg};
    margin-bottom: ${theme.spaces.sm};
    padding-left: 30px;
    .icon-ant{
        color: ${theme.colors.dark_grey};
        font-size: ${theme.spaces.lg};
        margin-left: ${theme.spaces.md};
    }

    @media screen and (max-width: 500px) {
        font-weight: ${theme.fonts.weights.medium};
        margin: 0;
        padding: ${theme.spaces.xs} ${theme.spaces.md};
    }
`;

export const ProfileBody = Styled.div`
    display: flex;
    width: 100%;
    margin-right: 30px;
    @media screen and (max-width : 650px){
        flex-direction: column;
        align-items: center;
        justify-content: center;
    };
    
    @media screen and (max-width: 500px) {
        margin-right: 0;
    }
`;

export const Profile = Styled.div`
    background: ${theme.colors.primary_white};
    border-radius: 20px;
    padding: 30px 30px 50px 30px;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 15px;
    box-shadow: 0px 4px 30px rgba(0, 10, 65, 0.1);
    margin-bottom: ${theme.spaces.x_50};
    @media screen and (max-width: 650px) {
            width: 355px;
    }
    @media screen and (max-width: 500px) {
            margin: 0;
            width: 100%;
            border-radius: 0;
    }
`;

export const Title = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.fonts.sizes.xs};
    color: ${theme.colors.dark_grey}
`;

export const SubTitle = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    color: ${theme.colors.light_grey};
    margin-top: 10px;
`;

export const Avatar = Styled.div`
    width: 120px;
    height: 120px;
    border-radius: 100%;
    margin-top: 34px;
    background-color: ${theme.colors.light_purple};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ProfileAvatarImage = Styled.img<{
    imgURL?: string | null;
}>`
    object-fit: contain;
    width:${({ imgURL }) => (imgURL ? '100%' : '70px')};
    height: ${({ imgURL }) => (imgURL ? '100%' : '70px')};
    object-fit: cover;
    border-radius: 100%;
`;

export const AvatarSubtitle = Styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    color: ${theme.colors.light_grey};
    max-width: 370px;
    margin-top: ${theme.spaces.sm};
`;

export const InputSubTitle = Styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    margin-bottom: ${theme.spaces.xs};
    color: ${theme.colors.light_grey};
`;

export const InputField = Styled(Input)<{ isName?: boolean }>`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    background: ${theme.colors.light_red};
    border: 1px solid rgba(51, 53, 96, 0.2);
    border-radius: ${theme.spaces.xs};
    min-height: 54px;
    margin-top: ${theme.spaces.xs};
    width:${({ isName }) => (isName ? '50%' : '97.6%')}
    div{
        padding: ${theme.spaces.xs} 0;
        margin: 0 ${theme.spaces.xs};
        color: rgba(51, 53, 96, 0.6);
    }
    
    @media screen and (max-width: 800px) {
        width: 97.6%;
}
    @media screen and (max-width: 500px) {
        font-size: ${theme.fonts.sizes.xxxs};
    }
`;

export const FooterWrapper = Styled.div`
    width: 55%;
    margin: 0 auto;
`;

export const PurpleButton = Styled(Button)`
    position: absolute;
    top: 0;
    left: 0;
    width: 184px;
    height: 50px;
    border: none;
    color: ${theme.colors.light_purple};
    background-color: rgba(81, 88, 255, 0.1);
    border-radius: 26px;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.fonts.sizes.xxs};
    cursor: pointer;
`;

export const UploadZone = Styled(Dragger)`
    position: relative;
    border: none !important;
    width: 184px !important;
    height: 50px !important;
    margin-top: ${theme.spaces.md};
    margin-bottom: ${theme.spaces.x_50};
    background: none !important;

    .ant-upload.ant-upload-drag{
        background: none!
    }
`;

export const FormWrapper = Styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const UpdateButton = Styled(Button)`
    width: 200px;
    height: 46px;
    background-color: ${theme.colors.light_purple};
    border-radius: 40px;
    color: ${theme.colors.primary_white};
    font-weight: ${theme.fonts.weights.medium};
    font-size: 100%;
    font-family: ${theme.fonts.families.poppins};
    margin-top: ${theme.spaces.xl};
    @media screen and (max-width: 500px) {
            width: 150px !important;
    }
`;

export const Verification = Styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const VerificationSubTitle = Styled.div`
    max-width: 571px;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    margin-bottom: ${theme.spaces.xs};
    color: ${theme.colors.light_grey};
`;

export const VerificationButton = Styled.button`
    width: 184px;
    height: 50px;
    border: none;
    color: ${theme.colors.light_purple};
    background-color: rgba(81, 88, 255, 0.1);
    border-radius: 26px;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.fonts.sizes.xxs};
    cursor: pointer;
    position: relative;
`;

export const ConfirmButton = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    color: ${theme.colors.light_purple};
    position: relative;
    bottom: 60px;
    float: right;
    margin-right: ${theme.spaces.xxxl};
    cursor: pointer;
    @media screen and (max-width: 500px) {
        font-size: ${theme.fonts.sizes.xxxs};
        margin-right: ${theme.spaces.sm};
    }

`;

export const FormItemWrapper = Styled.div<{ isName?: boolean }>`
    width:${({ isName }) => isName && '45%'};
    @media screen and (max-width: 800px) {
        width:${({ isName }) => isName && '100%'};
    }
`;

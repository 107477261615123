import { Form } from 'antd';

import { IInputItem } from '../types';

import { InputField } from '../contact-us-page.styled';

export const FormItem = ({
    inputName,
    pattern,
    message,
    required,
    defaultValue,
}: Omit<IInputItem, 'title'>) => {
    return (
        <>
            <Form.Item
                name={inputName}
                initialValue={defaultValue}
                rules={[
                    {
                        pattern: pattern,
                        message: message,
                    },
                ]}
            >
                <InputField required={required} />
            </Form.Item>
        </>
    );
};

import { useState, useRef, RefObject } from 'react';

import { ModalImage } from './modal-image';
import { IPropsImageBlock } from '../types';

import RollIcon from 'assets/RollIcon.svg';
import ExpandIcon from 'assets/ExpandIcon.svg';

import {
    ActionButton,
    ActionImage,
    ImageBlockContainer,
    ActionBlockImage,
    NFTBlockImage,
    NFTImage,
    CollectionVideo,
} from '../product-page.styled';

export const ImageBlock = ({ image, contentType }: IPropsImageBlock) => {
    const [transformDeg, setTransformDeg] = useState<number>(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const ref = useRef<HTMLImageElement | HTMLVideoElement>(null);

    const handleRollClick = () => {
        const newValue = transformDeg === 180 ? 0 : transformDeg + 90;

        setTransformDeg(newValue);
    };

    const handleExpandClick = () => {
        setTransformDeg(0);
        setIsModalVisible(!isModalVisible);
    };
    const isImage = contentType === 'image';

    return (
        <ImageBlockContainer className="notDraggable">
            {isModalVisible && (
                <ModalImage
                    isModalVisible={isModalVisible}
                    handleCancel={handleExpandClick}
                >
                    {isImage ? (
                        <NFTImage
                            src={image}
                            alt="nft"
                            transformDeg={transformDeg}
                            className="notDraggable"
                        />
                    ) : (
                        <CollectionVideo
                            loop
                            muted
                            playsInline
                            controls={false}
                            autoPlay
                            src={image}
                            transformDeg={transformDeg}
                            className="notDraggable"
                        />
                    )}
                </ModalImage>
            )}
            <ActionBlockImage>
                <ActionButton onClick={handleRollClick}>
                    <ActionImage src={RollIcon} alt="roll-icon" />
                </ActionButton>
                <ActionButton onClick={handleExpandClick}>
                    <ActionImage src={ExpandIcon} alt="expand-icon" />
                </ActionButton>
            </ActionBlockImage>
            <NFTBlockImage
                height={
                    null !== ref.current
                        ? transformDeg % 180 === 90
                            ? `${ref.current.clientWidth + 60}px`
                            : `${ref.current.clientHeight + 60}px`
                        : '300px'
                }
            >
                {isImage ? (
                    <NFTImage
                        src={image}
                        alt="nft"
                        ref={ref as RefObject<HTMLImageElement>}
                        transformDeg={transformDeg}
                        className="notDraggable"
                    />
                ) : (
                    <CollectionVideo
                        loop
                        playsInline
                        muted
                        controls={false}
                        autoPlay
                        src={image}
                        ref={ref as RefObject<HTMLVideoElement>}
                        transformDeg={transformDeg}
                        className="notDraggable"
                    />
                )}
            </NFTBlockImage>
        </ImageBlockContainer>
    );
};

import i18next from 'i18next';
import common_de from './de/common.json';
import common_en from './en/common.json';

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'EN', // language to use
    resources: {
        DE: {
            common: common_de,
        },
        EN: {
            common: common_en,
        },
    },
    debug: false,
});
export default i18next;

import { Form, Input } from 'antd';

import type { IFormItemProps } from '../types';

export const FormItem = ({
    name,
    message,
    placeholder,
    type,
    pattern,
}: IFormItemProps) => {
    const rules =
        name !== 'email'
            ? [
                  {
                      required: true,
                      type: type,
                      pattern: pattern,
                      message,
                  },
                  { max: 15, message: 'Max length 15 characters' },
              ]
            : [
                  {
                      required: true,
                      type: type,
                      pattern: pattern,
                      message,
                  },
              ];
    return (
        <Form.Item name={name} rules={rules}>
            <Input placeholder={placeholder} className="formInput" />
        </Form.Item>
    );
};

import { Switch } from 'react-router-dom';
import { PrivateRoutes } from './constant';
import { PrivateRoute } from './private-route';

export const ProtectedRoutes = () => (
    <Switch>
        {PrivateRoutes.map(({ path, component }) => {
            return (
                <PrivateRoute
                    exact
                    key={path}
                    path={path}
                    component={component}
                />
            );
        })}
    </Switch>
);

import { Spin } from 'antd';

import { FormItem } from './form-item';
import { IRenderFormItems } from '../types';

import {
    Title,
    SubTitle,
    FormItemWrapper,
} from '../update-profile-page.styled';
import { HalfInputWrapper } from 'containers/profile-page/profile-page.styled';

export const RenderFormItems = ({ renderItems, user }: IRenderFormItems) => {
    if (!user) {
        return <Spin size="large" />;
    }
    return (
        <>
            <HalfInputWrapper>
                <FormItemWrapper isName={true}>
                    <Title>First Name</Title>
                    <FormItem
                        isName={true}
                        inputName="firstName"
                        pattern={new RegExp(/^[\p{L}\p{M}\. ]+$/u)}
                        message="please fill in all the fields correctly"
                        maxLength={15}
                    />
                </FormItemWrapper>

                <FormItemWrapper isName={true}>
                    <Title>Last Name</Title>
                    <FormItem
                        isName={true}
                        inputName="lastName"
                        pattern={new RegExp(/^[\p{L}\p{M}\. ]+$/u)}
                        message="please fill in all the fields correctly"
                        maxLength={15}
                    />
                </FormItemWrapper>
            </HalfInputWrapper>

            {renderItems.map(
                (
                    {
                        inputName,
                        title,
                        subTitle,
                        placeholder,
                        pattern,
                        message,
                        maxLength,
                    },
                    i
                ) => {
                    return (
                        <FormItemWrapper key={i}>
                            <Title>{title}</Title>
                            {subTitle && <SubTitle>{subTitle}</SubTitle>}
                            <FormItem
                                pattern={pattern}
                                inputName={inputName}
                                placeholder={placeholder}
                                message={message}
                                maxLength={maxLength}
                            />
                        </FormItemWrapper>
                    );
                }
            )}
        </>
    );
};

import { Form } from 'antd';

import { IRenderCheckboxItems } from '../types';

import {
    RadioButtonsContainer,
    RadioButtonItem,
    RadioButtonText,
} from 'containers/contact-us-page/contact-us-page.styled';

export const RenderRadioButtons = ({
    renderRadioButtonsItems,
}: {
    renderRadioButtonsItems: IRenderCheckboxItems[];
}) => {
    return (
        <RadioButtonsContainer>
            <Form.Item
                name="radio-group"
                initialValue={renderRadioButtonsItems[0].value}
            >
                <RadioButtonItem.Group>
                    {renderRadioButtonsItems.map(({ value }, i) => (
                        <RadioButtonItem value={value} key={i + value}>
                            <RadioButtonText>{value}</RadioButtonText>
                        </RadioButtonItem>
                    ))}
                </RadioButtonItem.Group>
            </Form.Item>
        </RadioButtonsContainer>
    );
};

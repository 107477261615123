import Header from 'components/header';
import { IPropsHeaderButtons } from 'components/header/types';
import { PropsWithChildren } from 'react';

import * as Styles from 'common/common.styled';

const PageSkeleton = ({
    children,
    buttons,
    coloricons,
    isBecomeACreator = false,
    isSearch = false,
}: PropsWithChildren<IPropsHeaderButtons>) => {
    return (
        <Styles.PageLayoutWrapper>
            <Header
                buttons={buttons}
                coloricons={coloricons}
                isBecomeACreator={isBecomeACreator}
                isSearch={isSearch}
            />
            <Styles.PageLayoutContent>{children}</Styles.PageLayoutContent>
        </Styles.PageLayoutWrapper>
    );
};

export default PageSkeleton;

import { IRenderBlockProps } from './types';
import Email from 'assets/Mail.svg';
import FacebookIcon from 'assets/FacebookIcon.svg';
import GmailIcon from 'assets/GmailIcon.svg';
import RedditIcon from 'assets/RedditIcon.svg';
import TelegramIcon from 'assets/TelegramIcon.svg';
import TwitterIcon from 'assets/TwitterIcon.svg';
import WhatsAppIcon from 'assets/WhatsAppIcon.svg';

export const ABOUT_INFO: IRenderBlockProps[] = [
    {
        title: 'Description',
        folderName: 'description',
    },
    {
        title: 'Collection',
        folderName: 'collectionName',
    },
    {
        title: 'Tags',
        folderName: 'collectionName',
    },
    {
        title: 'Token Stander',
        value: 'ERC1155',
    },
    {
        title: 'External Link (Optional Field)',
        value: ' https://deviantart.com/x123456',
        styles: 'link',
    },
];

export const ADVANCE_INFO: IRenderBlockProps[] = [
    {
        title: 'Properties',
        value: 'Some user written description here',
    },
    {
        title: 'Metadata',
        folderName: 'collectionName',
    },
    {
        title: 'Status',
        folderName: 'collectionName',
    },
];

export const BUTTONS = [{ title: 'About' }, { title: 'Advance' }];

export const NOT_ACTIVE_BUTTONS = [
    { title: 'Offers' },
    { title: 'Activities' },
];

export const socialItems = [
    {
        image: TwitterIcon,
        alt: 'twitter',
        getUrl: (url: string) => `https://twitter.com/intent/tweet?url=${url}`,
    },
    {
        image: TelegramIcon,
        alt: 'telegram',

        getUrl: (url: string) => `https://t.me/share/url?url=${url}`,
    },
    {
        image: RedditIcon,
        alt: 'reddit',

        getUrl: (url: string) => `https://reddit.com/submit?url=${url}`,
    },
    {
        image: FacebookIcon,
        alt: 'facebook',

        getUrl: (url: string) => `https://www.facebook.com/sharer.php?u=${url}`,
    },
    {
        image: WhatsAppIcon,
        alt: 'whatsapp',

        getUrl: (url: string) => `https://api.whatsapp.com/send?text=${url}`,
    },
    {
        image: GmailIcon,
        alt: 'gmail',

        getUrl: (url: string) =>
            `https://mail.google.com/mail/u/0/?fs=1&tf=cm&to&body=${url}&ui=2`,
    },
    {
        image: Email,
        alt: 'email',

        getUrl: (url: string) => `mailto:?body=${url}`,
    },
];

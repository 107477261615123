import { USER_INFO } from '../constants';
import { IPropsRenderUserInfo } from '../types';

import * as Styles from '../account-page.styled';

export const RenderUserInfo = ({ user }: IPropsRenderUserInfo) => {
    return (
        <Styles.UserInfoList>
            {USER_INFO.map(({ name, type }) => {
                let info;
                if (typeof user[type] === 'object') {
                    info = (user[type] as string[]).length;
                } else {
                    info = typeof user[type] === 'undefined' ? 0 : user[type];
                }

                return (
                    <div className="infoBlock" key={name + type}>
                        <div className="infoBlockLeft">{name}</div>
                        <div className="infoBlockRight">{info}</div>
                    </div>
                );
            })}
        </Styles.UserInfoList>
    );
};

import type { DefaultTheme } from 'styled-components';
import { colorPalette } from './color-palette';
import { fontFamilies } from './font-families';
import { fontSizes } from './font-sizes';
import { fontWeights } from './font-weights';
import { spaces } from './spaces';

export const theme: DefaultTheme = {
    colors: colorPalette,
    fonts: { sizes: fontSizes, weights: fontWeights, families: fontFamilies },
    spaces: spaces,
};

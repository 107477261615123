import moment from 'moment';
import { IOptions } from './types';

export const TODAY = String(moment().format('YYYY/MM/DD'));

export const DATE_BASED_SELECT: IOptions[] = [
    { value: 'All time', query: '&date=all' },
    { value: 'New today', query: `&date=new` },
    { value: 'Past 7 days', query: `&date=past7` },
    { value: 'Past 30 days', query: `&date=past30` },
];
export const DESIGNER_SELECT: IOptions[] = [{ value: 'Designer' }];
export const RECOMMENDED_SELECT: IOptions[] = [
    { value: 'Most popular', query: '&isSortByLikes=true' },
    { value: 'Most viewed', query: '&isSortByViews=true' },
];

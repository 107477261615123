import { IRenderFormItems } from '../types';
import { FormItem } from './FormItem';

export const RenderFormItems = ({
    renderItems,
}: {
    renderItems: IRenderFormItems[];
}) => {
    return (
        <>
            {renderItems.map(({ labelName, inputName, message }) => {
                return (
                    <div className="box" key={inputName + labelName}>
                        <label className="textColor">{labelName}</label>
                        <FormItem
                            pattern={new RegExp(/^[\p{L}\p{M}\.]+$/u)}
                            name={inputName}
                            message={message}
                        />
                    </div>
                );
            })}
        </>
    );
};

import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

import { RootState } from 'store';
import HeaderComponent from 'components/header/header.component';
import FooterPreLogin from 'components/footer-pre-login';
import { ICollectionItems } from 'common/types';
import { FiltersBlock } from 'containers/nfts-page/components/filtersBlock';
import { CollectionItemsBlock } from 'containers/nfts-page/components/collectionItemsBlock';
import { Preview } from './components/preview';
import { DEFAULT_BTN_HEADER } from 'common/constants';
import { ShowMoreBtn } from 'components/showMoreBtn/show-more-btn';
import { useSearch } from 'hooks/useSearch';

import * as CommonStyles from 'common/common.styled';
import {
    MarginBottomDiv,
    SearchBlock,
    SearchIcon,
    SearchInput,
} from 'containers/nfts-page/nfts-page.styled';
import { useMediaQuery } from 'react-responsive';

const CreatorBrandPage = () => {
    const user = useSelector((state: RootState) => state.user.user);
    const [inputValue, setInputValue] = useState('');

    const {
        setCreator,
        setDate,
        creator,
        designer,
        setSortedBy,
        setDesigner,
        setPortion,
        portion,
        itemsLength,
        isLoading,
    } = useSearch();

    const collectionItems: ICollectionItems[] = useSelector(
        (state: RootState) => state.collectionItems.allCollections
    );
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' });

    const filteredCollectionItems = useMemo(() => {
        const newFiltered = !!inputValue
            ? collectionItems
                  .map((collection) => {
                      if (
                          collection.name
                              .toLocaleLowerCase()
                              .includes(inputValue.toLocaleLowerCase())
                      ) {
                          return collection;
                      }
                  })
                  .filter((el) => el !== undefined)
            : [...collectionItems];
        return newFiltered;
    }, [collectionItems, inputValue]);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    return (
        <CommonStyles.PageLayoutWrapper>
            <HeaderComponent
                isBecomeACreator={true}
                isSearch={false}
                buttons={DEFAULT_BTN_HEADER}
            />
            <SearchBlock>
                <SearchInput
                    placeholder="Search Project"
                    bordered={true}
                    suffix={<SearchIcon />}
                    value={inputValue}
                    onChange={onInputChange}
                />
            </SearchBlock>

            {isLoading ? (
                <Spin />
            ) : (
                <>
                    <FiltersBlock
                        setCreator={setCreator}
                        setSortedBy={setSortedBy}
                        setDate={setDate}
                        setDesigner={setDesigner}
                        creator={creator}
                        designer={designer}
                        filteredCollectionItems={filteredCollectionItems}
                    />

                    {isTabletOrMobile && <Preview />}
                    <CollectionItemsBlock
                        collectionItems={
                            filteredCollectionItems as ICollectionItems[]
                        }
                    >
                        {!isTabletOrMobile && <Preview />}
                    </CollectionItemsBlock>
                    {user && itemsLength > filteredCollectionItems.length && (
                        <ShowMoreBtn
                            handleShowMore={() => setPortion(portion + 1)}
                        />
                    )}
                </>
            )}

            {!user ? <FooterPreLogin /> : <MarginBottomDiv />}
        </CommonStyles.PageLayoutWrapper>
    );
};

export default CreatorBrandPage;

import { useHistory } from 'react-router';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Dropdown, Space } from 'antd';

import { useSelector } from 'react-redux';
import { useWeb3Auth } from 'common/services/web3auth';
import { RootState } from 'store';
import Person from 'assets/Person.svg';
import LogoutIcon from 'assets/LogoutIcon.svg';
import { IPropsUserDropdownMenu } from './types';
import { DEFAULT_USER_MENU_ITEMS } from './const';

import {
    UserMenu,
    Avatar,
    MenuItem,
    MenuIconWrapper,
} from './user-dropdown-menu.styled';

export const UserDropdownMenu = ({
    simpleMenuItems = DEFAULT_USER_MENU_ITEMS,
}: IPropsUserDropdownMenu) => {
    const user = useSelector((state: RootState) => state.user.user);

    const { logout } = useWeb3Auth();
    const history = useHistory();

    const handleLogout = async () => {
        await logout();
        history.push('/login');
    };

    const items: ItemType[] = [
        ...simpleMenuItems.map(({ label, icon, routerKey }, i) => ({
            key: i.toString(),
            label: <MenuItem>{label}</MenuItem>,
            icon: <MenuIconWrapper>{icon}</MenuIconWrapper>,
            onClick: () => {
                history.push(routerKey);
            },
        })),
        {
            type: 'divider',
        },
        {
            key: '100',
            label: <MenuItem isLogout>Logout</MenuItem>,
            onClick: handleLogout,
            icon: <img src={LogoutIcon} alt="logout-icon" />,
            className: 'logout-section',
        },
    ];

    return (
        <Dropdown
            overlay={<UserMenu items={items} />}
            trigger={['click']}
            placement="bottom"
            arrow
        >
            <Space>
                <Avatar src={user?.avatar || Person} alt="avatar" isPointer className='notDraggable'/>
            </Space>
        </Dropdown>
    );
};

import { IPropsRenderBlockInfo } from '../types';

import {
    Container,
    AboutBlock,
    InfoBlockContainer,
    InfoBlockText,
    InfoBlockTitle,
    InfoBlockLink,
} from '../product-page.styled';

export const RenderBlockInfo = ({
    product,
    folders,
}: IPropsRenderBlockInfo) => {
    return (
        <Container isBorder>
            <AboutBlock>
                {folders.map(({ title, value, folderName, styles }) => {
                    const folderText = folderName ? product[folderName] : value;
                    const isLink = title === 'External Link (Optional Field)';

                    let text = folderText;
                    if (title === 'Status') {
                        text = `${folderText} NFT`;
                    }
                    if (title === 'Tags') {
                        text = product.tags
                            ? product.tags.split(',').join(', ')
                            : '';
                    }

                    return (
                        <InfoBlockContainer key={title + value}>
                            <InfoBlockTitle>{title}</InfoBlockTitle>
                            {isLink ? (
                                product.externalLink && (
                                    <InfoBlockLink
                                        href={product.externalLink as string}
                                    >
                                        {product.externalLink}
                                    </InfoBlockLink>
                                )
                            ) : (
                                <InfoBlockText className={styles}>
                                    {text}
                                </InfoBlockText>
                            )}
                        </InfoBlockContainer>
                    );
                })}
            </AboutBlock>
        </Container>
    );
};

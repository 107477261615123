import { Form } from 'antd';

import { IPropsSelectTags } from '../types';

import { SelectTagsWrapper, Title } from '../create-page.styled';

export const SelectTags = ({
    tags,
    setNewOption,
    newOption,
}: IPropsSelectTags) => {
    const options: { value: string }[] =
        tags && !!tags.split(',')
            ? tags.split(', ').map((el) => ({ value: el }))
            : [];

    const onChangeHandler = (e: any) => {
        const newTag = e[e.length - 1];

        const isExist = options.find(({ value }) => value === newTag);

        if (isExist === undefined) {
            const correctArray = tags.split(', ').length
                ? e.filter((item: string) => !tags.split(', ').includes(item))
                : e;
            setNewOption(correctArray);
        }
    };
    const placement =
        [...options, ...newOption].length > 5 ? 'topLeft' : 'bottomLeft';

    return (
        <>
            <Title>Tags</Title>
            <Form.Item
                name="tags"
                rules={[
                    {
                        validator: async (_, names) => {
                            if (names && names.length > 10) {
                                return Promise.reject(
                                    new Error(
                                        'you cannot add more than 10 tags'
                                    )
                                );
                            }
                            if (
                                names &&
                                names.find((el: string) => el.length > 30)
                            ) {
                                return Promise.reject(
                                    new Error(
                                        'tag cannot be longer than 30 characters'
                                    )
                                );
                            }
                        },
                    },
                ]}
            >
                <SelectTagsWrapper
                    virtual={false}
                    placeholder="Enter your tags here"
                    mode="tags"
                    maxTagTextLength={35}
                    tokenSeparators={[',']}
                    options={options}
                    onChange={onChangeHandler}
                    placement={placement}
                ></SelectTagsWrapper>
            </Form.Item>
        </>
    );
};

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'theme';

export const BoxRenderCollectionItems = styled.div`
    display: flex;
    max-width: 1320px;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media (max-width: 900px) {
        justify-content: space-around;
        justify-content: center;
    }
`;

export const CollectionItemBox = styled.div<{ src?: string }>`
    max-width: 450px;
    height: 240px;
    position: relative;
    background: ${theme.colors.background_grey};
    display: flex;
    flex-direction: column;
    margin: ${theme.spaces.sm};
    font-color: ${theme.colors.dark_grey};
    border-radius: ${theme.spaces.xxs};
    overflow: hidden;
    border-radius: ${theme.spaces.xxs};
    ${({ src }) =>
        src
            ? `background-image: url(${src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;`
            : ``}

    @media screen and (max-width: 1850px) {
        height: 240px;
        min-width: 325px;
        max-width: 345px;
    }
    @media screen and (max-width: 1660px) {
        height: 235px;
        min-width: 300px;
        max-width: 320px;
    }
    @media screen and (max-width: 1580px) {
        height: 260px;
        min-width: 390px;
        max-width: 410px;
    }
    @media screen and (max-width: 1450px) {
        height: 220px;
        min-width: 340px;
        max-width: 360px;
    }
    @media screen and (max-width: 1380px) {
        max-width: 340px;
        height: 210px;
        min-width: 330px;
    }

    @media screen and (max-width: 1330px) {
        max-width: 320px;
        height: 200px;
        min-width: 300px;
    }

    @media screen and (max-width: 1254px) {
        margin: ${theme.spaces.sm} auto;
        height: 280px;
        min-width: 405px;
        max-width: 445px;
    }

    @media screen and (max-width: 1110px) {
        height: 240px;
        min-width: 355px;
        max-width: 375px;
    }

    @media screen and (max-width: 1060px) {
        height: 230px;
        min-width: 330px;
        max-width: 355px;
    }

    @media screen and (max-width: 900px) {
        margin: ${theme.spaces.sm};
    }

    @media screen and (max-width: 780px) {
        height: 240px;
        min-width: 355px;
        max-width: 375px;
    }

    @media screen and (max-width: 580px) {
        min-height: 320px;
        max-height: 330px;
        min-width: 400px;
        max-width: 420px;
    }
    @media screen and (max-width: 460px) {
        min-height: 260px;
        max-height: 270px;
        min-width: 355px;
        max-width: 355px;
    }
    @media screen and (max-width: 400px) {
        min-height: 220px;
        max-height: 260px;
        max-width: 90%;
        min-width: 90%;
    }
`;

export const CollectionVideoItemBox = styled.div`
    max-width: 370px;
    height: 240px;
    position: relative;
    min-width: 370px;
    background: ${theme.colors.background_grey};
    display: flex;
    flex-direction: column;
    margin: ${theme.spaces.sm};

    @media screen and (max-width: 1850px) {
        height: 240px;
        min-width: 325px;
        max-width: 325px;
    }
    @media screen and (max-width: 1660px) {
        height: 235px;
        min-width: 300px;
        max-width: 300px;
    }
    @media screen and (max-width: 1580px) {
        height: 260px;
        min-width: 390px;
        max-width: 390px;
    }
    @media screen and (max-width: 1450px) {
        height: 220px;
        min-width: 340px;
        max-width: 340px;
    }
    @media screen and (max-width: 1380px) {
        max-width: 330px;
        height: 210px;
        min-width: 330px;
    }

    @media screen and (max-width: 1330px) {
        max-width: 300px;
        height: 200px;
        min-width: 300px;
    }

    @media screen and (max-width: 1254px) {
        margin: ${theme.spaces.sm} auto;
        height: 280px;
        min-width: 405px;
        max-width: 405px;
    }

    @media screen and (max-width: 1110px) {
        height: 240px;
        min-width: 355px;
        max-width: 355px;
    }

    @media screen and (max-width: 1060px) {
        height: 230px;
        min-width: 330px;
        max-width: 330px;
    }

    @media screen and (max-width: 900px) {
        margin: ${theme.spaces.sm};
    }

    @media screen and (max-width: 780px) {
        height: 240px;
        min-width: 355px;
        max-width: 355px;
    }

    @media screen and (max-width: 580px) {
        min-height: 320px;
        max-height: 320px;
        min-width: 400px;
        max-width: 400px;
    }
    @media screen and (max-width: 460px) {
        min-height: 260px;
        max-height: 270px;
        min-width: 355px;
        max-width: 355px;
    }

    @media screen and (max-width: 400px) {
        min-height: 220px;
        max-height: 260px;
        max-width: 90%;
        min-width: 90%;
    }
`;

export const CollectionVideo = styled.video`
    width: 100%;
    overflow: hidden;
    height: 100%;
    border: none;
    object-fit: cover;
    border-radius: ${theme.spaces.xxs};
`;

export const CollectionImage = styled.div<{ src: string }>`
    width: 370px;
    height: 230px;
    border-radius: ${theme.spaces.xxs};
    background-image: url(${({ src }) => src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const CollectionImageBox = styled.div<{ src: string }>`
    position: relative;
    width: 100%;
    max-height: 230px;
    width: 370px;
    height: 230px;
    background-image: url(${({ src }) => src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const CardContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
    position: absolute;
    padding: 10px;
    bottom: 0%;
    left: 0%;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
    );
    border-bottom-left-radius: ${theme.spaces.xxs};
    border-bottom-right-radius: ${theme.spaces.xxs};
`;

export const InfoBlock = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;
export const ActionBlock = styled.div`
    display: flex;
    min-width: 25%;
    justify-content: space-between;
`;

export const ActionButton = styled.button`
    background: none;
    border: none;
`;

export const InfoBlockItem = styled.div<{ isLiked?: boolean }>`
    display: flex;
    align-items: center;
    margin-right: ${theme.spaces.xs};
    span {
        color: ${({ isLiked }) => (isLiked ? 'rgba(81, 88, 255, 1)' : 'white')};
        font-weight: ${theme.fonts.weights.regular};
        font-size: ${theme.fonts.sizes.xxxs};
        line-height: 18px;
        margin-left: ${theme.spaces.xxs};
    }
`;

export const LikeImg = styled.img`
    width: 12px;
    height: 12px;
    z-index: 3;
    color: white;
`;
export const ViewImg = styled.img`
    width: 17px;
    height: 10px;
    z-index: 4;
    color: white;
`;

export const NameCollectionItem = styled(Link)`
    color: white;
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.xs};
    line-height: 18px;
    max-width: 50%;
`;

export const UserNameCollectionItem = styled.div`
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxxs};
    line-height: 18px;
    color: white;
    padding-top: ${theme.spaces.xxs};
`;

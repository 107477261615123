import { Button } from 'antd';
import styled from 'styled-components';

import { theme } from 'theme';

export const PreviewBlock = styled.div<{
    isAccountPage: boolean | undefined;
}>`
    grid-column-start: 1;
    grid-column-end: 3;
    margin: ${theme.spaces.xs};
    padding: ${theme.spaces.xs};
    @media screen and (max-width: 1450px) {
        margin-left: ${({ isAccountPage }) => (isAccountPage ? '15%' : '10%')};
    }
    @media screen and (max-width: 1190px) {
        margin-right: ${({ isAccountPage }) =>
            isAccountPage ? '2.5%' : '0.5%'};
    }
    @media screen and (max-width: 1095px) {
        margin-left: ${({ isAccountPage }) => (isAccountPage ? '5%' : '3.5%')};
    }
    @media screen and (max-width: 980px) {
        margin-left: ${({ isAccountPage }) => (isAccountPage ? '7.5%' : '0%')};
    }
    @media screen and (max-width: 800px) {
        margin: 0;
        padding: ${theme.spaces.xl} ${theme.spaces.md} ${theme.spaces.x_50};
    }
`;

export const PreviewTitle = styled.h2`
    font-family: ${theme.fonts.families.poppins};
    font-style: normal;
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.xxxxl};
    color: ${theme.colors.light_grey};
    @media screen and (max-width: 800px) {
        font-size: ${theme.fonts.sizes.xl};
    }
`;

export const PreviewDescription = styled.p`
    padding: ${theme.spaces.xs} 0 ${theme.spaces.xl};
    font-family: ${theme.fonts.families.poppins};
    font-style: normal;
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xs};
    color: ${theme.colors.light_grey};
`;

export const PreviewButton = styled(Button)`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.fonts.sizes.xs};
    background-color: ${theme.colors.light_purple};
    border-radius: 26px;
    color: ${theme.colors.primary_white};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 ${theme.spaces.xl};
    height: 40px;
    margin-right: ${theme.spaces.md};
    @media screen and (max-width: 1100px) {
        padding: 0 ${theme.spaces.md};
    }
    @media screen and (max-width: 700px) {
        margin-right: ${theme.spaces.xxs};
    }
    @media screen and (max-width: 1000px) {
        font-size: ${theme.fonts.sizes.xxxs};
    }
    @media screen and (max-width: 925px) {
        font-size: ${theme.fonts.sizes.xs};
    }
    @media screen and (max-width: 805px) {
        font-size: ${theme.fonts.sizes.xxxs};
    }
`;

import { ADAPTER_EVENTS, WALLET_ADAPTER_TYPE } from '@web3auth/base';
import type { LOGIN_PROVIDER_TYPE } from '@toruslabs/openlogin';
import { Web3AuthCore } from '@web3auth/core';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IUser, IWeb3AuthContext, IWeb3AuthProps, TYPE_AUTH } from './types';
import { ethChainConfig } from './constant';
import { clearToken, fetchData } from 'common/helpers';
import { AppDispatch } from 'store';
import {
    getUser,
    removeIsAuth,
    setIsAuth,
    setIsWeb3Initialized,
} from 'store/slices/userSlice';
import { getCollectionItems } from 'store/slices/collectionItemSlice';
import { clearRefreshToken } from 'common/helpers/auth';

export const Web3AuthContext = createContext<IWeb3AuthContext>({
    web3Auth: null,
    isLoading: false,
    user: null,
    login: async () => {},
    logout: async () => {},
});

export function useWeb3Auth() {
    return useContext(Web3AuthContext);
}

export const Web3AuthProvider: any = ({ children }: IWeb3AuthProps) => {
    const [web3Auth, setWeb3Auth] = useState<Web3AuthCore | null>(null);
    const [user, setUser] = useState<unknown | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch: AppDispatch = useDispatch();

    const subscribeAuthEvents = (web3auth: Web3AuthCore) => {
        web3auth.on(ADAPTER_EVENTS.CONNECTED, (data: unknown) => {
            console.log('Yeah!, you are successfully logged in', data);
            setUser(data);
        });

        web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
            console.log('connecting');
        });

        web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
            console.log('disconnected');
            setUser(null);
        });

        web3auth.on(ADAPTER_EVENTS.ERRORED, (error: unknown) => {
            console.error(
                'some error or user has cancelled login request',
                error
            );
        });
    };

    async function init() {
        try {
            await logout();

            setIsLoading(true);
            const clientId = process.env.REACT_APP_CLIENT_ID || '';

            const web3AuthInstance = new Web3AuthCore({
                chainConfig: ethChainConfig,
                enableLogging: true,
            });

            const openLoginAdapter = new OpenloginAdapter({
                adapterSettings: {
                    network:
                        process.env.NEXT_PUBLIC_ERC1155_NETWORK === 'mainnet'
                            ? 'mainnet'
                            : 'testnet',
                    clientId,
                    uxMode: 'popup',
                },
                chainConfig: ethChainConfig,
                loginSettings: {
                    relogin: true,
                },
            });
            web3AuthInstance.configureAdapter(openLoginAdapter);

            await web3AuthInstance.init();

            subscribeAuthEvents(web3AuthInstance);
            setWeb3Auth(web3AuthInstance);
            setIsLoading(false);
            dispatch(setIsWeb3Initialized());
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        init();
    }, []);

    const login = async (
        adapter: WALLET_ADAPTER_TYPE,
        loginProvider: LOGIN_PROVIDER_TYPE,
        typeAuth: TYPE_AUTH,
        login_hint?: string,
        data?: Object
    ) => {
        try {
            setIsLoading(true);
            if (!web3Auth) {
                console.log('web3auth not initialized yet');
                return;
            }
            await init();
            logout();

            await web3Auth.connectTo(adapter, {
                loginProvider,
                login_hint,
            });

            const walletKeys = await web3Auth.provider?.request({
                method: 'eth_accounts',
            });

            const userInfo = await web3Auth.getUserInfo();

            if (Array.isArray(walletKeys) && walletKeys[0]) {
                const publicKey = walletKeys[0];
                let body = { publicKey };

                if (typeAuth === 'login') {
                    const { email } = userInfo;
                    body = { ...body, ...{ email } };
                }

                if (typeAuth === 'social') {
                    const { email, name } = userInfo;
                    body = { ...body, ...{ email, name } };
                }

                if (typeAuth === 'register' && data) {
                    body = { ...body, ...data };
                }

                const response: string | IUser = await fetchData(
                    body,
                    typeAuth
                );

                const token =
                    typeof response === 'string' ? response : response.token;
                const refreshToken =
                    typeof response === 'string'
                        ? response
                        : response.refreshToken;

                sessionStorage.setItem('token', token);
                sessionStorage.setItem('refreshToken', refreshToken);
            }
            dispatch(setIsAuth());
            dispatch(getUser());
            dispatch(getCollectionItems(0));
            return;
        } catch (error) {
            logout();
            dispatch(removeIsAuth());
        } finally {
            setIsLoading(false);
        }
    };

    const logout = async () => {
        if (!web3Auth) {
            console.log('web3auth not initialized yet');
            return;
        }
        clearToken();
        clearRefreshToken();
        dispatch(removeIsAuth());
        await web3Auth.logout();
    };

    const contextProvider = {
        web3Auth,
        user,
        isLoading,
        login,
        logout,
    };
    return (
        <Web3AuthContext.Provider value={contextProvider}>
            {children}
        </Web3AuthContext.Provider>
    );
};

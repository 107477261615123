export const FormInputItems = [
    {
        title: 'Name',
        subTitle: '',
        name: 'name',
        placeholder: 'Item name',
        message: 'Please fill in the field',
        maxLength: 40,
        required: true,
    },
    {
        title: 'External link',
        subTitle: '',
        name: 'externalLink',
        placeholder: 'External project/site/information resource',
        message: 'Please enter valid link',
        pattern: new RegExp('^http'),
        required: false,
        maxLength: 200,
    },
];

export const rules = [
    {
        required: true,
        message: 'Please fill in the field',
    },
];

export const acceptExtension = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'svg',
    'mp4',
    'webm',
    'mov',
    'quicktime',
    'm4v',
];

import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Form } from 'antd';

import PageLayout from 'components/page-skeleton';
import FooterPreLogin from 'components/footer-pre-login';
import { RadioButtonsItems, FormInputItems, rules } from './constants';
import {
    RenderFormItems,
    RenderRadioButtons,
    RenderUploadComponent,
} from './components';
import { fetchCreateItem } from 'common/services/apiHelpers';
import { ROUTES_KEYS } from 'common/constants';
import { notify } from 'common/helpers';

import {
    ContactUsButton,
    DescriptionSubtitle,
    FormWrapper,
    MainTitle,
    PageWrapper,
    TextAreaField,
    Title,
} from './contact-us-page.styled';

const ContactUsPage = () => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = async (values: any) => {
        setIsLoading(true);
        values.files =
            values.files &&
            values.files.map((image: any) => {
                return image.originFileObj;
            });

        const formData = new FormData();
        values.files &&
            values.files.forEach((file: Blob) => {
                formData.append('files', file);
            });
        delete values.files;
        values.issueCategory = values['radio-group'];
        delete values['radio-group'];

        for (const name in values) {
            formData.append(name, values[name]);
        }

        const sentEmail = await fetchCreateItem(formData, ROUTES_KEYS.EMAIL);
        if (!sentEmail) {
            notify('error', 'Something went wrong, try again');
        } else {
            notify('success', 'The email has been sent  successfully');
            form.resetFields();
        }

        setIsLoading(false);
    };

    return (
        <PageLayout>
            <PageWrapper>
                <FormWrapper form={form} name="create_form" onFinish={onFinish}>
                    <MainTitle>Contact us</MainTitle>
                    <ToastContainer
                        pauseOnFocusLoss={false}
                        pauseOnHover={false}
                        limit={1}
                    />
                    <RenderRadioButtons
                        renderRadioButtonsItems={RadioButtonsItems}
                    />
                    <RenderFormItems renderItems={FormInputItems} />
                    <Title>Description</Title>
                    <Form.Item name="description" rules={rules}>
                        <TextAreaField />
                    </Form.Item>
                    <DescriptionSubtitle>
                        In order to best help, please enter as many details as
                        you can. This should include troubleshooting steps
                        you've already taken, as well a screenshots of any error
                        messages.
                    </DescriptionSubtitle>
                    <RenderUploadComponent />
                    <ContactUsButton htmlType="submit" disabled={isLoading}>
                        Send
                    </ContactUsButton>
                </FormWrapper>
                <FooterPreLogin />
            </PageWrapper>
        </PageLayout>
    );
};

export default ContactUsPage;

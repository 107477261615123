import styled from 'styled-components';

import { theme } from 'theme';

export const ShowMoreBtnBlock = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        color: ${theme.colors.light_purple};
    }
    svg {
        color: ${theme.colors.light_purple};
    }
`;

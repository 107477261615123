import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { PropsWithChildren } from 'react';

import { CollectionItem } from './CollectionItem';
import { IPropsRenderCollectionItems } from '../../types';
import { RootState } from 'store';
import { isAuth } from 'common/helpers';

import { BoxRenderCollectionItems } from './collectionItem.styled';

export const RenderCollectionItems = ({
    isAccountPage = false,
    collectionItems,
    children,
}: PropsWithChildren<IPropsRenderCollectionItems>) => {
    const user = useSelector((state: RootState) => state.user.user);

    return (
        <BoxRenderCollectionItems isAccountPage={isAccountPage}>
            {children}
            {!user && isAuth() ? (
                <Spin />
            ) : (
                collectionItems.map(
                    (
                        {
                            name,
                            image,
                            likes,
                            seen,
                            ownerName,
                            ownerId,
                            _id,
                            contentType,
                        },
                        i
                    ) => {
                        return (
                            <CollectionItem
                                key={name + image + i}
                                name={name}
                                userName={ownerName}
                                img={image}
                                likes={likes}
                                views={seen}
                                ownerId={ownerId}
                                ownerName={ownerName}
                                isAccountPage={isAccountPage}
                                id={_id}
                                myUserId={user?._id}
                                contentType={contentType}
                            />
                        );
                    }
                )
            )}
        </BoxRenderCollectionItems>
    );
};

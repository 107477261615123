import { useEffect } from 'react';

import { IPropsProduct } from '../types';
import Like from 'assets/Like.svg';
import BlueLike from 'assets/BlueLike.svg';
import View from 'assets/View.svg';
import { isAuth } from 'common/helpers';
import { useHandleLike } from 'hooks/useHandleLike';

import {
    NameNft,
    OwnerBlok,
    OwnerText,
    ActionBlock,
    ViewImg,
    LikeImg,
    ActionBlockItem,
    BoxInfoNft,
    OwnerLink,
    AboutBlock,
    ActionButtonLike,
    ShareImg,
} from '../product-page.styled';
import { SocialShareDropdown } from './social-share-dropdown';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const InfoNft = ({ product }: IPropsProduct) => {
    const user = useSelector((state: RootState) => state.user.user);
    const { isLiked, handleLikeClick, handleViewChange } = useHandleLike(
        product._id,
        product.ownerId,
        product.likes,
        product.seen
    );

    const likeImage = isLiked ? BlueLike : Like;
    const userLink = user?._id === product.ownerId ? '' : product.ownerId;

    useEffect(() => {
        handleViewChange();
    }, []);

    return (
        <AboutBlock>
            <NameNft>{product.name}</NameNft>
            <BoxInfoNft>
                <OwnerBlok>
                    <OwnerText>Owned by:</OwnerText>
                    <OwnerLink to={`/account/${userLink}`}>
                        {product.ownerName}
                    </OwnerLink>
                </OwnerBlok>
                <ActionBlock>
                    <ActionBlockItem>
                        <ViewImg src={View} alt="viewIcon" className='notDraggable' />
                        <span>{product.seen} Views</span>
                    </ActionBlockItem>
                    <ActionButtonLike
                        disabled={!isAuth()}
                        onClick={handleLikeClick}
                    >
                        <ActionBlockItem isLiked={isLiked}>
                            <LikeImg src={likeImage} alt="likeIcon" className='notDraggable' />
                            <span>{product.likes} Liked</span>
                        </ActionBlockItem>
                    </ActionButtonLike>
                    <ActionBlockItem>
                        <SocialShareDropdown />
                    </ActionBlockItem>
                </ActionBlock>
            </BoxInfoNft>
        </AboutBlock>
    );
};

import { useSelector } from 'react-redux';

import { RenderCollectionItems } from './renderCollectionItems';
import { ShowMoreBtn } from 'components/showMoreBtn/show-more-btn';
import { IPropsCardBox } from 'containers/user-account-page/types';
import { RootState } from 'store';

import * as Styles from '../../user-account-page.styled';
export const CardBox = ({
    collectionItems,
    itemsLength,
    setPortion,
    portion,
}: IPropsCardBox) => {
    const allCollectionItems = useSelector(
        (state: RootState) => state.collectionItems.currentCollections
    );

    return (
        <Styles.CardsBlock>
            <Styles.ButtonsBlock>
                <Styles.ButtonCard className="active">Work</Styles.ButtonCard>
                <Styles.ButtonCard className="activityBtn">
                    Appreciations
                </Styles.ButtonCard>
            </Styles.ButtonsBlock>
            {!!collectionItems.length && (
                <RenderCollectionItems collectionItems={collectionItems} />
            )}
            {itemsLength > allCollectionItems.length && (
                <ShowMoreBtn handleShowMore={() => setPortion(portion + 1)} />
            )}
        </Styles.CardsBlock>
    );
};

import styled from 'styled-components';

export const LoginLayout = styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;

    h1 {
        padding-bottom: ${({ theme }) => theme.spaces.xxxl};
        font-size: ${({ theme }) => theme.fonts.sizes.lg};
        font-weight: 600;
        color: ${({ theme }) => theme.colors.dark_grey};
    }
    .form {
        margin: auto;
    }
    .textColor {
        color: ${({ theme }) => theme.colors.label_grey};
    }
    .formBox {
        background: white;
        width: 660px;
        box-shadow: 0px 4px 30px 0px ${({ theme }) => theme.colors.shadow_grey};
        border-radius: 20px;
        @media screen and (max-width: 600px) {
            width: 90%;
        }
    }
    .formBox > div {
        padding: ${({ theme }) => theme.spaces.xxl};
    }

    .formBox > div > span {
        font-size: ${({ theme }) => theme.fonts.sizes.xs};
        @media screen and (max-width: 600px) {
            font-size: ${({ theme }) => theme.fonts.sizes.xxs};
        }
        @media screen and (max-width: 400px) {
            font-size: ${({ theme }) => theme.fonts.sizes.xxxs};
        }
    }

    .checkBox {
        display: flex;
        justify-content: space-between;

        label {
            border: none;
            background: none;
        }

        button {
            border: none;
            background: none;
        }
    }
    .buttonBox {
        display: flex;
        flex-direction: column;
        margin-bottom: ${({ theme }) => theme.spaces.xl};
    }
    .buttonBox > button {
        border-radius: 10px;
        margin-top: ${({ theme }) => theme.spaces.sm};
    }

    .buttonBox > span {
        margin: auto;
        padding-top: ${({ theme }) => theme.spaces.md};
        padding-bottom: ${({ theme }) => theme.spaces.md};
        font-size: ${({ theme }) => theme.fonts.sizes.xxs};
    }

    .formInput {
        margin-top: ${({ theme }) => theme.spaces.xxs};
        border-radius: 10px;
    }
    .textSmall {
        font-size: ${({ theme }) => theme.fonts.sizes.xxxs};
    }
    .font-16 {
        font-size: ${({ theme }) => theme.fonts.sizes.xs};
    }
`;
export const LoadingContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #fff;
    align-items: center;
`;

export const MobileContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
export const Container = styled.div`
    width: 100%;
`;

export const NewUserContainer = styled.span`
    display: flex;
    
    @media screen and (max-width: 400px) {
        width: 100%;
        justify-content: center;
        color: ${({ theme }) => theme.colors.light_grey};
    }
`;

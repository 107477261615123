import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store';
import { getAllCollectionItems } from 'store/slices/collectionItemSlice';

export const useSearch = () => {
    const dispatch: AppDispatch = useDispatch();
    const itemsLength = useSelector(
        (state: RootState) => state.collectionItems.allLength
    );

    const [creator, setCreator] = useState('');
    const [sortedBy, setSortedBy] = useState('&isSortByLikes=true');
    const [date, setDate] = useState('');
    const [designer, setDesigner] = useState('');
    const [portion, setPortion] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        dispatch(
            getAllCollectionItems({
                sortedBy,
                creator,
                sortByDate: date,
                collection: designer,
                portion,
            })
        );
        setIsLoading(false);
    }, [creator, sortedBy, date, designer, portion]);

    return {
        setCreator,
        creator,
        setDate,
        setSortedBy,
        setDesigner,
        designer,
        setPortion,
        portion,
        isLoading,
        itemsLength,
    };
};

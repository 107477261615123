import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

import { IFile } from '../types';

export const normFile = (
    e: any,
    setFileList: Dispatch<SetStateAction<IFile[]>>
) => {
    if (Array.isArray(e)) {
        return e;
    }
    if (e && e.fileList.length) {
        setFileList(e.fileList);
        return e && e.fileList;
    }
};

export const notify = (string: 'nft' | 'collab') => {
    toast.success(
        `${
            string[0].toUpperCase() + string.slice(1)
        } has been successfully created`,
        {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            draggable: true,
            progress: undefined,
        }
    );
};

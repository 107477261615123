import { FormInputItem } from './form-input-item';

import {
    Title,
    InputSubTitle,
    FormItemWrapper,
} from '../../create-page.styled';

export interface IRenderInputFormItemsProps {
    title: string;
    subTitle?: string;
    name: string;
    message: string;
    placeholder: string;
    pattern?: RegExp;
    maxLength?: number;
    required: boolean;
}

export const RenderInputFormItems = ({
    renderItems,
}: {
    renderItems: IRenderInputFormItemsProps[];
}) => {
    return (
        <>
            {renderItems.map(
                (
                    {
                        title,
                        name,
                        message,
                        subTitle,
                        placeholder,
                        pattern,
                        maxLength,
                        required,
                    },
                    i
                ) => {
                    return (
                        <FormItemWrapper key={i}>
                            <Title>{title}</Title>
                            {subTitle && (
                                <InputSubTitle>{subTitle}</InputSubTitle>
                            )}
                            <FormInputItem
                                pattern={pattern}
                                name={name}
                                message={message}
                                placeholder={placeholder}
                                maxLength={maxLength}
                                required={required}
                            />
                        </FormItemWrapper>
                    );
                }
            )}
        </>
    );
};

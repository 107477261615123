import axios, { AxiosResponse } from 'axios';

import { getToken } from 'common/helpers';
import {
    ICreateCollectionItem,
    IUpdateUser,
    ICollection,
    IUpdateActionsUser,
    IUpdateCollectionItem,
    IUpdateOwnerName,
} from 'common/types';

export const fetchData = async (link: string) => {
    return withRefresh(async () => {
        const token = getToken() || '';
        const result = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER_URL}/${link}`,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return result;
    });
};

export const fetchUpdate = async (
    body:
        | IUpdateUser
        | IUpdateActionsUser['body']
        | IUpdateCollectionItem
        | IUpdateOwnerName,
    link: string
) => {
    return withRefresh(async () => {
        const token = getToken() || '';
        const result = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER_URL}/${link}`,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
            data: body,
        });
        return result;
    });
};

export const fetchDelete = async (
    body: IUpdateActionsUser['body'],
    link: string
) => {
    return withRefresh(async () => {
        const token = getToken() || '';
        const result = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SERVER_URL}/${link}`,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
            data: body,
        });
        return result;
    });
};

export const fetchCreateItem = async (
    body:
        | ICreateCollectionItem
        | Omit<ICollection, '_id' | 'ownerId' | 'ownerName'>
        | FormData,
    link: string
) => {
    return withRefresh(async () => {
        const token = getToken() || '';
        const result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER_URL}/${link}`,
            headers: {
                'x-access-token': token,
                'Content-Type': 'multipart/form-data',
            },
            data: body,
        });
        return result;
    });
};

const withRefresh = async (fetch: () => Promise<AxiosResponse<any, any>>) => {
    try {
        const result = await fetch();
        return result;
    } catch (error: any) {
        const isUnauthorizedError = error.response.status === 401;
        if (isUnauthorizedError) {
            await refreshToken();
            return await fetch();
        }
    }
};

export const refreshToken = async () => {
    const refreshToken = sessionStorage.getItem('refreshToken');
    const token = getToken() || '';
    const result = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/refresh`,
        headers: {
            'x-access-token': token,
        },
        data: { refreshToken },
    });
    sessionStorage.setItem('token', result.data.token);
    sessionStorage.setItem('refreshToken', result.data.refreshToken);
};

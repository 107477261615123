import { BUTTONS_ACCOUNT } from 'containers/account-page/constants';
import { IRenderButtonsProps } from 'containers/account-page/types';

import { ButtonCard, ButtonsContainer } from '../../account-page.styled';

export const RenderButtons = ({
    onCardClick,
    setShow,
}: IRenderButtonsProps) => {
    return (
        <ButtonsContainer>
            {BUTTONS_ACCOUNT.map(({ name, type }, i) => {
                const isClickable = type !== null;
                return (
                    <ButtonCard
                        id={name}
                        onClick={(e) => {
                            if (isClickable) {
                                setShow && setShow(false);
                                onCardClick(e, type);
                            }
                        }}
                        className={`account-tabs-item ${
                            !isClickable && 'greyText'
                        }`}
                        key={i + name}
                    >
                        {name}
                    </ButtonCard>
                );
            })}
        </ButtonsContainer>
    );
};

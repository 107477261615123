import { PropsWithChildren } from 'react';
import { ToastContainer } from 'react-toastify';
import { AuthBlock } from './AuthLayout.styled';

import 'react-toastify/dist/ReactToastify.css';

export const AuthLayout = ({ children }: PropsWithChildren<{}>) => {
    return (
        <AuthBlock>
            <div className="block">
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    limit={1}
                />
                {children}
            </div>
        </AuthBlock>
    );
};

export const FormInputItems = [
    {
        title: 'Your email address',
        inputName: 'email',
        required: true,
        message: 'please fill in all the fields correctly',
        pattern: new RegExp(
            /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/
        ),
    },
    {
        title: 'Subject',
        required: true,
        inputName: 'subject',
        message: 'please fill in all the fields correctly',
        pattern: new RegExp(/^[\p{L}\p{M}\. ]+$/u),
    },
];
export const RadioButtonsItems = [
    {
        value: 'My Account',
    },
    {
        value: 'Wallet and Transaction Issues',
    },
    {
        value: 'Buying and Selling NFTs',
    },
    {
        value: 'Developer Help',
    },
    {
        value: 'Report an Error Message',
    },
];

export const rules = [
    {
        required: true,
        message: 'Please fill in the field',
    },
];

import Styled from 'styled-components';
import { theme } from 'theme';

export const Wrapper = Styled.div<{ styles?: string }>`
    border-radius: 20px;
    padding: 40px 30px 40px 30px;
    background-color: ${theme.colors.primary_white};
    width: 355px;
    height: fit-content;
    box-shadow: ${(props) => props.styles};
    margin-bottom: ${theme.spaces.x_50};
    margin-left: ${theme.spaces.md};
    @media screen and (max-width: 600px) {
        width: 355px;
    }
    @media screen and (max-width: 500px) {
        border-top: 1px solid rgba(214, 215, 223, 1);
        border-radius: 0;
        background: rgba(249, 249, 249, 1);
        padding: 0;
        margin: 0;
        width: 100%;
    }
`;

export const BlockItem = Styled.div<{
    activeElement: boolean;
    isOpen: boolean;
    isExist?: boolean;
}>`
    padding: 12px 0px 11px 25px;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.fonts.sizes.xs};
    width: 100%;
    background: ${({ activeElement }) =>
        activeElement ? 'rgba(81, 88, 255, 0.1);' : null};
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    justify-content:${({ isOpen }) => (isOpen ? 'space-between' : 'start')};
    color: ${({ activeElement, isExist }) =>
        activeElement
            ? theme.colors.light_purple
            : isExist
            ? theme.colors.dark_grey
            : '#bcb9b9'};

        button{
            border:none;
            background:none;
            margin-left:${theme.spaces.xs};
            color:${theme.colors.dark_grey}
        }

        @media screen and (max-width: 500px) {
            margin-top: 0;
            padding: ${theme.spaces.xs} ${theme.spaces.md};
            font-weight: ${theme.fonts.weights.semiBold};
            font-size:16px;
        }
`;

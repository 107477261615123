import { useHistory } from 'react-router-dom';

import { ROUTER_KEYS } from 'common/data/data';
import { BecomeCreatorButton } from '../become-a-creator';
import { IPropsHeaderButtons } from 'components/header/types';
import { useMediaQuery } from 'react-responsive';
import { MobileMenuComponent } from '../mobile-menu';
import { EXTENDED_BTN_HEADER } from 'common/constants';
import { isBecomeACreatorShown } from 'common/helpers';

import * as Styled from './not-log-in-buttons.styled';

const NotLogInButtons = ({
    isBecomeACreator,
    buttons = EXTENDED_BTN_HEADER,
}: IPropsHeaderButtons) => {
    const hist = useHistory();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 924px)' });
    const isTabletS = useMediaQuery({ query: '(max-width: 875px)' });
    const isShowBecomeBtn = useMediaQuery({ query: '(max-width: 930px)' });

    const logIn = () => {
        hist.push(ROUTER_KEYS.LOGIN);
    };

    const signUp = () => {
        hist.push(ROUTER_KEYS.REGISTER);
    };

    return (
        <Styled.NotLogInWrapper>
            {!isShowBecomeBtn &&
                isBecomeACreatorShown(!isTabletS, isBecomeACreator) && (
                    <BecomeCreatorButton />
                )}
            {!isTabletS && (
                <Styled.LogInButton onClick={logIn}>Log In</Styled.LogInButton>
            )}
            <Styled.SignUpButton onClick={signUp}>Sign Up</Styled.SignUpButton>
            {isTabletOrMobile && (
                <MobileMenuComponent
                    isBecomeACreator={isBecomeACreatorShown(
                        isTabletS,
                        isBecomeACreator
                    )}
                    buttons={buttons}
                />
            )}
        </Styled.NotLogInWrapper>
    );
};

export default NotLogInButtons;

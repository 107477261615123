import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { changeIsLoading } from 'common/helpers/slices';
import { ROUTES_KEYS } from 'common/constants';
import {
    fetchCreateItem,
    fetchData,
    fetchUpdate,
} from 'common/services/apiHelpers';
import {
    ICollectionItems,
    ICreateCollectionItem,
    IGetCollectionItemsQuery,
    IInitialCollectionItemsState,
    IUpdateCollectionItem,
    IUpdateOwnerName,
    IGetCurrentCollectionsParams,
} from 'common/types';

const initialState: IInitialCollectionItemsState = {
    isLoading: false,
    allLength: 0,
    length: 0,
    collections: [],
    nfts: [],
    collabs: [],
    nftsLength: 0,
    collabsLength: 0,
    allCollections: [],
    currentCollections: [],
    currentCollectionsLength: 0,
    currentCreatedType: null,
};

export const createCollectionItem = createAsyncThunk(
    'collectionItems/createCollectionItem',
    (body: ICreateCollectionItem) =>
        fetchCreateItem(body, ROUTES_KEYS.COLLECTION_ITEM)
);

export const getCollectionItems = createAsyncThunk(
    'collectionItems/getCollectionItems',
    (portion: number) =>
        fetchData(`${ROUTES_KEYS.COLLECTION_ITEM}?portion=${portion}`)
);

export const getCollabs = createAsyncThunk(
    'collectionItems/getCollabs',
    (portion: number) =>
        fetchData(
            `${ROUTES_KEYS.COLLECTION_ITEM}/type?type=collab&portion=${portion}`
        )
);

export const getNfts = createAsyncThunk(
    'collectionItems/getNfts',
    (portion: number) =>
        fetchData(
            `${ROUTES_KEYS.COLLECTION_ITEM}/type?type=nft&portion=${portion}`
        )
);

export const getCurrentCollections = createAsyncThunk(
    'collectionItems/getCurrentCollections',
    (data: IGetCurrentCollectionsParams) =>
        fetchData(
            `${ROUTES_KEYS.COLLECTION_ITEM}/owner/${data.id}?portion=${data.portion}`
        )
);

export const getCurrentProduct = createAsyncThunk(
    'collectionItems/getCurrentProduct',
    (id: string) => fetchData(`${ROUTES_KEYS.COLLECTION_ITEM}/id/${id}`)
);

export const getAllCollectionItems = createAsyncThunk(
    'collectionItems/getAllCollectionItems',
    ({
        sortByDate,
        sortedBy,
        creator,
        collection,
        portion,
    }: IGetCollectionItemsQuery) =>
        fetchData(
            `${ROUTES_KEYS.ALL_BY_QUERY}?${sortedBy}${creator}${sortByDate}${collection}&portion=${portion}`
        )
);

export const getAllCollectionItemsWF = createAsyncThunk(
    'collectionItems/getAllCollectionItemsWF',
    () => fetchData(`${ROUTES_KEYS.ALL}`)
);

export const updateActions = createAsyncThunk(
    'collectionItems/updateActions',
    (body: IUpdateCollectionItem) =>
        fetchUpdate(body, ROUTES_KEYS.COLLECTION_ITEM)
);

export const updateOwnerName = createAsyncThunk(
    'collectionItems/updateOwnerName',
    (body: IUpdateOwnerName) =>
        fetchUpdate(body, `${ROUTES_KEYS.COLLECTION_ITEM}/user`)
);

export const collectionItemsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        getCollections: (state, action) => {},
        createCollection: (state, action) => {},
        updateActions: (state, action) => {},
        getCollectionItems: (state, action) => {},
        getAllCollectionItemsWF: (state, action) => {},
        updateOwnerName: (state, action) => {},
        getCurrentCollections: (state, action) => {},
        setCurrentCreatedType: (state, action) => {
            state.currentCreatedType = action.payload;
            return state;
        },
        removeCurrentCreatedType: (state) => {
            state.currentCreatedType = null;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCollectionItem.pending, (state) => {
                changeIsLoading(state);
            })

            .addCase(createCollectionItem.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.collections = [
                        ...state.collections,
                        action.payload.data.collection,
                    ];
                }
            })

            .addCase(getCollectionItems.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.collections = [...action.payload.data.collections];
                    state.length = action.payload.data.length;
                }
            })
            .addCase(getCollabs.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.collabs = [...action.payload.data.collections];
                    state.collabsLength = action.payload.data.length;
                }
            })
            .addCase(getNfts.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.nfts = [...action.payload.data.collections];
                    state.nftsLength = action.payload.data.length;
                }
            })
            .addCase(getAllCollectionItems.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.allCollections = [...action.payload.data.collections];
                    state.allLength = action.payload.data.length;
                }
            })
            .addCase(getAllCollectionItemsWF.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.allCollections = [...action.payload.data.collections];
                }
            })
            .addCase(updateOwnerName.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.collections = [
                        ...action.payload.data.updatedCollections,
                    ];
                }
            })
            .addCase(getCurrentCollections.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    if (
                        state.currentCollections.length &&
                        state.currentCollections.length !==
                            action.payload.data.collections.length &&
                        state.currentCollections[0].ownerId ===
                            action.payload.data.collections[0].ownerId
                    ) {
                        state.currentCollections = [
                            ...state.currentCollections,
                            ...action.payload.data.collections,
                        ];
                    } else {
                        state.currentCollections = [
                            ...action.payload.data.collections,
                        ];
                    }
                    state.currentCollectionsLength = action.payload.data.length;
                }
            })
            .addCase(updateActions.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    const foundIndex = state.allCollections.findIndex(
                        ({ _id }) => {
                            return (
                                _id ===
                                (
                                    action.payload!.data
                                        .updatedCollections as ICollectionItems
                                )._id
                            );
                        }
                    );

                    state.allCollections[foundIndex] =
                        action.payload.data.updatedCollections;

                    const currentAccountCollectionIndex =
                        state.currentCollections.findIndex(({ _id }) => {
                            return (
                                _id ===
                                (
                                    action.payload!.data
                                        .updatedCollections as ICollectionItems
                                )._id
                            );
                        });
                    if (currentAccountCollectionIndex >= 0) {
                        state.currentCollections[
                            currentAccountCollectionIndex
                        ] = action.payload.data.updatedCollections;
                    }
                }
            })

            .addCase(createCollectionItem.rejected, (state) => {
                changeIsLoading(state);
            });
    },
});
export const { setCurrentCreatedType, removeCurrentCreatedType } =
    collectionItemsSlice.actions;
export default collectionItemsSlice;

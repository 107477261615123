import { IInputItem } from '../types';

export const SettingsItems = [
    {
        name: 'Account Information',
        isExist: true,
    },
    {
        name: 'Notifications',
    },
    {
        name: 'Inbox Messages',
    },

    {
        name: 'Blocked Users',
    },
    {
        name: 'Delete Account',
    },
];

export const INPUT_ITEMS: IInputItem[] = [
    {
        title: 'Username',
        inputName: 'accountName',
        placeholder: 'Enter your display name',
        pattern: new RegExp(/^[\p{L}\p{M}\. ]+$/u),
        message: 'please fill in all the fields correctly',
        validation: { max: 30, message: 'Max length 30 characters' },
    },
    {
        title: 'Email',
        inputName: 'accountEmail',
        placeholder: 'Enter your email',
        message: 'please fill in all the fields correctly',
        pattern: new RegExp(
            /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/
        ),
    },
];

import { IColorPalette } from 'styled-components';

export const colorPalette: IColorPalette = {
    primary: '#bbb2e9',
    secondary: '#808080',
    not_active: '#d3d3d3',
    outlined: '#b3b3b3',
    not_outlined: '#cccccc',
    focused: '#15b2e5',
    hovered: '#1868b7',
    primary_white: '#ffffff',
    secondary_white: '#f5f5f5',
    primary_grey: '#C4C4C4',
    secondary_grey: '#E5E5E5',
    blue_grey: '#e7eaf3',
    blue_white: '#e5e8eb',
    primary_black: '#1e2939',
    secondary_black: '#434343',
    primary_green: '#3BA707',
    secondary_green: '#21bf96',
    primary_red: '#F22100',
    secondary_red: '#eb5757',
    primary_blue: '#041836',
    secondary_blue: 'rgba(0, 191, 255, 0.5)',
    light_blue: '#2081e2',
    dark_blue: '#04111d',
    grey_active: '#8a939b',
    grey_hovered: '#353840',
    black: '#000',
    third_white: 'rgba(229, 232, 235, 0.25)',
    third_light_blue: 'rgb(50, 160, 250)',
    secondary_light_blue: 'rgb(24, 104, 183);;',
    grey: '#00000066',
    light_grey: '#333560cc',
    dark_grey: '#333560',
    background_grey: '#ececff',
    shadow_grey: '#000a411a',
    label_grey: '#33356099',
    light_purple: '#5158ff',
    light_red: '#f9f9f9',
    light_gray: '#f5f5f5',
    light_sky_grey: '#d6d7df',
    light_grey_background: '#eeeeff',
    background_red: '#ffdbdb',
};

import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'theme';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: ${theme.spaces.xl} auto;
    box-shadow: 0px 4px 30px 0px ${({ theme }) => theme.colors.shadow_grey};
    border-radius: ${theme.spaces.xl};

    @media (max-width: 800px) {
        width: 90%;
    }

    @media (max-width: 500px) {
        box-shadow: none;
        margin: 0 auto;
    }
`;

export const ContentBox = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 80%;
    padding: ${theme.spaces.md};
    flex-direction: column;

    @media (max-width: 800px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        padding: ${theme.spaces.sm} 0;
    }
`;

export const InfoBlock = styled.div`
    padding-top: ${theme.spaces.x_50};
    display: flex;
    width: 100%;
    padding: ${theme.spaces.lg} ${theme.spaces.md};
    flex-direction: column;

    @media (max-width: 500px) {
        padding: ${theme.spaces.md} 0;
    }
`;

export const ButtonsBlock = styled.div`
    display: flex;
    width: 50%;
    justify-content: space-between;

    @media (max-width: 1000px) {
        width: 70%;
    }

    @media (max-width: 700px) {
        width: 80%;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const ButtonNotActive = styled.button`
    color: #a2a3ae;
    opacity: 0.8;
    background: none;
    font-size: 20px;
    line-height: 30px;
    border: none;
    margin-right: ${theme.spaces.sm};
    display: block;
    position: relative;
    padding: 0;

    @media (max-width: 500px) {
        font-size: 17px;
    }

    @media (max-width: 500px) {
        font-size: 14px;
    }
`;

export const ButtonActive = styled(ButtonNotActive)<{ isActive: boolean }>`
    color: ${({ isActive }) =>
        isActive ? theme.colors.light_purple : theme.colors.dark_grey};
    opacity: 0.8;
    background: none;
    font-size: 20px;
    line-height: 30px;
    border: none;
    margin-right: ${theme.spaces.sm};
    display: block;
    position: relative;
    padding: 0;

    &:hover {
        color: ${theme.colors.light_purple};
    }

    @media (max-width: 500px) {
        font-size: 17px;
    }

    @media (max-width: 500px) {
        font-size: 14px;
    }

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        bottom: -12px;
        background: ${({ isActive }) =>
            isActive ? theme.colors.light_purple : ''};
        position: absolute;
    }
`;

export const ButtonsWrapper = styled.div`
    width: 100%;
    border-bottom: 1px solid #d1d2da;
    margin: auto;
    padding-bottom: ${theme.spaces.xs};
    margin-bottom: ${theme.spaces.lg};
`;

export const TextInfo = styled.div`
    padding: ${theme.spaces.xl} 0 ${theme.spaces.md};
    font-weight: ${theme.fonts.weights.regular};
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.dark_grey};
    opacity: 0.6;
    padding: ${theme.spaces.lg} ${theme.spaces.md};

    @media (max-width: 500px) {
        font-size: 14px;
        padding: ${theme.spaces.md} 0 ${theme.spaces.lg};
    }
`;

export const Container = styled.div<{ isBorder: boolean }>`
    border-radius: ${({ isBorder }) => (isBorder ? '10px' : '')};
    border: ${({ isBorder }) =>
        isBorder ? '1px solid rgba(51, 53, 96, 0.2)' : ''};
`;

export const AboutBlock = styled.div`
    display: flex;
    padding: ${theme.spaces.md};
    flex-direction: column;

    @media (max-width: 500px) {
        padding: 0;
    }
`;

export const InfoBlockContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spaces.sm} 0;

    .link {
        color: ${theme.colors.light_purple};
    }

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: start;
    }
`;

export const InfoBlockTitle = styled.div`
    font-weight: ${theme.fonts.weights.regular};
    font-size: 18px;
    line-height: 27px;
    color: ${theme.colors.dark_grey};
    opacity: 0.6;
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    width: 50%;
    word-break: break-word;

    @media (max-width: 600px) {
        font-size: 14px;
    }

    @media (max-width: 500px) {
        padding-left: 12px;
        font-size: 12px;
        width: 100%;
        margin-left: 0;
    }
`;

export const InfoBlockText = styled(InfoBlockTitle)`
    @media (max-width: 500px) {
        opacity: 1;
    }
`;

export const InfoBlockLink = styled.a`
    font-weight: ${theme.fonts.weights.regular};
    font-size: 18px;
    line-height: 27px;
    width: 50%;
    margin-left: 10px;
    word-break: break-all;
    color: ${theme.colors.dark_grey};
    opacity: 0.6;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 600px) {
        font-size: 14px;
    }

    @media (max-width: 500px) {
        width: 100%;
        margin-left: 0;
        padding-left: 12px;
        font-size: 12px;
    }
`;

export const NameNft = styled.h1`
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: 40px;
    color: ${theme.colors.dark_grey};
    padding-bottom: ${theme.spaces.xs};

    @media (max-width: 900px) {
        font-size: 37px;
    }

    @media (max-width: 600px) {
        font-size: 30px;
    }

    @media (max-width: 500px) {
        font-size: 22px;
    }
`;

export const BoxInfoNft = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const OwnerBlok = styled.div`
    display: flex;
    align-items: center;
    margin-right: ${theme.spaces.x_100};
`;

export const OwnerText = styled.div`
    font-weight: ${theme.fonts.weights.medium};
    font-size: 18px;
    line-height: 27px;
    padding-right: ${theme.spaces.xs};
    color: ${theme.colors.dark_grey};

    @media (max-width: 500px) {
        font-size: 14px;
    }
`;

export const OwnerLink = styled(Link)`
    font-weight: ${theme.fonts.weights.regular};
    font-size: 16px;
    line-height: 24px;
    padding-right: ${theme.spaces.xxs};
    color: ${theme.colors.light_purple};

    @media (max-width: 500px) {
        font-size: 14px;
    }
`;

export const ActionBlock = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 500px) {
        padding-top: ${theme.spaces.sm};
        width: 70%;
    }
`;

export const ActionButtonLike = styled.button`
    background: none;
    border: none;
`;

export const ActionBlockItem = styled.div<{ isLiked?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: ${theme.spaces.sm};

    span {
        font-weight: ${theme.fonts.weights.regular};
        font-size: 16px;
        line-height: 24px;
        margin-left: ${theme.spaces.xxs};
        color: ${({ isLiked }) =>
            isLiked ? theme.colors.light_purple : theme.colors.dark_grey};
        opacity: 0.4;

        @media (max-width: 500px) {
            font-size: 14px;
        }
`;

export const LikeImg = styled.img`
    width: 12px;
    height: 12px;
    z-index: 3;
`;

export const ViewImg = styled.img`
    width: 17px;
    height: 10px;
    z-index: 4;
`;

export const ShareImg = styled.img`
    cursor: pointer;
    width: 15px;
    height: 15px;
    z-index: 4;
`;

export const ActionButton = styled.button`
    border: none;
    background: none;
    margin-right: ${theme.spaces.xs};
`;

export const ActionImage = styled.img`
    width: 15px;
    height: 15px;
`;

export const ImageBlockContainer = styled.div`
    border: 1px solid rgba(51, 53, 96, 0.2);
    border-radius: ${theme.spaces.xs};
`;

export const ActionBlockImage = styled.div`
    padding: ${theme.spaces.xs} 0;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(51, 53, 96, 0.2);
`;

export const NFTBlockImage = styled.div<{ height: string }>`
    display: flex;
    overflow: hidden;
    width: 80%;
    align-items: center;
    height: auto;
    margin: auto;
    min-height: ${({ height }) => height};
    transition: all 0.35s ease-in-out;
    @media (max-width: 500px) {
        min-height: unset;
    }
`;

export const NFTImage = styled.img<{ transformDeg: number }>`
    border-radius: ${theme.spaces.xs};
    max-width: -webkit-fill-available;

    margin: ${theme.spaces.md} auto;
    max-height: 540px;
    transform: ${({ transformDeg }) => `rotate(${transformDeg}deg)`};
    transition: all 0.5s ease-in-out;
    @media (max-width: 500px) {
        height: 200px;
    }
`;

export const CollectionVideo = styled.video<{ transformDeg: number }>`
    border-radius: ${theme.spaces.xs};
    margin: ${theme.spaces.md} auto;
    max-width: -webkit-fill-available;
    max-height: 540px;
    transform: ${({ transformDeg }) => `rotate(${transformDeg}deg)`};
    transition: all 0.5s ease-in-out;
    @media (max-width: 500px) {
        height: 200px;
    }
`;

export const ModalImage = styled(Modal)`
    width: 50% !important;
    @media (max-width: 900px) {
        width: 75% !important;
    }

    @media (max-width: 700px) {
        width: 95% !important;
    }
    @media (max-width: 350px) {
        max-width: 250px;
    }
    .ant-modal-body {
        display: flex;
        padding-top: ${theme.spaces.xxxl} !important;
    }

    img {
        margin: auto;
        max-height: 600px;
        max-width: 90%;
        @media (max-width: 500px) {
            height: 300px;
            max-width: 300px;
        }
        @media (max-width: 350px) {
            width: 200px;
            height: 200px;
        }
    }

    video {
        margin: auto;
        max-height: 600px;
        max-width: 90%;
        @media (max-width: 500px) {
            max-width: 300px;
            height: 300px;
        }
        @media (max-width: 350px) {
            width: 200px;
            height: 200px;
        }
    }
`;

export const SocialDropdownContainer = styled.div`
    width: 140px;
    padding: 10px;
    box-shadow: 0 1px 3px -2px rgb(0 0 0 / 12%), 0 3px 8px 0 rgb(0 0 0 / 8%),
        0 4px 14px 4px rgb(0 0 0 / 5%);
    border-radius: 15px;
    background-color: ${theme.colors.primary_white};
    display: flex;
    flex-wrap: wrap;
`;

export const SocialElement = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SocialImg = styled.img`
    cursor: pointer;
    height: 25px;
    width: 25px;
`;

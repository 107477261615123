import { IRenderFormItems } from '../types';

export const RENDER_FORM_ITEMS: IRenderFormItems[] = [
    {
        labelName: 'First name',
        inputName: 'firstName',
        message: 'Please input your first name!',
    },
    {
        labelName: 'Last name',
        inputName: 'lastName',
        message: 'Please input your last name!',
    },
];

import styled from 'styled-components';
import { Button } from 'antd';

import { theme } from 'theme';

export const BecomeCreatorButtonContainer = styled(Button)`
    display: flex;
    flex-direction: row;
    background: white;
    width: auto;
    align-items: center;
    color: ${theme.colors.dark_grey};
    border: 1px solid transparent;
    border-radius: 40px;
    height: 40px;

    @media screen and (max-width: 570px) {
        margin-top: ${theme.spaces.sm};
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        max-width: 230px;
    }

    &:hover {
        border: 1px solid #64b5f6;
        color: #64b5f6;
    }
`;
export const BecomeCreatorButtonText = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-size: ${theme.fonts.sizes.xs};
    font-style: normal;
    font-weight: ${theme.fonts.weights.medium};
    font-size: 16px;
`;
export const BecomeCreatorButtonIcon = styled.img`
    width: 29px;
    height: 30px;
`;

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import { AppDispatch, RootState } from 'store';
import { RenderButtons } from './renderButtons';
import { RenderCollectionItems } from './renderCollectionItems';
import {
    getCollectionItems,
    removeCurrentCreatedType,
} from 'store/slices/collectionItemSlice';
import { ICardBoxProps } from 'containers/account-page/types';
import { IButtonCardType } from 'containers/account-page/types';
import { ICollectionItems } from 'common/types';
import { ShowMoreBtn } from 'components/showMoreBtn/show-more-btn';
import { getCollabs, getNfts } from 'store/slices/collectionItemSlice';
import { delay } from 'common/helpers';

import * as Styles from '../../account-page.styled';

export const CardBox = ({ search }: ICardBoxProps) => {
    const [cardType, setCardType] = useState<IButtonCardType>('all');
    const [show, setShow] = useState(true);
    const dispatch: AppDispatch = useDispatch();
    const allItems = useSelector(
        (state: RootState) => state.collectionItems.collections
    );
    const user = useSelector((state: RootState) => state.user.user);

    const itemsLength = useSelector(
        (state: RootState) => state.collectionItems.length
    );
    const collabs = useSelector(
        (state: RootState) => state.collectionItems.collabs
    );
    const nfts = useSelector((state: RootState) => state.collectionItems.nfts);

    const collabsLength = useSelector(
        (state: RootState) => state.collectionItems.collabsLength
    );
    const nftsLength = useSelector(
        (state: RootState) => state.collectionItems.nftsLength
    );
    const currentCreatedType = useSelector(
        (state: RootState) => state.collectionItems.currentCreatedType
    );

    const selectTab = (e: any, type: IButtonCardType) => {
        const tabs = document.querySelectorAll('.account-tabs-item');
        tabs.forEach((tab) => {
            tab.classList.remove('active');
        });

        e.currentTarget.classList.add('active');
        setCardType(type);
    };

    const collectionItems = useMemo(() => {
        if (cardType === 'all') return allItems;
        if (cardType === 'collabs') return collabs;
        if (cardType === 'nft') return nfts;
        return [];
    }, [cardType, allItems, collabs, nfts]);

    const collectionLength = useMemo(() => {
        if (cardType === 'all') return itemsLength;
        if (cardType === 'collabs') return collabsLength;
        if (cardType === 'nft') return nftsLength;
        return [];
    }, [cardType, itemsLength, collabsLength, nftsLength]);

    const [portion, setPortion] = useState(0);

    useEffect(() => {
        if (currentCreatedType) {
            setCardType(currentCreatedType);
            dispatch(removeCurrentCreatedType());
            const tabs = document.querySelectorAll('.account-tabs-item');
            tabs.forEach((tab) => {
                tab.classList.remove('active');
            });
            const collabsBtn = document.getElementById('Collabs');
            const collectionsBtn: any = document.getElementById('Collections');
            currentCreatedType === 'nft'
                ? collectionsBtn!.classList.add('active')
                : collabsBtn!.classList.add('active');
        }
    }, []);

    useEffect(() => {
        dispatch(getCollabs(portion));
        dispatch(getNfts(portion));
    }, [portion, user?.likedProducts.length]);

    useEffect(() => {
        dispatch(getCollectionItems(portion));
    }, [portion, user?.likedProducts.length]);

    useEffect(() => {
        setShow(false);
        delay(300).then(() => setShow(true));
    }, [cardType]);

    const sortedItems = useMemo(
        () =>
            collectionItems.filter((item: ICollectionItems) =>
                item.name.toLowerCase().includes(search.toLowerCase())
            ),
        [collectionItems, search]
    );

    return (
        <Styles.CardsBlock>
            <Styles.ButtonsBlock>
                <div>
                    <Styles.ButtonCard
                        onClick={(e) => {
                            setShow(false);
                            selectTab(e, 'all');
                        }}
                        className="account-tabs-item active"
                    >
                        Owned
                    </Styles.ButtonCard>
                    <Styles.ButtonCard className="account-tabs-item greyText">
                        Activity
                    </Styles.ButtonCard>
                </div>

                <RenderButtons onCardClick={selectTab} setShow={setShow} />
            </Styles.ButtonsBlock>
            {!!sortedItems.length && show && (
                <RenderCollectionItems
                    show={show}
                    setShow={setShow}
                    isAccountPage={true}
                    collectionItems={sortedItems}
                />
            )}
            {(cardType === 'collabs' || cardType === 'nft') &&
                collectionLength > collectionItems.length && (
                    <ShowMoreBtn
                        handleShowMore={() => setPortion(portion + 1)}
                    />
                )}
        </Styles.CardsBlock>
    );
};

import { CHAIN_NAMESPACES, CustomChainConfig } from '@web3auth/base';
import { getChainId } from '../helpers';

export const ethChainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: getChainId(),
    rpcTarget: `https://${process.env.REACT_APP_NEXT_PUBLIC_ERC1155_NETWORK}.infura.io/v3/${process.env.REACT_APP_NEXT_PUBLIC_INFURA_APP_ID}`,
    displayName: process.env.REACT_APP_NEXT_PUBLIC_ERC1155_NETWORK,
    blockExplorer: `https://${process.env.REACT_APP_NEXT_PUBLIC_ERC1155_NETWORK}.etherscan.io/`,
    ticker: 'ETH',
    tickerName: 'Ethereum',
} as CustomChainConfig;

import type { LOGIN_PROVIDER_TYPE } from '@toruslabs/openlogin';

export const LOGIN_WITH_LINKS: {
    icon: string;
    alt: string;
    loginWith: LOGIN_PROVIDER_TYPE;
}[] = [
    {
        icon: '/assets/google-icon.svg',
        alt: 'google-icon',
        loginWith: 'google',
    },
    {
        icon: '/assets/facebook-icon.svg',
        alt: 'facebook-icon',
        loginWith: 'facebook',
    },
    { icon: '/assets/apple-icon.svg', alt: 'apple-icon', loginWith: 'apple' },
];

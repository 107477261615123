import styled from 'styled-components';
import { Button } from 'antd';

import { theme } from 'theme';

export const PreLoginButton = styled(Button)`
    width: 230px;
    height: 46px;
    background-color: ${theme.colors.light_purple};
    border-radius: 40px;
    color: ${theme.colors.primary_white};
    font-weight: ${theme.fonts.weights.medium};
    font-size: 100%;
    font-family: ${theme.fonts.families.poppins};
    margin: ${theme.spaces.xl} ${theme.spaces.md} ${theme.spaces.xl} 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px) {
        flex-direction: column;
        margin: 30px 20px 12px 0px;
    }

    @media (max-width: 500px) {
        margin: 0 0 ${theme.spaces.md};
        font-size: 16px;
        flex-direction: column;
        width: 230px;
    }
`;

export const Footer = styled.footer`
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media screen and (max-width: 600px) {
        margin-bottom: ${theme.spaces.x_50};
    }

    @media (max-width: 500px) {
        padding-top: ${theme.spaces.x_100};
        width: 70%;
    }
`;

export const FooterTitle = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.fonts.sizes.sm};
    width: 100% !important;
    text-align: center;
    color: ${theme.colors.dark_grey};
    max-width: 530px;
    margin-top: 60px;
    @media screen and (max-width: 770px) {
        font-size: ${theme.fonts.sizes.xs};
    }
    @media screen and (max-width: 600px) {
        font-size: ${theme.fonts.sizes.xxs};
    }

    @media screen and (max-width: 500px) {
        margin-top: 0;
        font-size: 18px;
        line-height: 27px;
    }
`;

export const FooterButtons = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: ${theme.spaces.md};
    justify-content: center;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

export const Or = styled.p`
    margin-right: ${theme.spaces.md};
    color: ${theme.colors.dark_grey};
    font-weight: ${theme.fonts.weights.medium};

    @media (max-width: 500px) {
        font-size: 16px;
        margin: 0 0 ${({ theme }) => theme.spaces.xl};
    }
`;

import { PropsWithChildren } from 'react';

import { RenderUserInfo } from './renderUserInfo';
import { RenderSocialIcons } from './renderSocialIcons';
import { IPropsRenderUserInfo } from '../types';
import Location from '../../../assets/Location.svg';
import Person from 'assets/Person.svg';

import * as Styles from '../account-page.styled';

export const UserBox = ({
    user,
    children,
}: PropsWithChildren<IPropsRenderUserInfo>) => {
    return (
        <Styles.UserBlock>
            <Styles.UserWrapper>
                <Styles.UserCard>
                    <Styles.UserInfoBlock>
                        <Styles.AvatarBox>
                            <Styles.AvatarContainer>
                                <Styles.Avatar
                                    src={user?.avatar || Person}
                                    alt="avatar"
                                />
                            </Styles.AvatarContainer>
                        </Styles.AvatarBox>
                        <h3>
                            {user.accountName ||
                                `${user.firstName} ${user.lastName}`}
                        </h3>
                        {user?.bio && <Styles.Bio>{user?.bio}</Styles.Bio>}
                        {user?.webSiteURL && (
                            <Styles.UserLink href={user?.webSiteURL}>
                                {user?.webSiteURL}
                            </Styles.UserLink>
                        )}
                        {user?.country && (
                            <Styles.Location>
                                <div>
                                    <img src={Location} alt="locationIcon" />
                                </div>
                                <span>{user?.country}</span>
                            </Styles.Location>
                        )}
                    </Styles.UserInfoBlock>

                    <button>Follow</button>
                    <button className="secondBtn">Message</button>
                    <RenderUserInfo user={user} />

                    <RenderSocialIcons user={user} />
                    {children}
                </Styles.UserCard>
            </Styles.UserWrapper>
        </Styles.UserBlock>
    );
};

import { RENDER_ICONS } from '../constants';
import {
    AboutUsImagesBlock,
    AboutUsImageContainer,
} from '../about-us-page.styled';

export const RenderIcons = () => {
    return (
        <AboutUsImagesBlock>
            {RENDER_ICONS.map(({ image }, i) => (
                <AboutUsImageContainer key={image + i}>
                    <img src={image} alt={`Person_${i}`} />
                </AboutUsImageContainer>
            ))}
        </AboutUsImagesBlock>
    );
};

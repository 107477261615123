import { Button } from 'antd';
import { WALLET_ADAPTERS } from '@web3auth/base';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { Icon } from 'components/auth/AuthLayout.styled';
import { useWeb3Auth } from 'common/services/web3auth';
import { IRenderButtonsProps } from './types';
import { errorCheck } from 'components/auth/helpers';
import { RootState } from 'store';

import * as Styles from './renderButtons.styled';

export const RenderButtons = ({ buttons, path }: IRenderButtonsProps) => {
    const { login, isLoading } = useWeb3Auth();

    const isWeb3Initialized = useSelector(
        (state: RootState) => state.user.setIsWeb3Initialized
    );

    const isAuth = useSelector((state: RootState) => state.user.isAuth);

    const history = useHistory();
    let pathName = '/';
    if (
        history.location.pathname !== 'login' &&
        history.location.pathname !== 'register'
    ) {
        pathName = history.location.pathname;
    }
    if (path) {
        pathName = path;
    }

    return (
        <Styles.SocialBox className="socialBox">
            {buttons.map(({ icon, alt, loginWith }) => {
                const handleClick = async () => {
                    if (!isLoading) {
                        await login(
                            WALLET_ADAPTERS.OPENLOGIN,
                            loginWith,
                            'social'
                        );
                        await errorCheck(
                            () => history.push(`${pathName}`),
                            isAuth
                        );
                    }
                };
                return (
                    <Button
                        disabled={!isWeb3Initialized}
                        key={icon + alt}
                        size="large"
                        onClick={handleClick}
                    >
                        <Icon src={window.location.origin + icon} alt={alt} />
                    </Button>
                );
            })}
        </Styles.SocialBox>
    );
};

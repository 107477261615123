import Styled from 'styled-components';
import { Input, Form, Button } from 'antd';

import { theme } from 'theme';

export const PageWrapper = Styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width : 500px){
        align-items: center;
        padding: ${theme.spaces.xl} ${theme.spaces.md};
    };
`;

export const SettingsWrapper = Styled.div`
    box-shadow: 0px 4px 30px rgba(0, 10, 65, 0.1);
    width: 96%;
    border-radius: ${theme.spaces.md};
    display: flex;
    flex-direction: column;
    padding: 3% 2% 3% 3%;
    margin: 3% 2% 5% 2%;

    @media screen and (max-width : 500px){
        background-color: ${theme.colors.light_red};
        padding: 0;
        margin: 0;
    };
`;

export const SettingsHeader = Styled.p`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.xxxxl};
    margin-bottom: ${theme.spaces.sm};
    line-height: ${theme.spaces.xxxxl};
    padding-left: 30px;

    .icon-ant{
        color: ${theme.colors.light_purple};
        font-size: ${theme.spaces.lg};
        margin-left: ${theme.spaces.md};
    }
    @media screen and (max-width : 500px){
        font-weight: ${theme.fonts.weights.medium};
        padding: ${theme.spaces.xs} ${theme.spaces.md};
        font-size: 22px;
        margin: 0;
    };
`;

export const SettingsBody = Styled.div`
    display: flex;
    width: 100%;
    margin-right: ${theme.spaces.xl};

    @media screen and (max-width : 650px){
        flex-direction: column;
        align-items: center;
        justify-content: center;
    };

`;

export const SpaceLine = Styled.hr`
    border: 0.1px solid ${theme.colors.light_sky_grey};
    width: 100%;
    height: 0;
    margin-bottom: ${theme.spaces.xl};
    
    @media screen and (max-width: 500px) {
        display: none;
}
`;
export const Settings = Styled.div`
    background: ${theme.colors.primary_white};
    border-radius: ${theme.spaces.md};
    padding: ${theme.spaces.xl} ${theme.spaces.xl} ${theme.spaces.x_50} ${theme.spaces.xl};
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: ${theme.spaces.sm};
    margin-bottom: ${theme.spaces.xxxl};
    box-shadow: 0px 4px 30px rgba(0, 10, 65, 0.1);

    @media screen and (max-width: 650px) {
            width: 355px;
    }
    @media screen and (max-width: 500px) {
            width: 100% !important;
            margin: 0;
            border-radius: 0;
    }
`;

export const Title = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.spaces.md};
    color: ${theme.colors.dark_grey};
    margin-bottom: ${theme.spaces.sm};
    @media screen and (max-width: 500px) {
        display: none;
    }
`;

export const InputTitle = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.sm};
    color: ${theme.colors.dark_grey};
    line-height: ${theme.fonts.sizes.md};
`;

export const InputField = Styled(Input)`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    background: ${theme.colors.light_red};
    border: 1px solid rgba(51, 53, 96, 0.2);
    border-radius: ${theme.fonts.sizes.xs};
    height: 54px;
    margin-top: ${theme.spaces.xs};
    width: 97.6%;
    color: rgba(51, 53, 96, 0.6);
`;

export const FormWrapper = Styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const UpdateButton = Styled(Button)`
    width: 230px;
    height: 46px;
    background-color: ${theme.colors.light_purple};
    border-radius:  ${theme.fonts.sizes.xxxl};
    color: ${theme.colors.primary_white};
    font-weight: ${theme.fonts.weights.medium};
    font-size: 100%;
    font-family: ${theme.fonts.families.poppins};
    margin-top: ${theme.spaces.xl};

    @media screen and (max-width: 500px) {
        width: 90%;
    }
`;

export const FormItemWrapper = Styled.div``;

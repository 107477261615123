import { Select, Input } from 'antd';
import styled from 'styled-components';

import { theme } from 'theme';
import { ReactComponent as SearchIconSVG } from '../../assets/SearchIconSVG.svg';
import { ReactComponent as ArrowBottom } from '../../assets/ArrowBottom.svg';

export const SearchBlock = styled.div`
    display: flex;
    justify-content: end;
`;

export const SearchInput = styled(Input)`
    width: 45%;
    min-width: 100px;
    align-self: center;
    size: large;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    border-radius: 45px !important;
    margin: ${theme.spaces.lg} 4% ${theme.spaces.lg} 0;
    & .ant-select-selector {
        border-radius: ${theme.spaces.x_50} !important;
        border: 1.2px solid #dddddd !important;
        height: 40px !important;
        padding: ${theme.spaces.xxs} ${theme.spaces.sm} !important;
    }
    & .anticon {
        background-color: rgba(58, 58, 58, 0);
    }
    & .ant-select-selection-placeholder {
        color: ${theme.colors.dark_grey} !important;
        opacity: 0.7;
        font-weight: ${theme.fonts.weights.medium};
    }
    @media screen and (max-width: 800px) {
        width: 100%;
        margin: ${theme.spaces.md} ${theme.spaces.md} 0;
    }
`;

export const SearchIcon = styled(SearchIconSVG)`
    width: 15px;
    height: 15px;
    display: block !important;

    & path {
        fill: ${theme.colors.shadow_grey};
    }
`;

export const FilterBlock = styled.div`
    border-bottom: 1px solid rgba(51, 53, 96, 0.15);
    border-top: 1px solid rgba(51, 53, 96, 0.15);
    @media screen and (max-width: 800px) {
        border-top: none;
    }
`;

export const FilterContent = styled.div`
    display: flex;
    margin: 0 4%;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 800px) {
        flex-direction: row-reverse;
        margin: 0;
        overflow-x: hidden;
        flex-wrap: nowrap;
        justify-content: normal;
    }
`;
export const SelectsBlock = styled.div<{ isFilerOpened: boolean }>`
    padding: ${theme.spaces.md} 0;
    display: flex;
    justify-content: left;
    gap: ${theme.spaces.xl};
    flex-wrap: wrap;
    @media screen and (max-width: 830px) {
        gap: 10px;
    }
    @media screen and (max-width: 800px) {
        overflow-x: ${({ isFilerOpened }) =>
            isFilerOpened ? 'hidden' : 'scroll'};
        gap: ${theme.spaces.sm};
        width: 100%;
        flex-wrap: nowrap;
    }
    &:first-child {
        padding-left: ${theme.spaces.md};
    }
    &:last-child {
        padding-right: ${theme.spaces.md};
    }
`;

export const StyledSelect = styled(Select)`
    .ant-select-selector {
        padding: 0 ${theme.spaces.md} !important;
        background: ${theme.colors.light_red} !important;
        color: ${theme.colors.dark_grey} !important;
        font-weight: ${theme.fonts.weights.semiBold} !important;
        margin-right: ${theme.spaces.md} !important;
        height: 54px !important;
        display: flex;
        align-items: center;
        border: none !important;
        border-radius: 5px !important;
        @media screen and (max-width: 800px) {
            height: 36px !important;
            box-shadow: none !important;
            background: ${theme.colors.light_gray} !important;
        }
    }

    .ant-select-arrow {
        user-select: none;
        margin-right: ${theme.spaces.xs};
        margin-top: -3px;
    }
    .ant-select-selection-item {
        color: ${theme.colors.dark_grey} !important;
        font-weight: ${theme.fonts.weights.semiBold} !important;
        line-height: 27px;
        font-size: ${theme.fonts.sizes.xxs};
    }
`;

export const ArrowIcon = styled(ArrowBottom)`
    width: 11px;
    height: 6px;
`;

export const SortBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    @media screen and (max-width: 800px) {
        width: 100%;
        justify-content: start;
    }
`;

export const SortBlockText = styled.span`
    font-weight: ${theme.fonts.weights.semiBold};
    line-height: 27px;
    font-size: ${theme.fonts.sizes.xxs};
    margin-right: ${theme.spaces.xs};
    color: ${theme.colors.dark_grey};
    margin-left: 20px;
    @media screen and (max-width: 800px) {
        display: none;
        margin-left: 0;
    }
`;

export const BlockCollectionItems = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spaces.md} ${theme.spaces.x_100};
    margin: auto;
    position: relative;
    @media screen and (max-width: 1600px) {
        padding: ${theme.spaces.md} ${theme.spaces.x_100};
    }
    @media screen and (max-width: 1380px) {
        padding: ${theme.spaces.md};
    }
    @media screen and (max-width: 1280px) {
        padding: ${theme.spaces.md} ${theme.spaces.x_100};
    }
    @media screen and (max-width: 905px) {
        padding: ${theme.spaces.md} ${theme.spaces.x_50};
    }
    @media screen and (max-width: 805px) {
        padding: ${theme.spaces.md};
    }
    @media screen and (max-width: 400px) {
        padding: 20px 0;
    }
`;

export const TransparentBlock = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 187px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 75%
    );
    z-index: 4;
`;

export const MarginBottomDiv = styled.div`
    margin-bottom: ${theme.spaces.x_50};
`;

import { useMediaQuery } from 'react-responsive';

import { RegistrationLayout } from './components/RegistrationLayout';
import Header from 'components/header';

import { Container, MobileContainer } from '../login-page/login.styled';

const Registration = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <Container>
            {isMobile ? (
                <MobileContainer>
                    <Header />
                    <RegistrationLayout />
                </MobileContainer>
            ) : (
                <RegistrationLayout />
            )}
        </Container>
    );
};

export default Registration;

import { CollectionItem } from './CollectionItem';
import { IPropsRenderCollectionItems } from '../../types';

import { BoxRenderCollectionItems } from './collectionItem.styled';

export const RenderCollectionItems = ({
    collectionItems,
}: IPropsRenderCollectionItems) => {
    return (
        <BoxRenderCollectionItems>
            {collectionItems.map(
                ({ name, image, likes, seen, ownerName, ownerId, _id, contentType }, i) => {
                    return (
                        <CollectionItem
                            key={name + image + i}
                            name={name}
                            userName={ownerName}
                            img={image}
                            likes={likes}
                            views={seen}
                            ownerId={ownerId}
                            ownerName={ownerName}
                            id={_id}
                            contentType={contentType}
                        />
                    );
                }
            )}
        </BoxRenderCollectionItems>
    );
};

import { ArrowDownOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { IPropsShowMoreBtn } from './types';

import { ShowMoreBtnBlock } from './show-more-btn.styled';


export const ShowMoreBtn: React.FC<IPropsShowMoreBtn> = (props) => {
    const { handleShowMore } = props;
    return (
        <ShowMoreBtnBlock>
            <Button type="text" onClick={handleShowMore}>
                show more <ArrowDownOutlined />
            </Button>
        </ShowMoreBtnBlock>
    );
};

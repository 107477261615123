import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';

import { PreLoginRoutes as PreLoginRoutesMap } from './constant';

export const PreLoginRoutes = () => (
    <Switch>
        {PreLoginRoutesMap.map(({ path, component }) => (
            <Route exact key={path} path={path} component={component} />
        ))}
    </Switch>
);

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import PageSkeleton from 'components/page-skeleton/page-skeleton.component';
import ProfileSettingsBlock from 'components/profile-settings-block';
import { AppDispatch } from 'store';
import { UpdateProfileContent } from './components/update-profile-content';
import { getUser } from 'store/slices/userSlice';
import { SettingsItems } from './constants';

import * as Styles from './update-profile-page.styled';

const UpdateProfilePage = () => {
    const [isHiddenSettings, setIsHiddenSettings] = useState(false);
    const isTablet = useMediaQuery({ query: '(max-width: 650px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        setIsHiddenSettings(isTablet);
    }, [isTablet]);

    const handleIconClick = () => {
        setIsHiddenSettings(!isHiddenSettings);
    };
    const IsShowDownIcon = isTablet && isHiddenSettings;

    return (
        <PageSkeleton>
            <Styles.PageWrapper>
                <Styles.ProfileWrapper>
                    <Styles.ProfileHeader>
                        Account Information
                        {isTablet && !isMobile && (
                            <>
                                {IsShowDownIcon ? (
                                    <DownOutlined
                                        className="icon-ant"
                                        onClick={handleIconClick}
                                    />
                                ) : (
                                    <UpOutlined
                                        className="icon-ant"
                                        onClick={handleIconClick}
                                    />
                                )}
                            </>
                        )}
                    </Styles.ProfileHeader>
                    <Styles.ProfileBody>
                        {!isHiddenSettings && !isMobile && (
                            <ProfileSettingsBlock
                                items={SettingsItems}
                                styles="0px 4px 30px rgba(0, 10, 65, 0.1);"
                            />
                        )}
                        {!isMobile ? (
                            <UpdateProfileContent />
                        ) : (
                            <ProfileSettingsBlock items={SettingsItems}>
                                <UpdateProfileContent />
                            </ProfileSettingsBlock>
                        )}
                    </Styles.ProfileBody>
                </Styles.ProfileWrapper>
            </Styles.PageWrapper>
        </PageSkeleton>
    );
};

export default UpdateProfilePage;

import { ROUTER_KEYS } from 'common/data/data';
import AccountIcon from 'assets/AccountIcon.svg';
import MessageIcon from 'assets/MessageIcon.svg';
import ProfileIcon from 'assets/ProfileIcon.svg';
import SettingIcon from 'assets/SettingIcon.svg';

export const DEFAULT_USER_MENU_ITEMS = [
    {
        label: 'Account',
        icon: <img src={AccountIcon} alt="account-icon" />,
        routerKey: ROUTER_KEYS.ACCOUNT,
    },
    {
        label: 'Profile',
        icon: <img src={ProfileIcon} alt="account-icon" />,
        routerKey: ROUTER_KEYS.PROFILE,
    },
    {
        label: 'Settings',
        icon: <img src={SettingIcon} alt="account-icon" />,
        routerKey: ROUTER_KEYS.SETTINGS,
    },
    {
        label: 'Contact us',
        icon: <img src={MessageIcon} alt="account-icon" />,
        routerKey: ROUTER_KEYS.CONTACT_US,
    },
];

import { Spin } from 'antd';
import { useState } from 'react';

import { RenderCollectionItems } from 'containers/account-page/components/card/renderCollectionItems';
import { isAuth } from 'common/helpers';
import { BlockCollectionItems, TransparentBlock } from '../nfts-page.styled';
import { IPropsCollectionItemsBlock } from './types';

export const CollectionItemsBlock = ({
    collectionItems,
    children,
}: IPropsCollectionItemsBlock) => {
    const [show, setShow] = useState(false);

    if (!collectionItems) {
        return <Spin>Loading</Spin>;
    }

    let viewCollectionItems = [];
    if (isAuth()) {
        viewCollectionItems = [...collectionItems];
    } else {
        viewCollectionItems =
            collectionItems.length > 48
                ? collectionItems.slice(0, 48)
                : collectionItems;
    }

    return (
        <BlockCollectionItems>
            <RenderCollectionItems
                show={show}
                setShow={setShow}
                collectionItems={viewCollectionItems}
            >
                {children}
            </RenderCollectionItems>
            {!isAuth() && viewCollectionItems.length > 4 && (
                <TransparentBlock />
            )}
        </BlockCollectionItems>
    );
};

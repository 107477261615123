import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { Spin } from 'antd';

import { ROUTES_KEYS } from 'common/constants';
import { fetchData } from 'common/services/apiHelpers';
import { IUser } from 'common/types';

import { NotFoundBlock, NotFoundMessage } from './invite-page.styled';

const InvitePage = () => {
    const [user, setUser] = useState<IUser | string>();

    const { name } = useParams<{ name: string }>();

    const fetchInfo = async () => {
        try {
            const responseUser = await fetchData(
                `${ROUTES_KEYS.USER}/name/${name}`
            );
            if (responseUser) {
                setUser(responseUser.data.user);
            }
        } catch (err: any) {
            setUser(err.response.data.message);
        }
    };
    useEffect(() => {
        fetchInfo();
    }, []);

    if (typeof user !== 'object') {
        return (
            <NotFoundBlock>
                <NotFoundMessage>Oops, user not found</NotFoundMessage>
            </NotFoundBlock>
        );
    }
    if (!user) {
        return <Spin />;
    }
    return <Redirect to={`/account/${user._id}`} />;
};
export default InvitePage;

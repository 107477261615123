import { Select, Skeleton, Form } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store';
import { getCountries } from 'store/slices/countrySlice';

import { SelectCountrySearch } from '../registration.styled';

const { Option } = Select;

export const SelectCountries = () => {
    const countries = useSelector(
        (state: RootState) => state.country.countries
    );
    const isLoading = useSelector(
        (state: RootState) => state.country.isLoading
    );
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        dispatch(getCountries());
    }, []);

    if (isLoading || !countries) {
        return <Skeleton.Input size="default" active block />;
    }

    return (
        <>
            <label className="textColor">Country/Region</label>
            <Form.Item
                name="country"
                rules={[
                    {
                        required: true,
                        message: 'Please input your country!',
                    },
                ]}
            >
                <SelectCountrySearch
                    placeholder="USA"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option!.children as unknown as string).includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA!.children as unknown as string)
                            .toLowerCase()
                            .localeCompare(
                                (
                                    optionB!.children as unknown as string
                                ).toLowerCase()
                            )
                    }
                >
                    {countries.map(({ _id, name }) => {
                        return (
                            <Option key={_id} value={name}>
                                {name}
                            </Option>
                        );
                    })}
                </SelectCountrySearch>
            </Form.Item>
        </>
    );
};

import { ROUTER_KEYS } from 'common/data/data';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import {
    PreviewBlock,
    PreviewButton,
    PreviewDescription,
    PreviewTitle,
} from '../creator-brand-page.styled';

export const Preview = ({ isAccountPage = false }) => {
    const history = useHistory();
    const createNFTRedirect = useCallback(
        () => history.push(ROUTER_KEYS.CREATE_NFT),
        [history]
    );
    return (
        <PreviewBlock isAccountPage={isAccountPage}>
            <PreviewTitle>CLLB</PreviewTitle>
            <PreviewDescription>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
            </PreviewDescription>
            <PreviewButton onClick={createNFTRedirect}>
                Create A CLLB
            </PreviewButton>
        </PreviewBlock>
    );
};

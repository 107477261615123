import { useMediaQuery } from 'react-responsive';

import NotLogInButtons from './components/not-log-in-buttons';
import { LoggedInButtonsComponent } from './components/logged-in-buttons';
import RenderHeaderButtons from './components/RenderHeaderButtons';
import { isAuth } from 'common/helpers';
import Logo from '../../assets/Logo.svg';
import WhiteLogo from '../../assets/WhiteLogo.svg';
import { IPropsHeaderButtons } from './types';

import * as Styles from './header.styled';
import { useHistory } from 'react-router';
import { ROUTER_KEYS } from 'common/data/data';

const Header = ({
    buttons,
    coloricons = 'black',
    isOpacity = false,
    isSearch = false,
    isBecomeACreator = false,
    isUpload = true,
}: IPropsHeaderButtons) => {
    const isMobile = useMediaQuery({ query: '(max-width: 640px)' });

    const history = useHistory();

    const redirectHome = () => {
        history.push(ROUTER_KEYS.HOME);
        history.location.pathname === '/' && window.location.reload();
    };

    const logo = coloricons === 'white' ? WhiteLogo : Logo;
    return (
        <Styles.WrapperHeader isOpacity={isOpacity}>
            <Styles.ButtonsWithLogo>
                <Styles.Logo
                    src={logo}
                    alt="logo"
                    onClick={redirectHome}
                    className="notDraggable"
                />

                {!isMobile && (
                    <RenderHeaderButtons
                        buttons={buttons}
                        coloricons={coloricons}
                    />
                )}
            </Styles.ButtonsWithLogo>
            {isAuth() ? (
                <LoggedInButtonsComponent
                    coloricons={coloricons}
                    buttons={buttons}
                    isSearch={isSearch}
                    isBecomeACreator={isBecomeACreator}
                    isUpload={isUpload}
                />
            ) : (
                <NotLogInButtons isBecomeACreator={isBecomeACreator} />
            )}
        </Styles.WrapperHeader>
    );
};

export default Header;

import { Select } from 'antd';
import styled from 'styled-components';

export const RegistrationLayout = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    padding: ${({ theme }) => theme.spaces.md} 0;
    p {
        text-align: center;
        padding: ${({ theme }) => theme.spaces.sm} 0
            ${({ theme }) => theme.spaces.sm};
    }
    h1 {
        font-size: ${({ theme }) => theme.fonts.sizes.lg};
        font-weight: 600;
        color: ${({ theme }) => theme.colors.dark_grey};
        @media screen and (max-width: 660px) {
            font-size: ${({ theme }) => theme.fonts.sizes.sm};
        }
    }
    .form {
        margin: auto;
    }
    h2 {
        color: ${({ theme }) => theme.colors.light_grey};
        font-weight: 500;
        padding: ${({ theme }) => theme.spaces.md} 0
            ${({ theme }) => theme.spaces.xs};
        @media screen and (max-width: 660px) {
            font-size: ${({ theme }) => theme.fonts.sizes.xs};
        }
    }
    h3 {
        color: ${({ theme }) => theme.colors.light_grey};
        @media screen and (max-width: 660px) {
            font-size: ${({ theme }) => theme.fonts.sizes.xxs};
        }
    }
    .inputBox {
        margin-bottom: ${({ theme }) => theme.spaces.xs};
    }
    .textColor {
        color: ${({ theme }) => theme.colors.label_grey};
        font-size: ${({ theme }) => theme.fonts.sizes.xs};

        @media screen and (max-width: 660px) {
            font-size: ${({ theme }) => theme.fonts.sizes.xxs};
        }
    }
    .formBox {
        background: white;
        width: 660px;
        box-shadow: 0px 4px 30px 0px ${({ theme }) => theme.colors.shadow_grey};
        border-radius: 20px;
        @media screen and (max-width: 600px) {
            width: 90%;
        }
    }
    .formBox > div {
        padding: ${({ theme }) => theme.spaces.md}
            ${({ theme }) => theme.spaces.xl} 0;
        font-size: ${({ theme }) => theme.fonts.sizes.xs};
        @media screen and (max-width: 660px) {
            padding: ${({ theme }) => theme.spaces.md}
                ${({ theme }) => theme.spaces.sm} 0;
        }
    }
    .formBox > div > div {
        @media screen and (max-width: 660px) {
            font-size: ${({ theme }) => theme.fonts.sizes.xxxs};
        }
    }

    .formBox > div > span {
        font-size: ${({ theme }) => theme.fonts.sizes.xs};
    }

    .checkBox {
        display: flex;
        justify-content: space-between;

        label {
            border: none;
            background: none;
        }

        button {
            border: none;
            background: none;
        }
    }
    .buttonBox {
        display: flex;
        flex-direction: column;
    }
    .buttonBox > button {
        border-radius: 10px;
        margin-top: ${({ theme }) => theme.spaces.sm};
    }

    .formInput {
        margin-top: ${({ theme }) => theme.spaces.xxs};
        border-radius: 10px;
    }
    .textSmall {
        font-size: ${({ theme }) => theme.fonts.sizes.xxs};
    }
    .font-16 {
        font-size: ${({ theme }) => theme.fonts.sizes.xs};
    }

    .login-form p {
        font-size: ${({ theme }) => theme.fonts.sizes.xxxs};
        padding: ${({ theme }) => theme.spaces.xs} 0;
        text-align: initial;
    }

    .greyText {
        color: ${({ theme }) => theme.colors.light_grey};
    }

    .signUpEmail {
        margin-bottom: ${({ theme }) => theme.spaces.sm};
    }
`;

export const NamesBlock = styled.div`
    display: flex;
    justify-content: space-between;
    .box {
        width: 45%;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;
        width: 100%;
        .box {
            width: 100%;
        }
    }
`;

export const SelectCountrySearch = styled(Select)`
    .ant-select-selector {
        border-radius: 10px !important;
    }
`;

import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store';
import { CardBox } from './components/card/index';
import { UserBox } from 'containers/account-page/components/userBox';
import { HeaderComponent } from '../account-page/components/header';

import * as Styles from './account-page.styled';
import * as CommonStyles from 'common/common.styled';
import { getUser } from 'store/slices/userSlice';

const AccountPage = () => {
    const [search, setSearch] = useState('');
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.user);

    useEffect(() => {
        dispatch(getUser());
    }, []);

    if (!user) {
        return <Spin size="large" />;
    }

    return (
        <CommonStyles.PageLayoutWrapper>
            <HeaderComponent onTextInput={setSearch} />

            <Styles.ContentBlock>
                <UserBox user={user} />
                <CardBox search={search} />
            </Styles.ContentBlock>
        </CommonStyles.PageLayoutWrapper>
    );
};

export default AccountPage;

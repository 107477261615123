import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';

import { MobileMenuComponent } from '../mobile-menu';
import Mail from '../../../../assets/Mail.svg';
import Bell from '../../../../assets/Bell.svg';
import WhiteMail from '../../../../assets/WhiteMail.svg';
import WhiteBell from '../../../../assets/WhiteBell.svg';
import { IPropsHeaderButtons } from 'components/header/types';
import { EXTENDED_BTN_HEADER } from 'common/constants';
import { UserDropdownMenu } from '../user-dropdown-menu';
import { isBecomeACreatorShown } from 'common/helpers';

import {
    Icon,
    SearchIcon,
    SearchInput,
    SearchWithAvatar,
    UploadButton,
} from './logged-in-buttons.styled';
import { BecomeCreatorButton } from '../become-a-creator';

export const LoggedInButtonsComponent = ({
    coloricons = 'black',
    buttons = EXTENDED_BTN_HEADER,
    isSearch = false,
    isBecomeACreator,
}: IPropsHeaderButtons) => {
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 924px)' });
    const isShownBecomeCreatorButton = useMediaQuery({
        query: '(max-width: 850px)',
    });

    const mail = coloricons === 'white' ? WhiteMail : Mail;
    const bell = coloricons === 'white' ? WhiteBell : Bell;
    const history = useHistory();
    return (
        <SearchWithAvatar>
            {isBecomeACreatorShown(
                !isShownBecomeCreatorButton,
                isBecomeACreator
            ) && <BecomeCreatorButton />}
            {isSearch && (
                <SearchInput
                    placeholder="Search..."
                    showSearch
                    bordered={true}
                    suffixIcon={<SearchIcon />}
                    notFoundContent={null}
                />
            )}
            {!isMobile && (
                <>
                    <Icon src={mail} alt="mail" className="notDraggable" />
                    <Icon src={bell} alt="bell" className="notDraggable" />
                    <UserDropdownMenu />
                </>
            )}

            {isTabletOrMobile ? (
                <MobileMenuComponent
                    isBecomeACreator={isBecomeACreatorShown(
                        true,
                        isBecomeACreator
                    )}
                    buttons={buttons}
                />
            ) : (
                <UploadButton onClick={() => history.push('/create')}>
                    Create CLLB
                </UploadButton>
            )}
        </SearchWithAvatar>
    );
};

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import GlobalStyles from './global.styled';
import { theme } from './theme';
import { store } from 'store';
import { ThemeProvider } from 'styled-components';
import i18next from './translate/i18';

import './style.css';

const Application = () => {
    return (
        <I18nextProvider i18n={i18next}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <GlobalStyles />
                    <App />
                </Provider>
            </ThemeProvider>
        </I18nextProvider>
    );
};

ReactDOM.render(
    // <React.StrictMode>
    <Application />,
    // </React.StrictMode>,
    document.getElementById('root')
);

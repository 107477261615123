import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

import { RenderSelect } from './renderSelect';
import { AppDispatch, RootState } from 'store';
import { RECOMMENDED_SELECT } from './constants';
import { getCreators } from 'store/slices/userSlice';
import { IPropsFiltersBlock } from './types';
import { getDesigners } from 'store/slices/collectionSlice';
import { ReactComponent as SortIconSVG } from '../../../assets/SortIconSVG.svg';
import { IUser } from 'common/types';

import {
    FilterBlock,
    FilterContent,
    SelectsBlock,
    SortBlock,
    SortBlockText,
} from '../nfts-page.styled';

export const FiltersBlock: React.FC<IPropsFiltersBlock> = ({
    setCreator,
    setDate,
    setDesigner,
    setSortedBy,
    creator,
    designer,
    filteredCollectionItems,
}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
    const [isFilterOpened, setIsFilterOpened] = useState(false);

    const dispatch: AppDispatch = useDispatch();
    const allCreators = useSelector((state: RootState) => state.user.creators);
    const allDesigners = useSelector(
        (state: RootState) => state.collections.designers
    );

    const allUsers: IUser[] = useSelector(
        (state: RootState) => state.user.allUsers
    );

    const weekAgo = moment(Date.now() - 7 * 24 * 3600 * 1000).format(
        'YYYY-MM-DD'
    );

    const monthAgo = moment(Date.now() - 30 * 24 * 3600 * 1000).format(
        'YYYY-MM-DD'
    );

    const today = moment(Date.now()).format('YYYY-MM-DD');

    const designers = useMemo(() => {
        if (creator && allUsers) {
            const user = allUsers.find(
                ({ accountName }) => accountName === creator.split('=')[1]
            );

            return [
                { value: 'All', query: '' },
                ...allDesigners.filter(({ value }) => {
                    return user?.collectionArr.find((name) => name === value);
                }),
            ];
        }
        return allDesigners;
    }, [
        creator,
        allCreators.length,
        allDesigners.length,
        filteredCollectionItems.length,
    ]);

    const creators = useMemo(() => {
        if (designer && allUsers) {
            const users = allUsers.filter((user) => {
                return user.collectionArr.find(
                    (value) => value === designer.split('=')[1]
                );
            });

            return [
                { value: 'All', query: '' },
                ...allCreators.filter(({ value }) => {
                    return users.find(
                        ({ accountName }) => accountName === value
                    );
                }),
            ];
        }
        return [...allCreators];
    }, [designer, filteredCollectionItems.length]);

    const data = useMemo(() => {
        let selectOptions = [
            { value: 'All time', query: '&date=all' },
            { value: 'New today', query: `&date=new` },
            { value: 'Past 7 days', query: `&date=past7` },
            { value: 'Past 30 days', query: `&date=past30` },
        ];

        let newSelectOptions = [{ value: 'All time', query: '&date=all' }];

        if (filteredCollectionItems.length) {
            const isCreatedToday = filteredCollectionItems.find((item) => {
                return moment(today).isSame(
                    item!.createdAt.toString().slice(0, 10)
                );
            });
            isCreatedToday &&
                newSelectOptions.push({
                    value: 'New today',
                    query: `&date=new`,
                });

            const isCreatedWeekAgo = filteredCollectionItems.find((item) => {
                return moment(
                    moment(item!.createdAt.toString().slice(0, 10)).format(
                        'YYYY-MM-DD'
                    )
                ).isBetween(weekAgo, today);
            });

            isCreatedWeekAgo &&
                newSelectOptions.push({
                    value: 'Past 7 days',
                    query: `&date=past7`,
                });

            const isCreatedMonthAgo = filteredCollectionItems.find((item) => {
                return moment(
                    item!.createdAt.toString().slice(0, 10)
                ).isBetween(monthAgo, today);
            });

            isCreatedMonthAgo &&
                newSelectOptions.push({
                    value: 'Past 30 days',
                    query: `&date=past30`,
                });
        }

        return filteredCollectionItems ? newSelectOptions : selectOptions;
    }, [designer, creator]);

    useEffect(() => {
        dispatch(getCreators());
        dispatch(getDesigners());
    }, []);

    return (
        <FilterBlock>
            <FilterContent>
                <SelectsBlock isFilerOpened={isFilterOpened}>
                    {isMobile && (
                        <RenderSelect
                            setIsFilterOpened={setIsFilterOpened}
                            icon={<SortIconSVG />}
                            defaultValue="Sort"
                            setState={setSortedBy}
                            options={RECOMMENDED_SELECT}
                        />
                    )}
                    <RenderSelect
                        setIsFilterOpened={setIsFilterOpened}
                        defaultValue="Creator"
                        setState={setCreator}
                        options={creators}
                    />
                    <RenderSelect
                        setIsFilterOpened={setIsFilterOpened}
                        defaultValue="Collections"
                        setState={setDesigner}
                        options={designers}
                    />
                    <RenderSelect
                        setIsFilterOpened={setIsFilterOpened}
                        setState={setDate}
                        options={data}
                    />
                </SelectsBlock>
                {!isMobile && (
                    <SortBlock>
                        <SortBlockText>Sort:</SortBlockText>
                        <RenderSelect
                            setIsFilterOpened={setIsFilterOpened}
                            setState={setSortedBy}
                            options={RECOMMENDED_SELECT}
                        />
                    </SortBlock>
                )}
            </FilterContent>
        </FilterBlock>
    );
};

import { useHistory } from 'react-router';
import { useState } from 'react';
import type { DrawerProps } from 'antd/es/drawer';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

import { IPropsHeaderButtons } from 'components/header/types';
import { isAuth } from 'common/helpers';
import { ROUTER_KEYS } from 'common/data/data';

import {
    SpaceBox,
    AuthBtnsContainer,
    MobileDrawer,
    AuthContainer,
} from './mobile-menu.styled';
import {
    LogInButton,
    SignUpButton,
} from '../not-log-in-buttons/not-log-in-buttons.styled';
import { UserMenu } from './user-munu';
import { RenderMobileButtons } from './render-mobile-buttons';

export const MobileMenuComponent = ({
    buttons,
    isBecomeACreator,
}: IPropsHeaderButtons) => {
    const [visible, setVisible] = useState(false);
    const [isHiddenSettings, setIsHiddenSettings] = useState(false);

    const history = useHistory();

    const logIn = () => {
        history.push(ROUTER_KEYS.LOGIN);
    };

    const signUp = () => {
        history.push(ROUTER_KEYS.REGISTER);
    };

    const placement: DrawerProps['placement'] = 'right';
    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <>
            <SpaceBox>
                <MenuOutlined onClick={showDrawer} />
            </SpaceBox>
            <MobileDrawer
                isOpen={isHiddenSettings}
                placement={placement}
                width={320}
                onClose={onClose}
                visible={visible}
                closeIcon={<CloseOutlined />}
            >
                {!isAuth() && (
                    <AuthBtnsContainer>
                        <AuthContainer>
                            <LogInButton onClick={logIn}>Log In</LogInButton>
                            <SignUpButton onClick={signUp}>
                                Sign Up
                            </SignUpButton>
                        </AuthContainer>
                    </AuthBtnsContainer>
                )}
                {isAuth() && (
                    <UserMenu
                        isHiddenSettings={isHiddenSettings}
                        setIsHiddenSettings={setIsHiddenSettings}
                    />
                )}
                <RenderMobileButtons
                    buttons={buttons}
                    isBecomeACreator={isBecomeACreator}
                />
            </MobileDrawer>
        </>
    );
};

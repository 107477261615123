import Styled from 'styled-components';
import { Select } from 'antd';
import { theme } from 'theme';

export const PageWrapper = Styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width : 500px){
        align-items:  center;
      };
`;

export const ProfileWrapper = Styled.div`
    background-color: ${theme.colors.light_red};
    width: 96%;    
    border-radius: ${theme.spaces.md};
    display: flex;
    flex-direction: column;
    padding: 3% 2% 3% 3%;
    margin: 3% 2% 5% 2%;

    .icon-ant{
        color: ${theme.colors.light_purple};
        font-size: ${theme.spaces.lg};
        margin-left: ${theme.spaces.md};
    }

    @media screen and (max-width : 500px){
        width: 100%;
       margin: 3% ${theme.spaces.md};
       padding: ${theme.spaces.md} 0;
       border-radius: ${theme.spaces.md};
    };
`;

export const ProfileHeader = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.lg};
    margin-bottom: ${theme.spaces.sm};
    padding-left: 30px;

    .icon-ant{
        color: ${theme.colors.light_purple};
        font-size: ${theme.spaces.lg};
        margin-left: ${theme.spaces.md};
    }

    @media screen and (max-width : 500px){
        font-weight: ${theme.fonts.weights.medium};
        font-size: 22px;
        padding-left: ${theme.spaces.md};
    };
`;

export const ProfileBody = Styled.div`
    display: flex;
    width: 100%;
    margin-right: ${theme.spaces.xl};

    @media screen and (max-width : 650px){
        flex-direction: column;
        align-items: center;
        justify-content: center;
    };

    @media screen and (max-width : 500px){
        margin-right: 0;
    };
`;

export const Profile = Styled.div`
    background: ${theme.colors.primary_white};
    border-radius: ${theme.spaces.md};
    padding:  ${theme.spaces.xl}  ${theme.spaces.xl}  ${theme.spaces.x_50} ${theme.spaces.xl};
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: ${theme.spaces.sm};
    margin-bottom: ${theme.spaces.x_50};

    @media screen and (max-width: 650px) {
            width: 355px;
    }

    @media screen and (max-width: 500px) {
        padding: 0;
        margin:0;
        width: 100%;
        padding: 0 ${theme.spaces.sm} ${theme.spaces.md};
        border-radius: 0;
        border:1px solid rgba(51, 53, 96, 0.1);

    }
`;

export const Title = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.fonts.sizes.xs};

    @media screen and (max-width: 500px) {
        display:none;
    }
`;

export const SpaceLine = Styled.hr`
    border: 0.1px solid ${theme.colors.light_sky_grey};
    width: 100%;
    height: 0;
    margin-bottom: ${theme.spaces.xl};

    @media screen and (max-width: 500px) {
        display:none;
    }
`;

export const ProfileAvatar = Styled.div`
    background: ${theme.colors.light_red};
    border: 1px solid rgba(51, 53, 96, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;

    @media screen and (max-width : 770px){
        width: 95%;
    };
    @media screen and (max-width : 650px){
        width: 95%;
    };

    @media screen and (max-width : 500px){
        margin: ${theme.spaces.md} auto 0;
    };
`;

export const ProfileAvatarImage = Styled.img<{
    imgURL?: string | null;
}>`
    object-fit: contain;
    width:${({ imgURL }) => (imgURL ? '100%' : '70px')};
    height: ${({ imgURL }) => (imgURL ? '100%' : '70px')};
    object-fit: cover;
    border-radius: 100%;

    @media screen and (max-width : 500px){
        width:${({ imgURL }) => (imgURL ? '120px' : '70px')};
        height:${({ imgURL }) => (imgURL ? '120px' : '70px')};
    };

`;

export const ProfileAvatarDiv = Styled.div`
    border-radius: 100%;        
    margin-top:  ${theme.spaces.lg};
    width: 122px;
    height: 122px;
    background: grey;
`;

export const ProfileAvatarTitle = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.fonts.sizes.xs};
    color: ${theme.colors.dark_grey};
    padding:  ${theme.spaces.sm} ${theme.spaces.xxs} ${theme.spaces.xl};
    text-align: center;

    @media screen and (max-width : 500px){
        font-size: 16px;
    };
`;

export const ProfileInfoWrapper = Styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${theme.spaces.xs};
`;

export const InputWrapper = Styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${theme.spaces.md};
`;

export const InputSubTitle = Styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    margin-bottom: ${theme.spaces.xs};
    color: ${theme.colors.dark_grey};

    @media screen and (max-width : 500px){
        color: rgba(51, 53, 96, 0.6);
        font-size: 14px;
        margin-bottom: ${theme.spaces.xxs};
    };
`;

export const HalfInputWrapper = Styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width : 800px){
        flex-direction: column;
    };
`;

export const HalfInputField = Styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    background: ${theme.colors.light_red};
    border: 1px solid rgba(51, 53, 96, 0.2);
    border-radius: ${theme.spaces.xs};
    min-height: 54px;
    margin-top: ${theme.spaces.xs};
    width: 95%;
    display: flex;
    align-items: center;

    span{
        margin-left:${theme.spaces.xs};
    }
`;

export const HalfSelectField = Styled(Select)`
.ant-select-arrow {
    position: absolute;
    right: 8%;
    margin-top: 1px;
}
.ant-select-selector {
    width: 95% !important;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    background: ${theme.colors.light_red};
    border: 1px solid rgba(51, 53, 96, 0.2) !important;
    border-radius: ${theme.spaces.xs} !important;
    height: 54px !important;
    margin-top: ;
    display: flex;
    align-items: center;
}`;

export const InputInvitationLink = Styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    background: ${theme.colors.light_red};
    border: 1px solid rgba(51, 53, 96, 0.2);
    border-radius: ${theme.spaces.xs};
    margin-top: ${theme.spaces.xs};
    width: 97.6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-all;
    
    button{background:none}
    div{
        padding: ${theme.spaces.xs};
        margin: 0 ${theme.spaces.xs};
        color: rgba(51, 53, 96, 0.6);
    }
`;

export const InputField = Styled(InputInvitationLink)`
   min-height: 54px; 

    div{
        padding: ${theme.spaces.xs} 0;
        margin: 0 ${theme.spaces.xs};
        color: rgba(51, 53, 96, 0.6);
    }
`;

export const Space = Styled.div`
    height: 30px;
`;

export const Avatar = Styled.div`
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin-top: 34px;
    background-color: ${theme.colors.light_purple};
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media screen and (max-width : 500px){
        width: 120px;
        height: 120px;
    };
`;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Form } from 'antd';

import { RenderFormItems } from '../components/form-items';
import { INPUT_ITEMS } from '../constants';
import { updateUser } from 'store/slices/userSlice';
import { AppDispatch, RootState } from 'store';
import { updateOwnerName } from 'store/slices/collectionItemSlice';
import { updateOwnerNameCollections } from 'store/slices/collectionSlice';
import { notify } from 'common/helpers';

import * as Styles from '../settings-page.styled';
import 'react-toastify/dist/ReactToastify.css';

export const SettingContent = () => {
    const [isLoading, setIsloading] = useState(false);
    const user = useSelector((state: RootState) => state.user.user);
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        setIsloading(true);
        const updatedUser = await dispatch(updateUser(values));
        await dispatch(
            updateOwnerName({
                ownerName: values.accountName as unknown as string,
            })
        );
        await dispatch(
            updateOwnerNameCollections({
                ownerName: values.accountName as unknown as string,
            })
        );
        if (updatedUser.payload) {
            notify('success', 'Settings has been updated successfully');
            form.resetFields();
        } else {
            notify('error', 'Something went wrong, try again');
        }
        setIsloading(false);
    };

    const dispatch: AppDispatch = useDispatch();

    return (
        <Styles.Settings>
            <Styles.Title>Account information</Styles.Title>
            <ToastContainer
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                limit={1}
            />
            <Styles.SpaceLine />
            <Styles.FormWrapper
                form={form}
                name="create_form"
                onFinish={onFinish}
            >
                <RenderFormItems user={user} renderItems={INPUT_ITEMS} />
                <Styles.SpaceLine />
                <Styles.UpdateButton htmlType="submit" disabled={isLoading}>
                    Update Profile
                </Styles.UpdateButton>
            </Styles.FormWrapper>
        </Styles.Settings>
    );
};

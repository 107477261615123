import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ROUTES_KEYS } from 'common/constants';
import { changeIsLoading } from 'common/helpers/slices';
import {
    fetchUpdate,
    fetchData,
    fetchDelete,
} from 'common/services/apiHelpers';
import {
    IInitialUserState,
    IUpdateActionsUser,
    IUpdateUser,
} from 'common/types';

const initialState: IInitialUserState = {
    isLoading: false,
    user: null,
    isAuth: false,
    creators: [],
    currentAccount: null,
    setIsWeb3Initialized: false,
    allUsers: [],
};

export const getUser = createAsyncThunk('user/getUser', () =>
    fetchData(ROUTES_KEYS.USER)
);
export const getAllUsers = createAsyncThunk('user/getAllUsers', () =>
    fetchData(`${ROUTES_KEYS.USER}/all`)
);
export const getCurrentAccount = createAsyncThunk(
    'user/getCurrentAccount',
    (id: string) => fetchData(`${ROUTES_KEYS.USER}/${id}`)
);

export const getCreators = createAsyncThunk('user/getCreators', () =>
    fetchData(`${ROUTES_KEYS.USER}/creators`)
);

export const updateUser = createAsyncThunk(
    'user/updateUser',
    (body: IUpdateUser) => fetchUpdate(body, ROUTES_KEYS.USER)
);

export const updateActionsUser = createAsyncThunk(
    'user/updateActionsUser',
    (data: IUpdateActionsUser) =>
        fetchUpdate(data.body, `${ROUTES_KEYS.USER}/${data.id}`)
);
export const removeActionsUser = createAsyncThunk(
    'user/removeActionsUser',
    (data: IUpdateActionsUser) =>
        fetchDelete(data.body, `${ROUTES_KEYS.USER}/${data.id}`)
);

export const setIsWeb3Initializedd = createAsyncThunk(
    'user/removeActionsUser',
    () => {}
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUser: (state, action) => {},
        updateUser: (state, action) => {},
        getCreators: (state, action) => {},
        updateActionsUser: (state, action) => {},
        removeActionsUser: (state, action) => {},
        getCurrentAccount: (state, action) => {},
        getAllUsers: (state, action) => {},
        setIsAuth: (state) => {
            state.isAuth = true;
            return state;
        },
        removeIsAuth: (state) => {
            state.isAuth = false;
            return state;
        },
        setIsWeb3Initialized: (state) => {
            state.setIsWeb3Initialized = true;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                changeIsLoading(state);
            })
            .addCase(updateUser.pending, (state) => {
                changeIsLoading(state);
            })
            .addCase(getUser.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.user = action.payload.data.user;
                }
            })
            .addCase(getCurrentAccount.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.currentAccount = action.payload.data.user;
                }
            })
            .addCase(getCreators.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.creators = [
                        { value: 'All', query: '' },
                        ...action.payload.data,
                    ];
                }
            })

            .addCase(updateActionsUser.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.user = action.payload.data.updatedUser;
                }
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.allUsers = [...action.payload.data.users];
                }
            })
            .addCase(removeActionsUser.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.user = action.payload.data.updatedUser;
                }
            })

            .addCase(updateUser.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.user = action.payload.data.updatedUser;
                }
            })

            .addCase(getUser.rejected, (state) => {
                changeIsLoading(state);
            });
    },
});
export const { setIsAuth, removeIsAuth, setIsWeb3Initialized } =
    userSlice.actions;

export default userSlice;

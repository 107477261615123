import { useHistory } from 'react-router';
import { BecomeCreatorButton } from '../become-a-creator';
import { IPropsHeaderButtons } from 'components/header/types';
import { EXTENDED_BTN_HEADER } from 'common/constants';

import {
    MobileButtonsContainer,
    MobileHeaderButton,
    Container,
} from './mobile-menu.styled';
import { UploadButton } from '../logged-in-buttons/logged-in-buttons.styled';

export const RenderMobileButtons = ({
    buttons = EXTENDED_BTN_HEADER,
    isBecomeACreator,
}: IPropsHeaderButtons) => {
    const history = useHistory();

    return (
        <Container>
            {isBecomeACreator && <BecomeCreatorButton />}
            <MobileButtonsContainer>
                {buttons.map(({ name, link }) => (
                    <MobileHeaderButton key={name} to={link}>
                        {name}
                    </MobileHeaderButton>
                ))}
                <UploadButton isMobile onClick={() => history.push('/create')}>
                    Create CLLB
                </UploadButton>
            </MobileButtonsContainer>
        </Container>
    );
};

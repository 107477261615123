import { Form } from 'antd';

import { IPropsFormItem } from '../types';
import { InputField } from '../update-profile-page.styled';

export const FormItem = ({
    inputName,
    placeholder,
    pattern,
    message,
    maxLength,
    isName = false,
}: IPropsFormItem) => {
    return (
        <>
            <Form.Item
                name={inputName}
                rules={[
                    {
                        pattern: pattern,
                        message: message,
                    },
                ]}
            >
                <InputField
                    isName={isName}
                    placeholder={placeholder}
                    className="formInput"
                    maxLength={maxLength}
                />
            </Form.Item>
        </>
    );
};

import {
    BecomeCreatorButtonContainer,
    BecomeCreatorButtonIcon,
    BecomeCreatorButtonText,
} from './become-a-creator.styled';
import Icon from '../../../../assets/BecomeACreator.png';
import { useHistory } from 'react-router';
import { ROUTER_KEYS } from 'common/data/data';
import { useCallback } from 'react';

export const BecomeCreatorButton = () => {
    const history = useHistory();

    const creatorBrandRedirect = useCallback(() => {
        history.push(ROUTER_KEYS.CREATOR_BRAND);
    }, [history]);

    return (
        <BecomeCreatorButtonContainer>
            <BecomeCreatorButtonIcon src={Icon} className="notDraggable" />
            <BecomeCreatorButtonText onClick={creatorBrandRedirect}>
                Become A Creator
            </BecomeCreatorButtonText>
        </BecomeCreatorButtonContainer>
    );
};

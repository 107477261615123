import { IHeaderButton } from 'common/types';
import { ROUTER_KEYS } from 'common/data/data';

export const ROUTES = {
    ROUTES_VENUES: 'venue',
    ROUTES_TABLES: 'table',
    ROUTES_MENU: 'menu',
    ROUTES_MENU_ITEM_OPTION: 'menu-item-option',
};

export const PASSWORD_MATCH =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&*])(?=.{8,})/;

export enum StoreKeys {
    Token = 'token',
}

export const STORAGE_KEYS = sessionStorage.getItem('token');

export const ROUTES_KEYS = {
    COLLECTION: 'collection',
    USER: 'user',
    COLLECTION_ITEM: 'collectionItem',
    ALL: 'collectionItem/allWithoutQuery',
    ALL_BY_QUERY:'collectionItem/allByQuery',
    EMAIL: 'email',
    COUNTRY: 'country',
};

export const DEFAULT_BTN_HEADER: IHeaderButton[] = [
    { name: 'NFTs', link: ROUTER_KEYS.HOME },
    // { name: 'DAOs', link: '#' },
    { name: 'About Us', link: ROUTER_KEYS.ABOUT_US },
];
export const EXTENDED_BTN_HEADER: IHeaderButton[] = [...DEFAULT_BTN_HEADER];

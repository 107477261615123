import { useState } from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';

import { IPropsProfileSettingItem } from './types';

import CloseArrow from '../../assets/CloseArrow.svg';
import OpenArrow from '../../assets/OpenArrow.svg';

import * as Styles from './profile-settings-block.styled';

export const ProfileSettingItem = ({
    setActiveElement,
    item,
    i,
    activeElement,
    children,
}: IPropsProfileSettingItem) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const history = useHistory();
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

    const handleClick = () => {
        if (item.isExist) {
            if (!isOpen) {
                setActiveElement(i);
                item?.link && history.push(item.link);
            } else {
                setActiveElement(null);
            }
            setIsOpen(!isOpen);
        }
    };
    return (
        <>
            <Styles.BlockItem
                onClick={handleClick}
                activeElement={activeElement === i}
                isOpen={isOpen}
                isExist={item.isExist}
            >
                {item.name}
                {isMobile && (
                    <button onClick={handleClick}>
                        {isOpen ? (
                            <img src={CloseArrow} alt="close-icon" />
                        ) : (
                            <img src={OpenArrow} alt="open-icon" />
                        )}
                    </button>
                )}
            </Styles.BlockItem>
            {isMobile &&
                activeElement === 0 &&
                (item.name === 'Basic Information' ||
                    item.name === 'Account Information') &&
                children}
        </>
    );
};

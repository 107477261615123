import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ROUTES_KEYS } from 'common/constants';
import { changeIsLoading } from 'common/helpers/slices';
import { fetchData } from 'common/services/apiHelpers';
import { IInitialCountryState } from 'common/types';

const initialState: IInitialCountryState = {
    isLoading: false,
    countries: [],
};

export const getCountries = createAsyncThunk('country/getCountries', () =>
    fetchData(`${ROUTES_KEYS.COUNTRY}`)
);

export const userSlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        getCountries: (state, action) => {},
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCountries.pending, (state) => {
                changeIsLoading(state);
            })
            .addCase(getCountries.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.countries = [...action.payload.data];
                }
            })
            .addCase(getCountries.rejected, (state) => {
                changeIsLoading(state);
            });
    },
});

export default userSlice;

import styled from 'styled-components';

import { theme } from 'theme';

import BackgroundPreLogin from '../../assets/BackgroundPreLogin.png';

export const TopBlock = styled.div`
    width: 100%;
    background-image: url(${BackgroundPreLogin});
    position: relative;
`;

export const TextTopBlock = styled.div`
    padding: ${theme.spaces.x_100} 0;
    margin-bottom: ${theme.spaces.xs};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 900px) {
        padding: ${theme.spaces.x_50} 0;
    }
    @media (max-width: 710px) {
        display: none;
    }
    h1 {
        font-weight: ${theme.fonts.weights.semiBold};
        font-size: ${theme.fonts.sizes.xxxl};
        line-height: 60px;
        color: ${theme.colors.primary_white};
    }
    h2 {
        font-size: ${theme.fonts.sizes.xs};
        line-height: 27px;
        color: ${theme.colors.primary_white};
    }
`;

export const ContentBlock = styled.div`
    font-family: ${theme.fonts.families.poppins};
    align-items: top;
    justify-content: space-start;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: ${theme.spaces.xxxl};
    @media (max-width: 900px) {
        flex-wrap: wrap;
        justify-content: center;
    }
    @media (max-width: 500px) {
        padding: 0;
    }
`;

export const UserBlock = styled.div`
    font-family: ${theme.fonts.families.poppins};
    max-width: 30%;
    min-width: 350px;
`;
export const UserWrapper = styled.div`
    border-radius: ${theme.spaces.xs};
    box-shadow: 0px 4px 30px 0px #000f431a;
`;

export const UserCard = styled.div`
    padding: ${theme.spaces.xl};
    display: flex;
    flex-direction: column;

    button {
        border-radius: ${theme.spaces.xs};
        margin: ${theme.spaces.xs} 0;
        border: none;
        padding: ${theme.spaces.xs} 0;
        background: ${theme.colors.light_purple};
        color: white;
        font-weight: ${theme.fonts.weights.semiBold};
    }
    .secondBtn {
        color: ${theme.colors.light_purple};
        background: rgba(81, 88, 255, 0.1);
        border: 1px solid rgba(81, 88, 255, 0.3);
    }
`;
export const UserInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
        font-size: ${theme.fonts.sizes.lg};
        line-height: 45px;
        color: ${theme.colors.dark_grey};
        font-weight: ${theme.fonts.weights.semiBold}
        text-align: center;
    }
`;
export const Avatar = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 75%;
    margin-bottom: ${theme.spaces.sm};
`;

export const Bio = styled.div`
    font-size: ${theme.fonts.sizes.xs};
    line-height: 27px;
    color: ${theme.colors.dark_grey};
    padding: ${theme.spaces.sm} 0;
`;
export const Location = styled.div`
    display: flex;
    align-items: center;
    padding: ${theme.spaces.md} 0;
    div {
        height: 17px;
    }
    img {
        margin-right: ${theme.spaces.xxs};
        margin-bottom: ${theme.spaces.sm};
    }
    span {
        font-weight: ${theme.fonts.weights.regular};
        font-size: ${theme.fonts.sizes.xs};
        color: ${theme.colors.dark_grey};
    }
`;

export const UserLink = styled.a`
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xs};
    color: ${theme.colors.dark_grey};
    text-decoration: underline;
`;

export const UserInfoList = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${theme.spaces.sm};
    .infoBlock {
        display: flex;
        justify-content: space-between;
    }

    .infoBlockLeft {
        font-weight: ${theme.fonts.weights.semiBold};
        font-size: ${theme.fonts.sizes.xs};
        line-height: 27px;
        color: ${theme.colors.dark_grey};
    }
    .infoBlockRight {
        font-weight: ${theme.fonts.weights.regular};
        font-size: ${theme.fonts.sizes.xs};
        line-height: 27px;
        color: ${theme.colors.dark_grey};
        opacity: 0.5;
    }
`;

export const CardsBlock = styled.div`
    max-width: 1280px;
    margin: 0;
    margin-left: 40px;
    font-family: ${theme.fonts.families.poppins};
    .active {
        background: ${theme.colors.light_purple};
        color: white;
        padding: ${theme.spaces.xxs} ${theme.spaces.xs};
        border-radius: ${theme.spaces.xs};
        margin-right: ${theme.spaces.xs};

        @media (max-width: 500px) {
            border-radius: ${({ theme }) => theme.spaces.md};
            padding: ${theme.spaces.xxs} ${theme.spaces.xl};
        }
    }

    .activityBtn {
        margin-right: ${theme.spaces.xs};
        color: #bcb9b9;
    }
    @media (max-width: 900px) {
        margin: 20px 0;
    }
    @media (max-width: 400px) {
        width: 100%;
    }
`;

export const ButtonsBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 900px) {
        justify-content: center;
    }
    @media (max-width: 500px) {
        margin: 0 auto;
        width: 70%;
        justify-content: space-around;
    }
`;

export const ButtonCard = styled.button`
    border: none;
    font-size: ${theme.fonts.sizes.xs};
    line-height: 27px;
    color: ${theme.colors.dark_grey};
    background: none;
    font-weight: ${theme.fonts.weights.semiBold};
    margin-bottom: ${theme.spaces.sm};
    @media (max-width: 500px) {
        font-weight: ${theme.fonts.weights.regular};
    }
`;

export const SocialText = styled.h3`
    font-weight: ${theme.fonts.weights.semiBold};
    color: ${theme.colors.dark_grey};
    text-align: center;
    padding: ${theme.spaces.x_50} 0 ${theme.spaces.lg};
    @media (max-width: 900px) {
        padding: ${theme.spaces.sm} 0;
    }
`;

export const SocialBox = styled.div`
    display: flex;
    justify-content: center;
`;

export const SocialItem = styled.a`
    border-radius: ${theme.spaces.xxs};
    width: ${theme.spaces.x_50};
    background: ${theme.colors.light_grey_background};
    margin-right: ${theme.spaces.sm};

    &:hover {
        box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
    }
`;

export const SocialImage = styled.img`
    margin: auto;
    padding: ${theme.spaces.sm} 0;
`;

export const UserInformBlock = styled.div`
    color: ${theme.colors.dark_grey};
    opacity: 0.5;
    font-size: 14px;
`;
export const InfoTitle = styled.div`
    font-wight: ${theme.fonts.weights.medium};
`;

export const InfoTextAbout = styled.div`
    font-wight: ${theme.fonts.weights.regular};
    line-height: 24px;
    padding: 15px 0;
    font-size: 14px;
    word-break: break-all;
`;

export const InfoTextMember = styled.div`
    font-wight: ${theme.fonts.weights.regular};
    line-height: 21px;
    padding: 15px 0;
    font-size: 14px;
    text-align: center;
`;

export const InfoTextReport = styled.div`
    font-size: 14px;
    text-align: center;
`;

import { ROUTER_KEYS } from 'common/data/data';

import AccountPage from 'containers/account-page';
import ContactUsPage from 'containers/contact-us-page';
import CreatePage from 'containers/create-page';
import CreatorBrandPage from 'containers/creator-brand-page';
import AboutUsPage from 'containers/about-us-page';
import InvitePage from 'containers/invite-page';
import Login from 'containers/login-page';
import NftsPage from 'containers/nfts-page';
import ProductPage from 'containers/product-page';
import Registration from 'containers/registration-page';
import SettingPage from 'containers/setting-page';
import UpdateProfilePage from 'containers/update-profile-page';
import UserAccountPage from 'containers/user-account-page';

interface IRoute {
    path: string;
    component: (() => JSX.Element) | React.FC<{}>;
}

const PrivateRoutes: IRoute[] = [
    { path: ROUTER_KEYS.PROFILE, component: UpdateProfilePage },
    { path: ROUTER_KEYS.CREATE_NFT, component: CreatePage },
    { path: ROUTER_KEYS.ACCOUNT, component: AccountPage },
    { path: ROUTER_KEYS.SETTINGS, component: SettingPage },
];
const PreLoginRoutes: IRoute[] = [
    { path: ROUTER_KEYS.HOME, component: NftsPage },
    { path: ROUTER_KEYS.REGISTER, component: Registration },
    { path: ROUTER_KEYS.LOGIN, component: Login },
    { path: ROUTER_KEYS.USER_ACCOUNT, component: UserAccountPage },
    { path: ROUTER_KEYS.INVITE_ACCOUNT, component: InvitePage },
    { path: ROUTER_KEYS.ABOUT_US, component: AboutUsPage },
    { path: ROUTER_KEYS.CONTACT_US, component: ContactUsPage },
    { path: ROUTER_KEYS.CREATOR_BRAND, component: CreatorBrandPage },
    { path: ROUTER_KEYS.PRODUCT, component: ProductPage },
];

export { PrivateRoutes, PreLoginRoutes };

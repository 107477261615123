import { Form } from 'antd';

import { normFile } from 'common/helpers';

import {
    DraggerField,
    Title,
    UploadContainer,
} from '../contact-us-page.styled';

export const RenderUploadComponent = () => {
    return (
        <UploadContainer>
            <Title>Attachments (Optional)</Title>
            <Form.Item>
                <Form.Item
                    name="files"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <DraggerField
                        name="files"
                        action="/upload.do"
                        multiple={true}
                        beforeUpload={() => false}
                    >
                        <p className="ant-upload-text">
                            <span>Add file</span> or drop files here
                        </p>
                    </DraggerField>
                </Form.Item>
            </Form.Item>
        </UploadContainer>
    );
};

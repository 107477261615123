import { Upload, Input, Select, Button, Form } from 'antd';

import { ReactComponent as Photo } from '../../assets/Photo.svg';
import { theme } from 'theme';

import styled from 'styled-components';

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 0 0 ${theme.spaces.md};

    @media (max-width: 800px) {
        width: 90%;
    }
`;

export const FormWrapper = styled(Form)`
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    border: 1px solid transparent;
    width: 100%;
    background: ${theme.colors.light_red};
    border-radius: ${theme.spaces.md};
    padding: 2.1% 2.1% 3% 2.1%;

    @media (max-width: 800px) {
        margin-top: 20px;
        padding: 2.1% 2.1% 5% 2.1%;
    }
`;

export const MainTitle = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    color: ${theme.colors.dark_grey};
    font-size: 22px;
`;

export const SpaceLine = styled.hr`
    width: 100%;
    border: 1px solid ${theme.colors.light_sky_grey};
    height: 0;
    margin-top: ${theme.spaces.sm};

    @media (max-width: 500px) {
        margin: ${theme.spaces.sm} 0;
    }
`;

export const MainSubTitle = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    color: ${theme.colors.dark_grey};
    font-size: 18px;
    margin-top: ${theme.spaces.x_50};

    @media (max-width: 500px) {
        margin-top: ${theme.spaces.xl};
        font-size: 16px;
    }
`;

export const UploadTitle = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    color: ${theme.colors.dark_grey};
    font-size: 20px;
    margin-bottom: ${theme.spaces.xs};
    margin-top: ${theme.spaces.md};

    @media (max-width: 500px) {
        margin-top: ${theme.spaces.xs};
    }
`;

export const SubTitle = styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    color: rgba(51, 53, 96, 0.7) !important;

    @media (max-width: 500px) {
        width: 182px;
        margin: 0 auto !important;
        font-size: 12px;
    }
`;

export const UploadZone = styled(Upload)`
    border: 3px dashed #c3c4cb !important;
    border-radius: 10px !important;
    width: 50% !important;
    height: 270px !important;

    @media (max-width: 1250px) {
        width: 70% !important;
        overflow: hidden !important;
    }

    @media (max-width: 800px) {
        width: 100% !important;
    }
`;

export const UploadImageWrapper = styled.p``;
export const UploadWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`;
export const UploadImage = styled(Photo)``;

export const UploadButton = styled.button`
    width: 184px;
    height: 50px;
    border: none;
    color: ${theme.colors.light_purple};
    background-color: rgba(81, 88, 255, 0.1);
    border-radius: 26px;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.xxs};
    cursor: pointer;
    margin-top: ${theme.spaces.md};
    @media (max-width: 500px) {
        font-size: 16px;
    }
`;

export const Title = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: 18px;
    color: ${theme.colors.dark_grey};

    @media (max-width: 500px) {
        font-size: 14px;
    }
`;

export const InputSubTitle = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    margin-top: 8px;
    color: rgba(51, 53, 96, 0.6);

    @media (max-width: 500px) {
        font-size: 14px;
    }
`;

export const InputField = styled(Input)`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    background: ${theme.colors.light_red};
    border: 1px solid rgba(51, 53, 96, 0.2);
    border-radius: 10px;
    height: 54px;
    margin-top: ${theme.spaces.xs};
`;

export const TextAreaField = styled(TextArea)`
    background: ${theme.colors.light_red};
    border: 1px solid rgba(51, 53, 96, 0.2);
    border-radius: 10px;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    margin-top: ${theme.spaces.xs};
    height: 28vh !important;

    @media (max-width: 800px) {
        height: 300px !important;
    }
    @media (max-width: 650px) {
        height: 250px !important;
    }

    @media (max-width: 500px) {
        height: 200px !important;
    }
`;

export const SelectField = styled(Select)`
    .ant-select-selector {
        background: ${theme.colors.light_red} !important;
    }
    .ant-select-arrow {
        margin-top: 1px;
    }
    .ant-select-selector {
        width: 100% !important;
        font-family: ${theme.fonts.families.poppins};
        font-weight: ${theme.fonts.weights.regular};
        font-size: ${theme.fonts.sizes.xxs};
        background: ${theme.colors.light_red};
        border: 1px solid rgba(51, 53, 96, 0.2) !important;
        border-radius: 10px !important;
        height: 54px !important;
        margin-top: ${theme.spaces.xs};
        display: flex;
        align-items: center;
    }
`;

export const CreateNewCollectionSelect = styled(SelectField)`
    .ant-select-item-option-content {
        white-space: break-spaces;
        word-break: break-word;
    }
`;

export const OptionField = styled(Option)``;

export const CreateOptionField = styled.div`
    @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const CreateButton = styled(Button)`
    width: 30%;
    height: 46px;
    background-color: ${theme.colors.light_purple};
    border-radius: 40px;
    color: ${theme.colors.primary_white};
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: 100%;
    font-family: ${theme.fonts.families.poppins};
    margin-top: ${theme.spaces.xl};
    @media (max-width: 700px) {
        margin-top: 0;
    }

    @media (max-width: 500px) {
        font-size: 16px;
        width: 150px;
    }

    @media (max-width: 350px) {
        width: 50%;
    }
`;

export const SignUpButton = styled(Button)`
    width: 230px;
    height: 46px;
    background-color: ${theme.colors.light_purple};
    border-radius: 40px;
    color: ${theme.colors.primary_white};
    font-weight: ${theme.fonts.weights.medium};
    font-size: 100%;
    font-family: ${theme.fonts.families.poppins};
    margin: ${theme.spaces.xl} ${theme.spaces.md} ${theme.spaces.xl} 0px;
`;

export const Footer = styled.div`
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
`;

export const FooterTitle = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.fonts.sizes.sm};
    width: 100% !important;
    text-align: center;
    margin-top: 120px;
`;

export const FooterButtons = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: ${theme.spaces.x_50};
    justify-content: center;
`;

export const Or = styled.p`
    margin-right: ${theme.spaces.md};
`;

export const FormItemWrapper = styled.div``;

export const ButtonWrapper = styled.div`
    display: flex;
    gap: ${theme.spaces.md};
`;

export const ErrorMessage = styled.p<{ isImage?: boolean }>`
    color: red;
    font-size: 12px;
    margin: ${({ isImage }) => (isImage ? ' -40px 0 15px' : '-15px 0 15px')};
`;

export const CreateNewCollectionButton = styled(Button)`
    margin: 0 ${theme.spaces.xs} 0 0;
    padding: 0;

    @media (max-width: 500px) {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }
`;

export const CreateNewCollectionInput = styled.input`
    border-radius: ${theme.spaces.xs};
    padding: ${theme.spaces.xxs};
    border: 1px solid ${theme.colors.not_active};
    color: ${theme.colors.black} !important;
    outline: none;
    &:focus {
        border: 1px solid ${theme.colors.focused} !important;
    }
`;

export const ButtonType = styled(Button)`
    border-radius: 8px;
`;

export const DescriptionCounter = styled.div<{ isFull?: boolean }>`
    position: absolute;
    z-index: 10;
    align-self: flex-end;
    color: ${({ isFull }) =>
        isFull ? theme.colors.primary_red : theme.colors.grey_active};
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const SelectTagsWrapper = styled(Select)`
    width: '100%';
    .ant-select-selector {
        background: ${theme.colors.light_red} !important;
    }
    .ant-select-arrow {
        margin-top: 1px;
    }
    .ant-select-selector {
        width: 100% !important;
        font-family: ${theme.fonts.families.poppins};
        font-weight: ${theme.fonts.weights.regular};
        font-size: ${theme.fonts.sizes.xxs};
        background: ${theme.colors.light_red};
        border: 1px solid rgba(51, 53, 96, 0.2) !important;
        border-radius: 10px !important;
        min-height: 54px !important;
        margin-top: ${theme.spaces.xs};
        display: flex;
        align-items: center;
    }
`;

export const PreViewBorder = styled.div`
    border:  3px dashed #c3c4cb;
    padding: 10px;
    border-radius: 10px;
    height: 300px;
    margin-bottom: 20px;
    display: block;
    position: relative;
}`;

export const PreViewVideo = styled.video`
    width: 100%;
    height: 100%;
`;

export const ViewHover = styled.div`
    position: absolute;
    width: 97%;
    height: 100%;
    top: 0;
    button {
        position: absolute;
        left: calc(50% - 10px);
        top: calc(50% - 8px);
        z-index: 2;
        border: none;
        background: none;
        display: none;
        cursor: pointer;
        color: white;
    }
    &:hover {
        button {
            display: flex;
        }
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.3);
    }
`;

import { ISpaces } from 'styled-components';

export const spaces: ISpaces = {
    x_110: '110px',
    x_100: '100px',
    x_80: '80px',
    x_50: '50px',
    xxxxl: '45px',
    xxxl: '40px',
    xxl: '35px',
    xl: '30px',
    lg: '25px',
    md: '20px',
    sm: '15px',
    xs: '10px',
    xxs: '5px',
    xxxs: '1px',
};

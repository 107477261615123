import { FormItem } from './form-item';
import { IInputItem } from '../types';

import { FormItemWrapper, InputTitle } from '../contact-us-page.styled';

export const RenderFormItems = ({
    renderItems,
}: {
    renderItems: IInputItem[];
}) => {
    return (
        <>
            {renderItems.map(
                ({ inputName, title, pattern, message, required }, i) => {
                    return (
                        <FormItemWrapper key={i + inputName}>
                            <InputTitle>{title}</InputTitle>
                            <FormItem
                                pattern={pattern}
                                inputName={inputName}
                                message={message}
                                required={required}
                            />
                        </FormItemWrapper>
                    );
                }
            )}
        </>
    );
};

import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

import HeaderComponent from 'components/header/header.component';
import { InfoNftExtended } from './components/info-nft-extended';
import { AppDispatch, RootState } from 'store';
import { getAllCollectionItemsWF } from 'store/slices/collectionItemSlice';
import { InfoNft } from './components/info-nft';
import FooterPreLogin from 'components/footer-pre-login';
import { isAuth } from 'common/helpers';
import { ImageBlock } from './components/image-block';

import * as CommonStyles from 'common/common.styled';
import * as Styles from './product-page.styled';

const ProductPage = () => {
    const { id } = useParams<{ id: string }>();
    const allCollections = useSelector(
        (state: RootState) => state.collectionItems.allCollections
    );

    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        if (!!allCollections) {
            dispatch(getAllCollectionItemsWF());
        }
    }, []);

    if (!getAllCollectionItemsWF) {
        return <Spin size="large" />;
    }
    const product = allCollections.find(({ _id }) => id === _id);

    if (!product) {
        return <Spin size="large" />;
    }

    return (
        <CommonStyles.PageLayoutWrapper>
            <HeaderComponent />
            <Styles.Wrapper>
                <Styles.ContentBox>
                    <ImageBlock
                        image={product.image}
                        contentType={product.contentType}
                    />
                </Styles.ContentBox>
                <InfoNft product={product} />
                <InfoNftExtended product={product} />
            </Styles.Wrapper>
            {!isAuth() && <FooterPreLogin isSignIn />}
        </CommonStyles.PageLayoutWrapper>
    );
};

export default ProductPage;

import { IPropsCollectionItem } from 'containers/account-page/types';
import Like from 'assets/WhiteLike.svg';
import BlueLike from 'assets/BlueHeart.svg';
import View from 'assets/WhiteView.svg';
import { useHandleLike } from 'hooks/useHandleLike';
import { isAuth } from 'common/helpers';

import {
    InfoBlock,
    CardContentBox,
    InfoBlockItem,
    LikeImg,
    ViewImg,
    NameCollectionItem,
    UserNameCollectionItem,
    ActionBlock,
    ActionButton,
} from './collectionItem.styled';

export const CardContent = ({
    userName,
    name,
    likes,
    views,
    id,
    ownerId,
}: IPropsCollectionItem) => {
    const { isLiked, handleLikeClick } = useHandleLike(
        id,
        ownerId,
        likes,
        views
    );
    const likeImage = isLiked ? BlueLike : Like;
    return (
        <CardContentBox>
            <NameCollectionItem to={`/product/${id}`}>
                {name}
            </NameCollectionItem>
            <InfoBlock>
                <UserNameCollectionItem>{userName}</UserNameCollectionItem>
                <ActionBlock>
                    <ActionButton
                        disabled={!isAuth()}
                        onClick={(event) => {
                            event.stopPropagation();
                            handleLikeClick();
                        }}
                    >
                        <InfoBlockItem isLiked={!!isLiked}>
                            <LikeImg src={likeImage} alt="likeIcon" />
                            <span>{likes}</span>
                        </InfoBlockItem>
                    </ActionButton>

                    <InfoBlockItem>
                        <ViewImg src={View} alt="viewIcon" />
                        <span>{views}</span>
                    </InfoBlockItem>
                </ActionBlock>
            </InfoBlock>
        </CardContentBox>
    );
};

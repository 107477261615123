import { DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Form } from 'antd';

import { IPropsRenderUploadComponent } from '../types';
import { acceptExtension } from '../constants';
import { normFile } from '../helper';

import * as Styles from '../create-page.styled';

export const RenderUploadComponent = ({
    fileList,
    setFileList,
    setFieldsValue,
    isErrorUpload,
    setIsErrorUpload,
}: IPropsRenderUploadComponent) => {
    const [source, setSource] = useState<any>(null);

    return (
        <>
            <Styles.UploadTitle>Upload File</Styles.UploadTitle>
            {fileList.length &&
            source &&
            fileList[0].type.split('/')[0].toLocaleLowerCase() === 'video' ? (
                <Styles.PreViewBorder>
                    <Styles.PreViewVideo
                        loop
                        muted
                        playsInline
                        controls={false}
                        autoPlay
                        src={source}
                    ></Styles.PreViewVideo>

                    <Styles.ViewHover>
                        <button
                            onClick={() => {
                                setSource(null);
                                setFileList([]);
                                setFieldsValue({ image: undefined });
                            }}
                        >
                            <DeleteOutlined color="white" />
                        </button>
                    </Styles.ViewHover>
                </Styles.PreViewBorder>
            ) : (
                <Form.Item>
                    <Form.Item
                        valuePropName="fileList"
                        getValueFromEvent={(e) => normFile(e, setFileList)}
                        name="image"
                    >
                        <Styles.UploadZone
                            name="image"
                            action="/upload.do"
                            multiple={false}
                            beforeUpload={(e) => {
                                setSource(
                                    URL.createObjectURL(e as unknown as Blob)
                                );
                                setIsErrorUpload('');
                                const type = e.type.split('/')[1];
                                const isExist = acceptExtension.find(
                                    (el) => el === type.toLocaleLowerCase()
                                );
                                !isExist &&
                                    setIsErrorUpload(
                                        'you can upload only JPG, JPEG, PNG, GIF, SVG, MP4, WEBM files'
                                    );

                                return false;
                            }}
                            listType="picture-card"
                            accept=".jpg,.png,.gif,.svg,.mp4,.webm"
                            onRemove={() => {
                                setFileList([]);
                                setIsErrorUpload('');
                            }}
                            showUploadList={{ showPreviewIcon: false }}
                        >
                            {!fileList.length && (
                                <Styles.UploadWrapper>
                                    <Styles.UploadImageWrapper className="ant-upload-drag-icon">
                                        <Styles.UploadImage />
                                    </Styles.UploadImageWrapper>
                                    <Styles.SubTitle>
                                        JPG, PNG, GIF, SVG, MP4, WEBM. Max size:
                                        100 MB
                                    </Styles.SubTitle>
                                    <Styles.UploadButton type="button">
                                        Choose File
                                    </Styles.UploadButton>
                                </Styles.UploadWrapper>
                            )}
                        </Styles.UploadZone>
                    </Form.Item>
                </Form.Item>
            )}

            {isErrorUpload && (
                <Styles.ErrorMessage isImage={true}>
                    {isErrorUpload}
                </Styles.ErrorMessage>
            )}
        </>
    );
};

import Styled from 'styled-components';
import { theme } from 'theme';

export const PageLayoutWrapper = Styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: ${theme.colors.primary_white};
`;

export const PageLayoutContent = Styled.div`
    align-items: top;
    justify-content: center;
    display: flex;
    min-height: 90vh;
    width: 100%;
`;

import { Form, Button } from 'antd';
import { useHistory, useLocation } from 'react-router';
import { WALLET_ADAPTERS } from '@web3auth/base';
import { useWeb3Auth } from 'common/services/web3auth';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AuthLayout } from 'components/auth/AuthLayout';
import { RENDER_FORM_ITEMS } from 'components/auth/constant';
import { LOGIN_WITH_LINKS } from 'components/render-buttons/constants';
import { RenderButtons } from '../../../components/render-buttons/renderButtons';
import { FormItem } from 'components/auth/components/FormItem';
import { RenderFormItems } from 'components/auth/components/renderFormItems';
import { errorCheck } from 'components/auth/helpers';
import { LocationProps } from 'common/types';
import { getPathName } from 'common/helpers';
import { SelectCountries } from './SelectCountries';
import { RootState } from 'store';

import {
    RegistrationLayout as RegistrationBox,
    NamesBlock,
} from '../registration.styled';

export const RegistrationLayout = () => {
    const [form] = Form.useForm();
    const { login, isLoading } = useWeb3Auth();

    const isWeb3Initialized = useSelector(
        (state: RootState) => state.user.setIsWeb3Initialized
    );
    const isAuth = useSelector((state: RootState) => state.user.isAuth);
    const location = useLocation() as unknown as LocationProps;

    const history = useHistory();
    const pathName = getPathName(location);

    const onFinish = async (values: any) => {
        delete values.remember;
        if (!isLoading) {
            await login(
                WALLET_ADAPTERS.OPENLOGIN,
                'email_passwordless',
                'register',
                values.email,
                values
            );
            errorCheck(() => history.push(pathName), isAuth, form);
        }
    };

    return (
        <AuthLayout>
            <RegistrationBox>
                <div className="formBox">
                    <div>
                        <h1>Create an Account</h1>
                        <div className="greyText">
                            Already have an account?
                            <Link to="/login"> Sign in</Link>
                        </div>

                        <h2>Sign up with Social</h2>
                        <RenderButtons
                            path={pathName}
                            buttons={LOGIN_WITH_LINKS}
                        />
                        <p className="textColor">Or</p>
                        <h3 className="signUpEmail">Sign up with email</h3>
                        <Form
                            form={form}
                            name="normal_login"
                            className="login-form from"
                            onFinish={onFinish}
                        >
                            <label className="textColor">Email address</label>
                            <FormItem
                                name="email"
                                message="Please enter your valid email"
                                type="email"
                            />
                            <NamesBlock>
                                <RenderFormItems
                                    renderItems={RENDER_FORM_ITEMS}
                                />
                            </NamesBlock>

                            <SelectCountries />

                            <p>
                                The
                                <Link to="#"> CLLB family of companies </Link>
                                may keep me informed with
                                <Link to="#"> personalized</Link> emails about
                                products and services. See our
                                <Link to="#"> Privacy Policy</Link> for more
                                details or to opt-out at any time.
                            </p>
                            <p>
                                By clicking Create account, I agree that I have
                                read and accepted the
                                <Link to="#"> Terms of Use </Link>
                                and
                                <Link to="#"> Privacy Policy</Link>.
                            </p>
                            <Form.Item>
                                <div className="buttonBox">
                                    <Button
                                        disabled={!isWeb3Initialized}
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        size="large"
                                    >
                                        Create Account
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </RegistrationBox>
        </AuthLayout>
    );
};

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { FormUpdateProfile } from './form-update-profile';

import Person from 'assets/Person.svg';
import * as Styles from '../update-profile-page.styled';

export const UpdateProfileContent = () => {
    const user = useSelector((state: RootState) => state.user.user);

    return (
        <Styles.Profile>
            <Styles.Title>Basic Information</Styles.Title>
            <Styles.SubTitle>
                You can set preferred display name, create your branded profile
                URL and manage other personal settings
            </Styles.SubTitle>
            <Styles.Avatar>
                <Styles.ProfileAvatarImage
                    imgURL={user?.avatar}
                    src={(user?.avatar as string) || Person}
                ></Styles.ProfileAvatarImage>
            </Styles.Avatar>
            <Styles.AvatarSubtitle>
                We recommend an image of at least 300x300. Gifts work too. Max
                5mb
            </Styles.AvatarSubtitle>
            <FormUpdateProfile user={user} />
        </Styles.Profile>
    );
};

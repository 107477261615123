import { Link, NavLink } from 'react-router-dom';
import { Drawer, Space } from 'antd';
import styled from 'styled-components';

import { theme } from 'theme';

import {
    MenuItem,
    MenuIconWrapper,
} from '../user-dropdown-menu/user-dropdown-menu.styled';

export const Container = styled.div`
    padding: ${theme.spaces.md};
`;

export const MobileHeaderButton = styled(NavLink)`
    margin-bottom: 20px;
    color: ${theme.colors.dark_grey};
    font-weight: ${theme.fonts.weights.medium};
    font-size: 18px;
`;

export const SpaceBox = styled(Space)`
    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px;
    @media screen and (max-width: 470px) {
        margin-right: 5px;
        margin-left: 5px;
    }
`;

export const ButtonsContainer = styled.div`
    margin-top: ${theme.spaces.xs};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
export const MobileButtonsContainer = styled.div`
    margin: ${theme.spaces.xl} 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const MobileDrawer = styled(Drawer)<{ isOpen?: boolean }>`
    .ant-drawer-content {
        background: rgba(249, 249, 249, 1) !important;
    }
    .ant-drawer-header {
        background: ${({ isOpen }) =>
            isOpen ? 'white !important' : 'rgba(249, 249, 249, 1) !important'};
    }
    .ant-drawer-header-title {
        justify-content: end !important ;
    }
    .ant-drawer-body {
        padding: 0 !important;
    }
    .anticon-close {
        color: black !important;
    }
    .ant-drawer-close {
        margin: 0 !important;
    }
`;

export const AuthBtnsContainer = styled.div<{
    isPerson?: boolean;
    isOpen?: Boolean;
}>`
    background: ${({ isOpen }) => isOpen && 'white! important'};
    display: flex;
    border-bottom: 1px solid #e8e6ee;
    flex-direction: ${({ isPerson }) => (isPerson ? 'column' : 'row')};
`;

export const AuthContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: ${theme.spaces.xl} 0 ${theme.spaces.lg};
    padding: 0 ${theme.spaces.xxxl} 0 ${theme.spaces.md};
    width: 85%;
`;

export const UserContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spaces.xs};
`;

export const Username = styled.h1`
    font-weight: ${theme.fonts.weights.medium};
    font-size: 18px;
    line-height: 27px;
    color: rgba(51, 53, 96, 1);
    padding: 0 ${theme.spaces.sm};
    word-break: break-word;
    max-width: 207px;
`;

export const UserAction = styled(Link)`
    display: flex;
    padding-bottom: ${theme.spaces.xs};
    align-items: center;
`;

export const MobileIcon = styled(MenuIconWrapper)`
    padding-right: ${theme.spaces.xs};
`;

export const MobileMenuItem = styled(MenuItem)`
    font-size: 16px;
`;

export const AvatarContainer = styled.div`
    width: 50px;
    height: 50px;
`;

export const AvatarBurger = styled.img`
    border-radius: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

export const MenuContainer = styled.div`
    margin-left: 65px;
`;

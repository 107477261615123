import styled from 'styled-components';
import { Upload, Input, Button, Form, Radio } from 'antd';

import { theme } from 'theme';

const { Dragger } = Upload;
const { TextArea } = Input;

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${theme.spaces.xl};
`;

export const FormWrapper = styled(Form)`
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    width: 80%;
    margin: 5vh auto 0;
    background: ${theme.colors.light_red};
    border-radius: ${theme.spaces.md};
    padding: 2.1% 2.1% 3% 2.1%;

    @media (max-width: 800px) {
        width: 90%;
    }

    @media (max-width: 500px) {
        margin: ${theme.spaces.xl} auto 0;
    }
`;

export const MainTitle = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.xxxl};
    margin-bottom: ${theme.spaces.xl};
    color: ${theme.colors.dark_grey};

    @media (max-width: 500px) {
        font-size: 22px;
    }
`;

export const InputTitle = styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xs};
    color: ${theme.colors.label_grey};
    line-height: ${theme.fonts.sizes.md};

    @media (max-width: 500px) {
        font-size: 14px;
        padding-bottom: ${theme.spaces.xxs};
    }
`;

export const Title = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xs};
    color: ${theme.colors.label_grey};

    @media (max-width: 500px) {
        font-size: 14px;
        padding-bottom: ${theme.spaces.xxs};
    }
`;

export const DescriptionSubtitle = styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xs};
    color: ${theme.colors.label_grey};

    @media (max-width: 500px) {
        font-size: 14px;
    }
`;

export const InputField = styled(Input)`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    background: ${theme.colors.light_red};
    border: 1px solid ${theme.colors.label_grey} 20%;
    border-radius: ${theme.spaces.xs};
    height: 54px;
    margin-top: ${theme.spaces.xs};

    @media (max-width: 500px) {
        margin-top: 0;
    }
`;

export const DraggerField = styled(Dragger)`
    border-radius: ${theme.spaces.xs} !important;
    text-align: start !important;

    @media (max-width: 500px) {
        border: 1px solid rgba(51, 53, 96, 0.2) !important;
    }

    .ant-upload-text {
        font-family: ${theme.fonts.families.poppins};
        font-style: normal;
        font-weight: ${theme.fonts.weights.regular};
        font-size: ${theme.fonts.sizes.md};
        line-height: ${theme.spaces.lg};
        color: ${theme.colors.label_grey} !important;
        margin-left: ${theme.spaces.xl} !important;
        span {
            color: ${theme.colors.light_purple};
        }
        @media (max-width: 500px) {
            font-size: 14px;
        }
    }
`;

export const UploadContainer = styled.div`
    margin-top: ${theme.spaces.xxxl};
`;

export const RadioButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: ${theme.spaces.x_50};

    .ant-radio-group {
        display: flex !important;
        flex-wrap: wrap;
    }

    .ant-radio-wrapper {
        @media (max-width: 800px) {
            width: 100%;
        }
    }

    @media (max-width: 500px) {
        margin-bottom: 0;
    }
`;

export const RadioButtonItem = styled(Radio)`
    .ant-radio-inner {
        width: 14px;
        height: 14px;
        background: ${theme.colors.primary_white};
        border: 1.5px solid ${theme.colors.label_grey};
        margin-right: ${theme.spaces.xxs};
    }
`;

export const RadioButtonText = styled.p`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: ${theme.fonts.weights.medium};
    font-size: 18px;
    margin-right: 30px;
    color: ${theme.colors.dark_grey};
    opacity: 0.8;

    @media (max-width: 500px) {
        font-size: 16px;
        padding-bottom: ${theme.spaces.md};
    }
`;

export const TextAreaField = styled(TextArea)`
    background: ${theme.colors.light_red};
    border: 1px solid ${theme.colors.label_grey} 20%;
    border-radius: ${theme.spaces.xs};
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    margin-top: ${theme.spaces.xs};
    height: 28vh !important;

    @media (max-width: 500px) {
        height: 200px !important;
        margin-top: 0;
    }
`;

export const ContactUsButton = styled(Button)`
    width: 230px;
    height: 46px;
    background-color: ${theme.colors.light_purple};
    border-radius: ${theme.spaces.xxxl};
    color: ${theme.colors.primary_white};
    font-weight: ${theme.fonts.weights.medium};
    font-size: 100%;
    font-family: ${theme.fonts.families.poppins};
    margin-top: ${theme.spaces.xl};

    @media (max-width: 500px) {
        max-width: 112px;
        margin: 0 0 ${theme.spaces.xxl};
        font-size: 16px;
    }
`;

export const FormItemWrapper = styled.div``;

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Spin } from 'antd';
import { ToastContainer } from 'react-toastify';

import PageLayout from 'components/page-skeleton';
import { RenderInputFormItems } from './components/form-input-items/render-input-form-items';
import { FormInputItems, rules } from './constants';
import { AppDispatch, RootState } from 'store';
import {
    getAllCollections,
    setCollections,
} from 'store/slices/collectionSlice';
import { Title, FormItemWrapper } from './create-page.styled';
import {
    createCollectionItem,
    setCurrentCreatedType,
} from 'store/slices/collectionItemSlice';
import { ICollection, ICreateCollectionItem, IUser } from 'common/types';
import { FormSelectItem } from './components/form-select-items/form-select-items';
import { RenderUploadComponent } from './components/render-upload.component';
import { CreatePageItemTypes } from './types';
import { fetchCreateItem, fetchData } from 'common/services/apiHelpers';
import { ROUTES_KEYS } from 'common/constants';
import { getUser, updateUser } from 'store/slices/userSlice';
import { notify } from './helper';
import { SelectTags } from './components/select-tags';

import 'react-toastify/dist/ReactToastify.css';
import * as Styles from './create-page.styled';

const CreatePage = () => {
    const [newItemType, setNewItemType] = useState<CreatePageItemTypes>(
        CreatePageItemTypes.Nft
    );
    const [createCollabCollection, setCreateCollabCollection] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [newOption, setNewOption] = useState<string[]>([]);
    const [fileList, setFileList] = useState<any[]>([]);
    const [isFetchLoading, setIsFetchLoading] = useState(false);
    const [descriptionLength, setDescriptionLength] = useState(0);

    const history = useHistory();
    const [form] = Form.useForm();
    const [isError, setIsError] = useState('');
    const [isErrorUpload, setIsErrorUpload] = useState('');

    const dispatch: AppDispatch = useDispatch();

    const collections = useSelector(
        (state: RootState) => state.collections.collections
    );

    const user: IUser | null = useSelector(
        (state: RootState) => state.user.user
    );

    const isCreateNft = useMemo(
        () => newItemType === CreatePageItemTypes.Nft,
        [newItemType]
    );

    const onCreateNftClick = () => setNewItemType(CreatePageItemTypes.Nft);
    const onCreateCollabClick = () =>
        setNewItemType(CreatePageItemTypes.Collab);

    const fetchCreateCollabCollections = async () => {
        const collections = await fetchData(`${ROUTES_KEYS.COLLECTION}/collab`);
        if (collections && collections.data) {
            setCreateCollabCollection(collections.data.collections);
        }
    };

    useEffect(() => {
        dispatch(getUser());
        dispatch(getAllCollections());
        fetchCreateCollabCollections();
    }, []);

    useEffect(() => {
        if (user) {
            setIsLoading(true);
            dispatch(
                setCollections({
                    name: `${user?.firstName} ${user?.lastName}'s Collection`,
                })
            );
            setIsLoading(false);
        }
    }, [user?.firstName, collections.length]);

    const currentCollections: ICollection[] = useMemo(
        () => (isCreateNft ? collections : createCollabCollection),
        [collections, createCollabCollection, isCreateNft]
    );

    if (isLoading || !user) {
        return <Spin size="large" />;
    }

    const onFinish = async (values: any) => {
        setIsFetchLoading(true);
        if (!fileList.length) {
            setIsErrorUpload(
                'JPG, JPEG, PNG, GIF, SVG, MP4 or WEBM file must be uploaded'
            );
            setIsFetchLoading(false);
        } else {
            setIsErrorUpload('');
            let collection = currentCollections?.find(
                ({ name }) => name === values.collectionName
            );
            if (!collection) {
                const newCollection = await fetchCreateItem(
                    { name: values.collectionName.trim() },
                    ROUTES_KEYS.COLLECTION
                );
                collection = newCollection && newCollection.data;
            }

            values.image = fileList[0].originFileObj;
            if (!values.externalLink) {
                delete values.externalLink;
            }
            if (!values.tags) {
                delete values.tags;
            }

            if (values.tags && newOption) {
                dispatch(
                    updateUser({
                        tags: `${
                            user.tags ? `${user.tags}, ` : ''
                        }${newOption.join(', ')}`.trim(),
                    })
                );
            }

            values.collectionId = collection!._id;
            values.type = newItemType;
            const formData = new FormData();
            for (const name in values) {
                formData.append(name, values[name]);
            }
            dispatch(
                createCollectionItem(
                    formData as unknown as ICreateCollectionItem
                )
            ).then(() => {
                setFileList([]);
                const type = newItemType === 'nft' ? 'nft' : 'collabs';
                dispatch(setCurrentCreatedType(type));
                form.resetFields();
                notify(newItemType);
                setIsFetchLoading(false);
                setTimeout(() => history.push('/account'), 2500);
            });
        }
    };

    if (isLoading) {
        return <Spin size="large" />;
    }

    return (
        <PageLayout>
            <Styles.PageWrapper>
                <ToastContainer pauseOnFocusLoss={false} pauseOnHover={false} />
                <Styles.FormWrapper
                    form={form}
                    name="create_form"
                    onFinish={onFinish}
                >
                    <Styles.MainTitle>Create New Item</Styles.MainTitle>
                    <Styles.SpaceLine />
                    <Styles.ButtonWrapper>
                        <Styles.ButtonType
                            onClick={onCreateNftClick}
                            type={isCreateNft ? 'primary' : 'default'}
                        >
                            Create NFT
                        </Styles.ButtonType>
                        <Styles.ButtonType
                            onClick={onCreateCollabClick}
                            type={!isCreateNft ? 'primary' : 'default'}
                        >
                            Create a Collab
                        </Styles.ButtonType>
                    </Styles.ButtonWrapper>
                    <Styles.MainSubTitle>Required fields</Styles.MainSubTitle>
                    <RenderUploadComponent
                        setFieldsValue={form.setFieldsValue}
                        fileList={fileList}
                        setFileList={setFileList}
                        isErrorUpload={isErrorUpload}
                        setIsErrorUpload={setIsErrorUpload}
                    />

                    <RenderInputFormItems renderItems={FormInputItems} />
                    <Styles.Title>Description</Styles.Title>

                    <Styles.DescriptionContainer>
                        <Styles.DescriptionCounter
                            isFull={descriptionLength === 500}
                        >
                            Characters {descriptionLength}/500
                        </Styles.DescriptionCounter>
                        <Form.Item name="description" rules={rules}>
                            <Styles.TextAreaField
                                placeholder="Write Description of your item"
                                maxLength={500}
                                onChange={({ target }) =>
                                    setDescriptionLength(target.value.length)
                                }
                            />
                        </Form.Item>
                    </Styles.DescriptionContainer>

                    <SelectTags
                        tags={user.tags}
                        setNewOption={setNewOption}
                        newOption={newOption}
                    />

                    <FormItemWrapper>
                        <Title>Collection</Title>
                        <FormSelectItem
                            name="collectionName"
                            message="Please fill in the field"
                            placeholder="Select Collection"
                            optionsArray={currentCollections}
                            isCreateNft={isCreateNft}
                            setIsError={setIsError}
                            isError={isError}
                        />
                    </FormItemWrapper>
                    <Styles.CreateButton
                        htmlType="submit"
                        disabled={isFetchLoading || !!isError}
                    >
                        Create {isCreateNft ? 'NFT' : 'Collab'}
                    </Styles.CreateButton>
                </Styles.FormWrapper>
            </Styles.PageWrapper>
        </PageLayout>
    );
};

export default CreatePage;

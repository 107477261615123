import PageLayout from 'components/page-skeleton/page-skeleton.component';
import { Banner } from './components/banner';
import { RenderIcons } from './components/renderIcons';
import FooterPreLogin from 'components/footer-pre-login';
import AboutUsBodyImage from '../../assets/HomeNft.png';
import AboutUsSecondItemBg from '../../assets/HomeSecondItemBg.png';
import { isAuth } from 'common/helpers';

import * as Styles from './about-us-page.styled';

const AboutUsPage = () => {
    return (
        <PageLayout isBecomeACreator={true}>
            <Styles.AboutUsWrapper>
                <Banner />
                <Styles.AboutUsBody>
                    <Styles.ContentWrapper isReverse>
                        <Styles.BodyInfoWrapper>
                            <Styles.AboutUsBodyTitle>
                                Affirm unleashes your business potential by the
                                constant innovation.
                            </Styles.AboutUsBodyTitle>
                            <Styles.SubTitle>Company Benefits</Styles.SubTitle>
                            <Styles.BodyDescription>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500
                            </Styles.BodyDescription>
                        </Styles.BodyInfoWrapper>
                        <Styles.LeftImageBlock>
                            <Styles.AboutUsImage
                                src={AboutUsBodyImage}
                                alt="NFT"
                            />
                        </Styles.LeftImageBlock>
                    </Styles.ContentWrapper>
                    <Styles.AboutUsBodyItem>
                        <Styles.ContentWrapper>
                            <Styles.BodyInfoWrapper>
                                <Styles.AboutUsBodyTitle>
                                    We generated 2x more online sales in 2021.
                                </Styles.AboutUsBodyTitle>
                                <Styles.BodyDescription>
                                    Through strategy, design, and planning we
                                    build brand identities that connect with
                                    your template. We then fine-tune a marketing
                                    plan that allows us to laser focus.
                                </Styles.BodyDescription>
                            </Styles.BodyInfoWrapper>
                            <Styles.RightImageBlock>
                                <Styles.AboutUsImage
                                    src={AboutUsSecondItemBg}
                                    alt="NFT"
                                />
                            </Styles.RightImageBlock>
                        </Styles.ContentWrapper>
                    </Styles.AboutUsBodyItem>
                    <Styles.GetToKnowWrapper>
                        <Styles.FooterTitle>Get to know us</Styles.FooterTitle>
                        <Styles.GetToKnowDescription>
                            Through strategy, design, and planning we build
                            brand identities that connect with your template. We
                            then fine-tune a marketing plan that allows us to
                            laser focus.
                        </Styles.GetToKnowDescription>
                        <RenderIcons />
                    </Styles.GetToKnowWrapper>
                    <Styles.SolutionsWrapper>
                        <Styles.SolutionInfoWrapper>
                            <Styles.FooterTitle>
                                Digital solutions for your online business.
                            </Styles.FooterTitle>
                            <Styles.BodyDescription>
                                Hub IT allows your business and technology
                                computers to store, transmit, analyze, and
                                manipulate big data.
                            </Styles.BodyDescription>
                            <Styles.LearnMore>Learn More</Styles.LearnMore>
                        </Styles.SolutionInfoWrapper>
                    </Styles.SolutionsWrapper>
                </Styles.AboutUsBody>
                {!isAuth() && (
                    <Styles.FooterWrapper>
                        <FooterPreLogin />
                    </Styles.FooterWrapper>
                )}
            </Styles.AboutUsWrapper>
        </PageLayout>
    );
};

export default AboutUsPage;

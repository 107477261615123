import { ICollection } from 'common/types';
import { Dispatch } from 'react';

export const validateNewCollection = (
    newValue: string,
    setIsError: Dispatch<string>,
    allCollections: ICollection[],
    isCreateNft: boolean
) => {
    const validationInput = /^[0-9a-zA-Z-_—/' ()]+$/;
    const isExist = allCollections.find(({ name }) => name === newValue);

    if (isCreateNft) {
        if (isExist) {
            setIsError('this collection already exists');
        } else if (!newValue.match(validationInput)) {
            setIsError('some special symbols are not allowed');
        } else if (newValue.length > 40) {
            setIsError('Collection name cannot be longer than 40 characters');
        } else {
            setIsError('');
        }
    }
};

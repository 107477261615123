import Styled from 'styled-components';

import { theme } from 'theme';

import HomeBannerImage from '../../assets/HomeBannerImage.jpg';

export const Banner = Styled.div`
    width: 100%;
    background-image: url(${HomeBannerImage});
    background-size: cover;
    height: 500px;
    z-index: 1;
    @media (max-width: 880px) {
        height: calc(100vh - 60px);   
    }
`;

export const ShadowBox = Styled.div`
    width:100%;
    z-index: 2;
    background: ${({ theme }) => theme.colors.grey};
    height: 100%;
    align-items: center;
    position: relative;
    padding: ${theme.spaces.xxxl} ${theme.spaces.x_50}; 0 ${theme.spaces.xl};
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    @media (max-width: 880px) {
        flex-direction: column;
        padding: 0;
    }
`;

export const Title = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.xxxl};
    color: ${theme.colors.primary_white};
    @media (max-width: 880px) {
        padding: ${theme.spaces.x_50} ${theme.spaces.xxs}  ${theme.spaces.xs}  ${theme.spaces.md};
    }

`;

export const Description = Styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xs};
    margin-top: ${theme.spaces.xl};
    color: rgba(255, 255, 255, 0.8);
    max-width: 600px;

    @media (max-width: 880px) {
        margin: 0;
        padding: 0 ${theme.spaces.xxxl} 0 ${theme.spaces.md};
    }
`;

export const InfoWrapper = Styled.div`
    width: 50%;
    min-width: 300px;
    @media (max-width: 880px) {
        width: 100%;    
    }
`;

export const BannerImages = Styled.img`
    margin-top: ${theme.spaces.xs};
    object-fit: fill;
`;

export const LeftImageBlock = Styled.div`
    margin-right: ${theme.spaces.x_50};
    @media (max-width:880px) {
        margin: 0 0 ${theme.spaces.x_50};
    }
`;
export const RightImageBlock = Styled.div`
    margin-left: ${theme.spaces.x_50};
    @media (max-width: 880px) {
        margin: 0px;
    }
`;
export const AboutUsBody = Styled.div`
    padding: ${theme.spaces.x_50} ${theme.spaces.x_110};
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 1050px) {
        padding: ${theme.spaces.x_80} ${theme.spaces.sm} ${theme.spaces.x_110};
    }
    @media (max-width: 880px) {
        gap: ${theme.spaces.x_110};
    }
`;

export const AboutUsBodyItem = Styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: ${theme.spaces.x_110};
    @media (max-width: 880px) {
        margin-top: ${theme.spaces.xs};    
    }
`;

export const BodyDescription = Styled.p`
    font-family: ${theme.fonts.families.poppins};
    font-style: normal;
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xs};
    color: ${theme.colors.label_grey};
    opacity: 0.6;
    margin: ${theme.spaces.xl} 0;
    @media (max-width: 880px) {
        font-size: ${theme.fonts.sizes.xxs}; 
    }
`;

export const AboutUsImage = Styled.img`
`;

export const AboutUsImagesBlock = Styled.div`
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 880px) {
        width: 100%;
        justify-content: space-around;
    }

    img{
        border-radius: 100%;
    }
`;

export const AboutUsImageContainer = Styled.div`
   width: 120px;
   height: 120px;
   margin: ${theme.spaces.xs};

   @media (max-width: 1050px) {
        width: 100px;
        height: 100px;
    }

    @media (max-width: 700px) {
        width: 90px;
        height: 90px;
    }

    @media (max-width: 600px) {
        width: 50px;
        height: 50px;
    }
`;

export const AboutUsBodyTitle = Styled.div`
    width: 28vw;
    color: ${theme.colors.dark_grey};
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.xxxl};
    
    @media (max-width: 1050px) {
        font-size: ${theme.fonts.sizes.lg};
        width: 100%;
    }
`;

export const BodyInfoWrapper = Styled.div`
    width: 47%;
    @media (max-width: 880px) {
        width: 100%;
    }
`;

export const ContentWrapper = Styled.div<{ isReverse?: boolean }>`
    display: flex;
    flex-direction: ${({ isReverse }) => (isReverse ? 'row-reverse' : 'row')};
    width: 100%;  
    justify-content: center;
    align-items: center;
    @media (max-width: 880px) {
        flex-direction: column};
    }

`;
export const SubTitle = Styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.xs};
    color: ${theme.colors.dark_grey};
    margin-top: ${theme.spaces.xxxl};
`;

export const AboutUsWrapper = Styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const GetToKnowWrapper = Styled.div`
    width: 100%;
    margin-top: ${theme.spaces.x_50};
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 880px) {
        margin-top: ${theme.spaces.xl};
    }
`;

export const FooterTitle = Styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.semiBold};
    font-size: ${theme.fonts.sizes.xxxl};
    color: ${theme.colors.dark_grey};
    @media (max-width: 880px) {
        font-size: ${theme.fonts.sizes.lg}
    }
`;

export const GetToKnowDescription = Styled.div`
    text-align: center;
    max-width: 790px;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xs};
    color: rgba(51, 53, 96, 0.6);
    margin: ${theme.spaces.xl} 0;
`;

export const SolutionsWrapper = Styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spaces.x_110};
    @media (max-width: 880px) {
        margin-top: 70px;
    }
`;

export const SolutionInfoWrapper = Styled.div`
    max-width: 505px;
    @media (max-width: 880px) {
        max-width: 100%;
    }
`;

export const LearnMore = Styled.div`
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xs};
    color: #bcb9b9;
`;

export const FooterWrapper = Styled.div`
    width: 55%;
    margin: 0 auto;
    
    @media (max-width: 880px) {
        width: 100%;
        padding: 0 ${theme.spaces.sm}  ${theme.spaces.x_100};
    }
`;

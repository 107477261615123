import { Select } from 'antd';

import { IOptionsProps } from './types';

import { StyledSelect, ArrowIcon } from '../nfts-page.styled';
import { useEffect, useState } from 'react';

export const RenderSelect = ({
    options,
    setState,
    defaultValue,
    icon,
    setIsFilterOpened,
}: IOptionsProps) => {
    const { Option } = Select;
    const [isVisible, setIsVisible] = useState(false);

    const onChange = (value: unknown) => {
        setState(value as string);
    };

    const listenToScroll = () => {
        let heightToHideFrom = 200;
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => window.removeEventListener('scroll', listenToScroll);
    }, []);

    return (
        <StyledSelect
            defaultValue={defaultValue || options[0].value}
            suffixIcon={icon ? icon : <ArrowIcon />}
            onClick={() => {
                setIsVisible(!isVisible);
                setIsFilterOpened(!isVisible);
            }}
            onBlur={() => {
                setIsVisible(false);
                setIsFilterOpened(false);
            }}
            onChange={onChange}
            open={isVisible}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{
                padding: '0',
            }}
        >
            {options.map(({ value, query }) => (
                <Option key={value} value={query}>
                    {value}
                </Option>
            ))}
        </StyledSelect>
    );
};

import { IFontSizes } from 'styled-components';

export const fontSizes: IFontSizes = {
    xxxxxl: '3em',
    xxxxl: '2.6em',
    xxxl: '2.4em',
    xxl: '2.2em',
    xl: '2em',
    lg: '1.8em',
    md: '1.6em',
    sm: '1.4em',
    xs: '1.2em',
    xxs: '1em',
    xxxs: '0.8em',
    xxxxs: '0.5em',
};

import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import { ToastContainer } from 'react-toastify';

import { AppDispatch } from 'store';
import { RenderFormItems } from './render-form-items';
import { updateUser } from 'store/slices/userSlice';
import { normFile } from 'common/helpers';
import { IUpdateUser } from 'common/types';
import { INPUT_ITEMS } from '../constants';
import { IUserProps } from '../types';
import { notify } from 'common/helpers';

import * as Styles from '../update-profile-page.styled';
import 'react-toastify/dist/ReactToastify.css';

export const FormUpdateProfile = ({ user }: IUserProps) => {
    const [form] = Form.useForm();
    const dispatch: AppDispatch = useDispatch();

    const defaultValues = useMemo(
        () => ({
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            customURL: user?.customURL || '',
            bio: user?.bio || '',
            company: user?.company || '',
            twitterLink: user?.twitterLink || '',
            webSiteURL: user?.webSiteURL || '',
            email: user?.email || '',
            about: user?.about || '',
            portfolioURL: user?.portfolioURL || '',
        }),
        [user]
    );

    useEffect(() => {
        form.setFieldsValue(defaultValues);
    }, [form, defaultValues]);

    const onFinish = async (values: any) => {
        values.image = values.image && values.image[0].originFileObj;

        const formData = new FormData();
        for (const name in values) {
            formData.append(name, values[name]);
        }

        const updatedUser = await dispatch(
            updateUser(formData as unknown as IUpdateUser)
        );
        if (updatedUser.payload) {
            notify('success', 'Profile has been updated successfully');
            form.resetFields();
        } else {
            notify('error', 'Something went wrong, try again');
        }
    };

    return (
        <Styles.FormWrapper
            form={form}
            name="create_form"
            onFinish={onFinish}
            initialValues={defaultValues}
        >
            <ToastContainer pauseOnFocusLoss={false} pauseOnHover={false} />

            <Form.Item
                valuePropName="fileList"
                getValueFromEvent={normFile}
                name="image"
            >
                <Styles.UploadZone
                    name="image"
                    multiple={false}
                    listType="picture"
                    beforeUpload={() => false}
                    action="/upload.do"
                >
                    <Styles.PurpleButton htmlType="button">
                        Choose file
                    </Styles.PurpleButton>
                </Styles.UploadZone>
            </Form.Item>
            <RenderFormItems user={user} renderItems={INPUT_ITEMS} />
            <Styles.UpdateButton htmlType="submit">
                Update Profile
            </Styles.UpdateButton>
        </Styles.FormWrapper>
    );
};

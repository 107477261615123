import { FormItem } from './form-item';
import { Spin } from 'antd';

import { InputTitle, FormItemWrapper } from '../../settings-page.styled';
import { IInputItem } from '../../types';
import { IUser } from 'common/types';

export const RenderFormItems = ({
    renderItems,
    user,
}: {
    renderItems: IInputItem[];
    user: IUser | null;
}) => {
    if (!user) {
        return <Spin size="large" />;
    }
    return (
        <>
            {renderItems.map(
                (
                    {
                        inputName,
                        title,
                        placeholder,
                        pattern,
                        message,
                        validation,
                    },
                    i
                ) => {
                    let defaultValue;
                    if (inputName === 'accountName') {
                        defaultValue = user[inputName]
                            ? user[inputName]
                            : `${user.firstName || ''} ${user.lastName || ''}`;
                    } else {
                        defaultValue = user[inputName]
                            ? user[inputName]
                            : `${user.email}`;
                    }

                    return (
                        <FormItemWrapper key={i}>
                            <InputTitle>{title}</InputTitle>
                            <FormItem
                                pattern={pattern}
                                inputName={inputName}
                                placeholder={placeholder}
                                message={message}
                                defaultValue={defaultValue}
                                validation={validation}
                            />
                        </FormItemWrapper>
                    );
                }
            )}
        </>
    );
};

import { IButtonAccount, ISocialIcons, IUserInfo } from './types';
import FacebookAccount from 'assets/FacebookAccount.svg';
import TwitterAccount from 'assets/TwitterAccount.svg';
import UtubeAccount from 'assets/UtubeAccount.svg';
import InstagramAccount from 'assets/InstagramAccount.svg';

export const USER_INFO: IUserInfo[] = [
    { name: 'Project Views', type: 'projectVies' },
    { name: 'Appreciations', type: 'apreciations' },
    { name: 'Followers', type: 'followers' },
    { name: 'Following', type: 'following' },
];

export const BUTTONS_ACCOUNT: IButtonAccount[] = [
    { name: 'Offers', type: null },
    { name: 'Transactions', type: null },
    { name: 'Collections', type: 'nft' },
    { name: 'Collabs', type: 'collabs' },
];

export const SOCIAL_ICONS: ISocialIcons[] = [
    { name: 'facebookLink', image: FacebookAccount },
    { name: 'twitterLink', image: TwitterAccount },
    { name: 'instLink', image: InstagramAccount },
    { name: 'utubeLink', image: UtubeAccount },
];

export const LINKS_SOCIAL = {
    facebookLink: 'https://www.facebook.com/',
    twitterLink: 'https://twitter.com/',
    instLink: 'https://www.instagram.com/',
    utubeLink: 'https://www.youtube.com/channel/',
};

import { Form } from 'antd';

import { IInputItem } from '../../types';
import { InputField } from '../../settings-page.styled';

export const FormItem = ({
    inputName,
    placeholder,
    pattern,
    message,
    defaultValue,
    validation,
}: Omit<IInputItem, 'title'>) => {
    return (
        <>
            <Form.Item
                name={inputName}
                initialValue={defaultValue}
                rules={[
                    {
                        pattern: pattern,
                        message: message,
                    },
                    { ...validation },
                ]}
            >
                <InputField placeholder={placeholder} className="formInput" />
            </Form.Item>
        </>
    );
};

import { useHistory } from 'react-router';

import { RenderButtons } from 'components/render-buttons/renderButtons';
import { LOGIN_WITH_LINKS } from 'components/render-buttons/constants';
import { isAuth } from 'common/helpers';
import { ROUTER_KEYS } from 'common/data/data';
import { IFooterPreLogin } from './types';

import * as Styles from './footer-pre-login.styled';

const FooterPreLogin = ({ isSignIn = false }: IFooterPreLogin) => {
    const newBtnOrder = [...LOGIN_WITH_LINKS].reverse();
    const history = useHistory();
    const handleClickEmailSignUp = () => history.push(ROUTER_KEYS.REGISTER);
    const handleClickSignIn = () => history.push(ROUTER_KEYS.LOGIN);

    return (
        <Styles.Footer>
            {!isAuth() && (
                <>
                    <Styles.FooterTitle>
                        Sign up or Sign in to your account to view more work
                        personalized to your preferences.
                    </Styles.FooterTitle>
                    <Styles.FooterButtons>
                        <Styles.PreLoginButton
                            onClick={
                                isSignIn
                                    ? handleClickSignIn
                                    : handleClickEmailSignUp
                            }
                        >
                            {isSignIn ? 'Sign In' : 'Sign Up with Email'}
                        </Styles.PreLoginButton>
                        <Styles.Or>Or</Styles.Or>
                        <RenderButtons buttons={newBtnOrder} />
                    </Styles.FooterButtons>
                </>
            )}
        </Styles.Footer>
    );
};

export default FooterPreLogin;

import { Form } from 'antd';
import { InputField } from '../../create-page.styled';

export interface IFormItemProps {
    name: string;
    message: string;
    placeholder?: string;
    type?: 'email' | 'string';
    pattern?: RegExp;
    maxLength?: number;
    required: boolean;
}

export const FormInputItem = ({
    name,
    message,
    placeholder,
    type,
    pattern,
    maxLength,
    required,
}: IFormItemProps) => {
    return (
        <Form.Item
            name={name}
            rules={[
                {
                    required,
                    type: type,
                    pattern: pattern,
                    message,
                },
            ]}
        >
            <InputField
                placeholder={placeholder}
                className="formInput"
                maxLength={maxLength}
            />
        </Form.Item>
    );
};

import { useLocation } from 'react-router';

import { EXTENDED_BTN_HEADER } from '../../../common/constants';
import { IPropsHeaderButtons } from '../types';

import * as Styles from '../header.styled';

const RenderHeaderButtons = ({
    buttons = EXTENDED_BTN_HEADER,
    coloricons = 'black',
}: IPropsHeaderButtons) => {
    const location = useLocation();

    const { pathname } = location;
    const currentPath = '/' + pathname.split('/')[1];
    return (
        <Styles.HeaderButtons className="notDraggable">
            {buttons.map(({ name, link }) => {
                return currentPath === link ? (
                    <Styles.HeaderActiveButton
                        key={name}
                        coloricons={coloricons}
                        to={currentPath}
                        onClick={() => window.location.reload()}
                    >
                        {name}
                    </Styles.HeaderActiveButton>
                ) : (
                    <Styles.HeaderButton
                        key={name}
                        coloricons={coloricons}
                        to={link}
                        className="notDraggable"
                    >
                        {name}
                    </Styles.HeaderButton>
                );
            })}
        </Styles.HeaderButtons>
    );
};

export default RenderHeaderButtons;

import { IInputItem } from '../types';

export const SettingsItems = [
    {
        name: 'Basic Information',
        isExist: true,
    },
    {
        name: 'Team',
    },
    {
        name: 'Links',
    },
];

export const INPUT_ITEMS: IInputItem[] = [
    {
        title: 'Custom URL',
        inputName: 'customURL',
        placeholder: 'Enter your custom URL',
        maxLength: 50,
    },
    {
        title: 'Website URL',
        inputName: 'webSiteURL',
        placeholder: 'Enter your web site URL',
        pattern: new RegExp('^(https|http)://'),
        message: 'invalid link',
        maxLength: 200,
    },
    {
        title: 'Company',
        inputName: 'company',
        placeholder: 'Enter your company',
        maxLength: 40,
    },

    {
        title: 'Bio',
        inputName: 'bio',
        placeholder: 'Tell about yourself in a few words',
        maxLength: 50,
    },
    {
        title: 'About',
        inputName: 'about',
        maxLength: 100,
    },
    {
        title: 'Twitter Username',
        inputName: 'twitterLink',
        placeholder: '@ Enter your name in Twitter',
        subTitle:
            'Link your twitter account to gain more trust on the marketplace',
        maxLength: 50,
        message: 'username should start with @',
        pattern: new RegExp('^@'),
    },

    {
        title: 'Personal site on portfolio',
        inputName: 'portfolioURL',
        placeholder: 'portfolio',
        pattern: new RegExp('^(https|http)://'),
        maxLength: 200,
        message: 'invalid link',
    },
    {
        title: 'Email',
        inputName: 'email',
        placeholder: 'Enter your email',
        message: 'please fill in all the fields correctly',
        pattern: new RegExp(
            /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/
        ),
        maxLength: 50,
    },
];

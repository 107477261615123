import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { AppDispatch, RootState } from 'store';
import HeaderComponent from 'components/header/header.component';
import { FiltersBlock } from './components/filtersBlock';
import FooterPreLogin from 'components/footer-pre-login';
import { CollectionItemsBlock } from './components/collectionItemsBlock';
import { ICollectionItems } from 'common/types';
import { ShowMoreBtn } from 'components/showMoreBtn/show-more-btn';
import { useSearch } from 'hooks/useSearch';

import {
    SearchInput,
    SearchIcon,
    SearchBlock,
    MarginBottomDiv,
} from './nfts-page.styled';
import * as CommonStyles from 'common/common.styled';
import { getAllUsers, getUser } from 'store/slices/userSlice';

const NftsPage = () => {
    const user = useSelector((state: RootState) => state.user.user);
    const [inputValue, setInputValue] = useState('');
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser());
        dispatch(getAllUsers());
    }, []);

    const {
        setCreator,
        creator,
        setDate,
        setSortedBy,
        setDesigner,
        designer,
        setPortion,
        portion,
        itemsLength,
        isLoading,
    } = useSearch();

    const collectionItems: ICollectionItems[] = useSelector(
        (state: RootState) => state.collectionItems.allCollections
    );
    const filteredCollectionItems = useMemo(() => {
        const newFiltered = !!inputValue
            ? collectionItems
                  .map((collection) => {
                      if (
                          collection.name
                              .toLocaleLowerCase()
                              .includes(inputValue.toLocaleLowerCase()) ||
                          collection.collectionName
                              .toLocaleLowerCase()
                              .includes(inputValue.toLocaleLowerCase()) ||
                          collection.ownerName
                              .toLocaleLowerCase()
                              .includes(inputValue.toLocaleLowerCase())
                      ) {
                          return collection;
                      }
                  })
                  .filter((el) => el !== undefined)
            : [...collectionItems];
        return newFiltered;
    }, [collectionItems, inputValue]);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    return (
        <CommonStyles.PageLayoutWrapper>
            <HeaderComponent isBecomeACreator={true} isSearch={false} />
            <SearchBlock>
                <SearchInput
                    placeholder="Search CLLB"
                    bordered={true}
                    suffix={<SearchIcon />}
                    value={inputValue}
                    onChange={onInputChange}
                    maxLength={50}
                />
            </SearchBlock>
            {isLoading ? (
                <Spin />
            ) : (
                <>
                    <FiltersBlock
                        setCreator={setCreator}
                        setSortedBy={setSortedBy}
                        setDate={setDate}
                        setDesigner={setDesigner}
                        creator={creator}
                        designer={designer}
                        filteredCollectionItems={filteredCollectionItems}
                    />
                    <CollectionItemsBlock
                        collectionItems={
                            filteredCollectionItems as ICollectionItems[]
                        }
                    />

                    {user && itemsLength > collectionItems.length && (
                        <ShowMoreBtn
                            handleShowMore={() => setPortion(portion + 1)}
                        />
                    )}
                </>
            )}

            {!user ? <FooterPreLogin /> : <MarginBottomDiv />}
        </CommonStyles.PageLayoutWrapper>
    );
};

export default NftsPage;

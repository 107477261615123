import styled from 'styled-components';

import { theme } from 'theme';

export const NotFoundBlock = styled.div`
    height: 100vh;
`;
export const NotFoundMessage = styled.h1`
    margin: 50px 0;
    color: ${theme.colors.dark_grey};
`;

import { BrowserRouter as Router } from 'react-router-dom';

import { ProtectedRoutes } from './protected-route';
import { PreLoginRoutes } from './pre-login-route';

export const MainRouter = () => {
    return (
        <Router>
            <ProtectedRoutes />
            <PreLoginRoutes />
        </Router>
    );
};

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { DEFAULT_USER_MENU_ITEMS } from '../user-dropdown-menu/const';
import { useWeb3Auth } from 'common/services/web3auth';
import { IPropsUserMenu } from './types';
import Person from 'assets/Person.svg';
import LogoutIcon from 'assets/LogoutIcon.svg';

import {
    AuthBtnsContainer,
    UserContainer,
    Username,
    MobileMenuItem,
    UserAction,
    MobileIcon,
    MenuContainer,
    AvatarBurger,
    Container,
    AvatarContainer,
} from './mobile-menu.styled';

export const UserMenu = ({
    setIsHiddenSettings,
    isHiddenSettings,
}: IPropsUserMenu) => {
    const user = useSelector((state: RootState) => state.user.user);
    const { logout } = useWeb3Auth();

    const handleIconClick = () => {
        setIsHiddenSettings(!isHiddenSettings);
    };

    return (
        <AuthBtnsContainer isPerson isOpen={isHiddenSettings}>
            <Container>
                <UserContainer>
                    <AvatarContainer>
                        <AvatarBurger
                            src={user?.avatar || Person}
                            alt="avatar"
                        />
                    </AvatarContainer>

                    <Username>{user?.accountName}</Username>
                    {isHiddenSettings ? (
                        <UpOutlined
                            className="icon-ant"
                            onClick={handleIconClick}
                        />
                    ) : (
                        <DownOutlined
                            className="icon-ant"
                            onClick={handleIconClick}
                        />
                    )}
                </UserContainer>
                {isHiddenSettings && (
                    <MenuContainer>
                        {DEFAULT_USER_MENU_ITEMS.map(
                            ({ label, icon, routerKey }, i) => (
                                <UserAction
                                    to={routerKey}
                                    key={label + routerKey}
                                >
                                    <MobileIcon>{icon}</MobileIcon>
                                    <MobileMenuItem>{label}</MobileMenuItem>
                                </UserAction>
                            )
                        )}
                        <UserAction onClick={logout} to="/login">
                            <MobileIcon isLogout>
                                <img src={LogoutIcon} alt="logout-icon" />
                            </MobileIcon>
                            <MobileMenuItem isLogout>Logout</MobileMenuItem>
                        </UserAction>
                    </MenuContainer>
                )}
            </Container>
        </AuthBtnsContainer>
    );
};

import styled from 'styled-components';

export const AuthBlock = styled.div`
    display: flex;
    width: 100%;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    background: white;

    @media screen and (max-width: 600px) {
        display: flex;
        justify-content: center;
        align-items: start;
        padding: 20px 0 0;
    }

    .block {
        width: 50%;
        margin: auto;
        @media screen and (max-width: 1300px) {
            width: 80%;
        }

        @media screen and (max-width: 600px) {
            width: 100%;
            margin: 0;
        }
    }
`;

export const Icon = styled.img`
    margin: auto;
`;

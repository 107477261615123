import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'theme';

export const WrapperHeader = styled.header<{ isOpacity?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${theme.spaces.xs} 5%;
    color: ${({ isOpacity }) => (isOpacity ? 'white' : 'black')};
    background-color: ${({ isOpacity }) =>
        isOpacity ? 'rgba(0,0,0, 0.1)' : theme.colors.light_red};
    @media screen and (max-width: 1000px) {
        padding: ${theme.spaces.xs} 2%;
    }
`;

export const ButtonsWithLogo = styled.div`
    display: flex;
    width: 50%;
    @media screen and (max-width: 850px) {
        width: 58%;
    }
    @media screen and (max-width: 690px) {
        width: 85%;
    }
`;

export const Logo = styled.img`
    cursor: pointer;
    width: 100px;
    height: 25px;
    margin-right: 5%;
`;

export const HeaderButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderButton = styled(NavLink)<{ coloricons: 'black' | 'white' }>`
    font-family: ${theme.fonts.families.poppins};
    font-style: normal;
    font-weight: ${theme.fonts.weights.medium};
    font-size: 16px;
    color: ${({ coloricons }) =>
        coloricons === 'black' ? theme.colors.dark_grey : 'white'};
    margin: 0 25px;
`;

export const HeaderActiveButton = styled(HeaderButton)`
    display: block;
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        bottom: -19px;
        background: ${theme.colors.dark_grey};
        position: absolute;
    }
`;

import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Header from 'components/header';
import { DEFAULT_BTN_HEADER } from 'common/constants';
import { IPropsHeaderComponent } from '../types';
import { RootState } from 'store';

import {
    SearchInput,
    SearchIcon,
    SearchBlock,
    TextTopBlock,
    TopBlock,
} from '../account-page.styled';

export const HeaderComponent = ({
    children,
    isUserAccount = false,
    onTextInput,
    user,
}: PropsWithChildren<IPropsHeaderComponent>) => {
    const isMobile = useMediaQuery({ query: '(max-width: 430px)' });
    const coloricons = isMobile ? 'black' : 'white';
    const currentUser = useSelector((state: RootState) => state.user.user);

    return (
        <TopBlock>
            <Header
                buttons={DEFAULT_BTN_HEADER}
                coloricons={coloricons}
                isOpacity={!isMobile}
            />
            <TextTopBlock>
                <h1>{user ? user.accountName : currentUser?.accountName}</h1>
                <h2>Home/Designer</h2>
                {(isMobile || isUserAccount) && (
                    <SearchBlock>
                        <SearchInput
                            onChange={(e) => onTextInput?.(e.target.value)}
                            size="large"
                            placeholder="Search"
                            bordered={true}
                            suffix={<SearchIcon />}
                            maxLength={50}
                        />
                    </SearchBlock>
                )}
            </TextTopBlock>
            {children}
        </TopBlock>
    );
};

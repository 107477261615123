import axios from 'axios';

import { TYPE_AUTH } from 'common/services/types';

export const getChainId = () => {
    switch (process.env.REACT_APP_NEXT_PUBLIC_ERC1155_NETWORK) {
        case 'mainnet':
            return '0x1';
        case 'ropsten':
            return '0x3';
        case 'rinkeby':
            return '0x4';
        case 'goerli':
            return '0x5';
        default:
            throw new Error('Invalid network');
    }
};

export const fetchData = async (
    body: Object,
    typeAuth: TYPE_AUTH = 'social'
) => {
    const url = typeAuth === 'social' ? 'sigIn' : typeAuth;

    const { data } = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/user/${url}`,
        data: body,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return data;
};

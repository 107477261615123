import { Spin } from 'antd';
import { useParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

import { CardBox } from './components/card/index';
import { AppDispatch, RootState } from 'store';
import { getCurrentAccount } from 'store/slices/userSlice';
import { getCurrentCollections } from 'store/slices/collectionItemSlice';
import { UserBox } from 'containers/account-page/components/userBox';
import { UserInfo } from './components/userInfo';
import { HeaderComponent } from '../account-page/components/header';
import FooterPreLogin from 'components/footer-pre-login';
import { isAuth } from 'common/helpers';

import * as Styles from './user-account-page.styled';
import * as CommonStyles from 'common/common.styled';

const UserAccountPage = () => {
    const [portion, setPortion] = useState(0);
    const [search, setSearch] = useState('');

    const { id } = useParams<{ id: string }>();
    const currentAccount = useSelector(
        (state: RootState) => state.user.currentAccount
    );
    const collectionItems = useSelector(
        (state: RootState) => state.collectionItems.currentCollections
    );
    const itemsLength = useSelector(
        (state: RootState) => state.collectionItems.currentCollectionsLength
    );

    const dispatch: AppDispatch = useDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 430px)' });

    const sortedCollectionItems = useMemo(
        () =>
            collectionItems.filter((item) =>
                item.name.toLowerCase().includes(search.toLowerCase())
            ),
        [collectionItems, search]
    );

    useEffect(() => {
        dispatch(getCurrentAccount(id));
        dispatch(getCurrentCollections({ id, portion }));
    }, [portion]);

    if (!currentAccount || !collectionItems) {
        return <Spin size="large" />;
    }

    return (
        <CommonStyles.PageLayoutWrapper>
            <HeaderComponent
                user={currentAccount}
                isUserAccount={true}
                onTextInput={setSearch}
            />

            <Styles.ContentBlock>
                <UserBox user={currentAccount}>
                    {!isMobile && <UserInfo user={currentAccount} />}
                </UserBox>
                <CardBox
                    portion={portion}
                    setPortion={setPortion}
                    itemsLength={itemsLength}
                    collectionItems={sortedCollectionItems}
                />
            </Styles.ContentBlock>
            {!isAuth() && <FooterPreLogin />}
        </CommonStyles.PageLayoutWrapper>
    );
};

export default UserAccountPage;

import { toast } from 'react-toastify';

import { LocationProps } from 'common/types';
export { getChainId, fetchData } from './webAuth';
export { getToken, isAuth, clearToken } from './auth';

export const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }

    return e && e.fileList;
};

export const getPathName = (location: LocationProps) => {
    let pathName = '/';

    if (
        location.state &&
        location.state.from.pathname &&
        location.state.from.pathname !== ('login' || 'registration')
    ) {
        pathName = location.state.from.pathname;
    }
    return pathName;
};

export const isBecomeACreatorShown = (
    boolean1: boolean | undefined,
    boolean2: boolean | undefined
) => {
    return boolean1 && boolean2;
};

export const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

export const notify = (
    type: 'error' | 'success' = 'success',
    message: string
) => {
    toast[type](message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        draggable: true,
        progress: undefined,
    });
};

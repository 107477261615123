import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { changeIsLoading } from 'common/helpers/slices';
import { ROUTES_KEYS } from 'common/constants';
import {
    fetchCreateItem,
    fetchData,
    fetchUpdate,
} from 'common/services/apiHelpers';
import {
    IInitialCollectionState,
    ICollection,
    IUpdateOwnerName,
} from 'common/types';

const initialState: IInitialCollectionState = {
    isLoading: false,
    collections: [],
    designers: [],
    allCollections: [],
};

export const setCollections = createAsyncThunk(
    'collections/getCollections',
    async (body: Omit<ICollection, '_id' | 'ownerId' | 'ownerName'>) => {
        const collections = await fetchData(ROUTES_KEYS.COLLECTION);

        return collections && !collections.data.collections.length
            ? {
                  data: {
                      collections: await fetchCreateItem(
                          body,
                          ROUTES_KEYS.COLLECTION
                      ),
                  },
              }
            : collections;
    }
);
export const updateOwnerNameCollections = createAsyncThunk(
    'collections/updateOwnerNameCollections',
    (body: IUpdateOwnerName) => fetchUpdate(body, `${ROUTES_KEYS.COLLECTION}`)
);

export const getDesigners = createAsyncThunk('collections/getDesigners', () =>
    fetchData(`${ROUTES_KEYS.COLLECTION}/designers`)
);

export const getAllCollections = createAsyncThunk(
    'collections/getAllCollections',
    () => fetchData(`${ROUTES_KEYS.COLLECTION}/all`)
);

export const createCollection = createAsyncThunk(
    'collections/createCollection',
    (body: Omit<ICollection, '_id' | 'ownerId' | 'ownerName'>) =>
        fetchCreateItem(body, ROUTES_KEYS.COLLECTION)
);

export const collectionSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        getCollections: (state, action) => {},
        createCollection: (state, action) => {},
        getDesigners: (state, action) => {},
        updateOwnerNameCollections: (state, action) => {},
        getAllCollections: (state, action) => {},
    },
    extraReducers: (builder) => {
        builder

            .addCase(setCollections.pending, (state) => {
                changeIsLoading(state);
            })
            .addCase(setCollections.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.collections = action.payload.data.collections;
                }
            })
            .addCase(updateOwnerNameCollections.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.collections = [
                        ...action.payload.data.updatedCollections,
                    ];
                }
            })
            .addCase(createCollection.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.collections = action.payload.data;
                }
            })
            .addCase(getAllCollections.fulfilled, (state, action) => {
                changeIsLoading(state);
                if (action && action.payload) {
                    state.allCollections = action.payload.data.collections;
                }
            })
            .addCase(getDesigners.fulfilled, (state, action) => {
                if (action && action.payload) {
                    state.designers = [
                        { value: 'All', query: '' },
                        ...action.payload.data,
                    ];
                }
            });
    },
});

export default collectionSlice;

import Person_1 from 'assets/Person_1.jpg';
import Person_2 from 'assets/Person_2.png';
import Person_3 from 'assets/Person_3.jpg';
import Person_4 from 'assets/Person_4.jpg';
import Person_5 from 'assets/Person_5.jpg';
import Person_6 from 'assets/Person_6.jpg';
import Person_7 from 'assets/Person_7.jpg';
import Person_8 from 'assets/Person_8.jpg';
import Person_9 from 'assets/Person_9.jpg';
import Person_10 from 'assets/Person_10.jpg';
import Person_11 from 'assets/Person_11.png';
import Person_13 from 'assets/Person_13.jpg';
import Person_14 from 'assets/Person_14.jpg';
import Person_15 from 'assets/Person_15.jpg';
import Person_16 from 'assets/Person_16.jpg';
import Person_17 from 'assets/Person_17.jpg';

export const RENDER_ICONS = [
    {
        image: Person_1,
    },
    {
        image: Person_2,
    },
    {
        image: Person_3,
    },
    {
        image: Person_4,
    },
    {
        image: Person_5,
    },
    {
        image: Person_6,
    },
    {
        image: Person_7,
    },
    {
        image: Person_8,
    },
    {
        image: Person_9,
    },
    {
        image: Person_10,
    },
    {
        image: Person_11,
    },
    {
        image: Person_1,
    },
    {
        image: Person_13,
    },
    {
        image: Person_14,
    },
    {
        image: Person_15,
    },
    {
        image: Person_16,
    },
    {
        image: Person_17,
    },
];

import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';

import { CardContent } from './cardContent';
import { IPropsCollectionItem } from 'containers/account-page/types';

import {
    CollectionItemBox,
    CollectionVideo,
    CollectionVideoItemBox,
} from './collectionItem.styled';

export const CollectionItem = (props: IPropsCollectionItem) => {
    const [isShowLabel, setIsShowLabel] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
    const history = useHistory();

    return props.contentType !== 'video' ? (
        <CollectionItemBox
            onMouseOver={() => setIsShowLabel(true)}
            onMouseLeave={() => setIsShowLabel(false)}
            src={props.img}
            onClick={() => history.push(`/product/${props.id}`)}
        >
            {(isShowLabel || isTabletOrMobile) && <CardContent {...props} />}
        </CollectionItemBox>
    ) : (
        <CollectionVideoItemBox
            onMouseOver={() => setIsShowLabel(true)}
            onMouseLeave={() => setIsShowLabel(false)}
        >
            <CollectionVideo
                loop
                muted
                playsInline
                controls={false}
                autoPlay
                src={props.img}
                onClick={() => history.push(`/product/${props.id}`)}
            />
            {(isShowLabel || isTabletOrMobile) && <CardContent {...props} />}
        </CollectionVideoItemBox>
    );
};

import { BUTTONS, NOT_ACTIVE_BUTTONS } from '../constants';
import { IPropsRenderButtons } from '../types';

import {
    ButtonsBlock,
    ButtonNotActive,
    ButtonActive,
} from '../product-page.styled';

export const ButtonsRender = ({
    currentBlock,
    setCurrentBlock,
}: IPropsRenderButtons) => {
    return (
        <ButtonsBlock>
            {BUTTONS.map(({ title }) => {
                const handleClick = () => {
                    if (title === 'About' || title === 'Advance') {
                        setCurrentBlock(title);
                    }
                };
                return (
                    <ButtonActive
                        key={title}
                        isActive={title === currentBlock}
                        onClick={handleClick}
                    >
                        {title}
                    </ButtonActive>
                );
            })}
            {NOT_ACTIVE_BUTTONS.map(({ title }) => (
                <ButtonNotActive key={title}>{title}</ButtonNotActive>
            ))}
        </ButtonsBlock>
    );
};

import { useHistory } from 'react-router';
import { IPropsCollectionItem } from 'containers/account-page/types';
import { useHandleLike } from 'hooks/useHandleLike';
import { useEffect, useRef } from 'react';

import Like from 'assets/Like.svg';
import BlueLike from 'assets/BlueLike.svg';
import View from 'assets/View.svg';
import { isAuth } from 'common/helpers';

import {
    CollectionItemBox,
    CollectionImage,
    InfoBlock,
    CardContent,
    InfoBlockItem,
    LikeImg,
    ViewImg,
    NameCollectionItem,
    UserNameCollectionItem,
    ActionButton,
    ItemContent,
    CollectionVideo,
} from './collectionItem.styled';

export const CollectionItem = ({
    img,
    userName,
    name,
    likes,
    views,
    ownerId,
    isAccountPage,
    id,
    myUserId,
    contentType,
}: IPropsCollectionItem) => {
    const video = useRef<any>(null);
    const history = useHistory();
    const { isLiked, handleLikeClick } = useHandleLike(
        id,
        ownerId,
        likes,
        views
    );

    const likeImage = isLiked ? BlueLike : Like;
    const userLink = myUserId === ownerId ? '' : ownerId;
    const isImage = contentType === 'image';

    useEffect(() => {
        if (video.current) {
            video.current.play();
        }
    });
    return (
        <CollectionItemBox isAccountPage={isAccountPage}>
            {isImage ? (
                <CollectionImage
                    onClick={() => history.push(`/product/${id}`)}
                    src={img}
                    className="notDraggable"
                />
            ) : (
                <CollectionVideo
                    onClick={() => history.push(`/product/${id}`)}
                    loop
                    playsInline
                    muted
                    controls={false}
                    autoPlay
                    src={img}
                    ref={video}
                    className="notDraggable"
                />
            )}
            <ItemContent>
                <CardContent>
                    <NameCollectionItem
                        to={`/product/${id}`}
                        className="notDraggable"
                    >
                        {name.length > 24 ? name.slice(0, 24) : name}
                    </NameCollectionItem>
                    <InfoBlock>
                        <InfoBlockItem isLiked={!!isLiked}>
                            <ActionButton
                                disabled={!isAuth()}
                                onClick={handleLikeClick}
                            >
                                <LikeImg
                                    src={likeImage}
                                    alt="likeIcon"
                                    className="notDraggable"
                                />
                            </ActionButton>
                            <span>{likes}</span>
                        </InfoBlockItem>
                        <InfoBlockItem isLiked={false}>
                            <ActionButton disabled={!isAuth()}>
                                <ViewImg
                                    src={View}
                                    alt="viewIcon"
                                    className="notDraggable"
                                />
                            </ActionButton>
                            <span>{views}</span>
                        </InfoBlockItem>
                    </InfoBlock>
                </CardContent>
                <UserNameCollectionItem
                    to={`/account/${userLink}`}
                    className="notDraggable"
                >
                    {userName}
                </UserNameCollectionItem>
            </ItemContent>
        </CollectionItemBox>
    );
};

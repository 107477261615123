import BannerImage from '../../../assets/BannerImage.png';

import * as Styles from '../about-us-page.styled';

export const Banner = () => {
    return (
        <Styles.Banner>
            <Styles.ShadowBox>
                <Styles.InfoWrapper>
                    <Styles.Title>
                        The Heart of the Designer Community
                    </Styles.Title>
                    <Styles.Description>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500
                    </Styles.Description>
                </Styles.InfoWrapper>
                <Styles.BannerImages src={BannerImage} />
            </Styles.ShadowBox>
        </Styles.Banner>
    );
};

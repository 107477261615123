import Styled from 'styled-components';

export const SocialBox = Styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;

    @media screen and (max-width: 600px) {
        gap: 30px
    }

    button {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        border: 1px solid rgba(51, 53, 96, 0.1);
        border-radius: 26px;
    }

`;

import { Dropdown, Space } from 'antd';
import SocialShare from 'assets/SociaIcon.svg';
import {
    ShareImg,
    SocialDropdownContainer,
    SocialElement,
    SocialImg,
} from '../product-page.styled';

import CopyIcon from 'assets/CopyIcon.svg';
import { socialItems } from '../constants';

export const SocialShareDropdown = ({ items = socialItems }) => {
    const setUrlToBuffer = () => {
        navigator.clipboard.writeText(
            window.location.origin + window.location.pathname
        );
    };

    const overlay = (
        <SocialDropdownContainer>
            <SocialElement>
                <SocialImg
                    src={CopyIcon}
                    onClick={setUrlToBuffer}
                    alt="copy"
                    title="copy"
                    className='notDraggable'
                />
            </SocialElement>
            {items.map((item, i) => (
                <SocialElement key={i}>
                    <a
                        target="_blank"
                        href={item.getUrl(
                            window.location.origin + window.location.pathname
                        )}
                        rel="noreferrer"
                        className='notDraggable'
                    >
                        <SocialImg
                            src={item.image}
                            alt={item.alt}
                            title={item.alt}
                            className='notDraggable'
                        />
                    </a>
                </SocialElement>
            ))}
        </SocialDropdownContainer>
    );
    return (
        <Dropdown
            overlay={overlay}
            trigger={['click']}
            placement="topLeft"
            arrow
        >
            <ShareImg src={SocialShare} alt="likeIcon" title="share" className='notDraggable' />
        </Dropdown>
    );
};

import { useDispatch, useSelector } from 'react-redux';
import { useState, useCallback } from 'react';

import { AppDispatch, RootState } from 'store';
import { updateActionsUser, removeActionsUser } from 'store/slices/userSlice';
import { updateActions } from 'store/slices/collectionItemSlice';

export const useHandleLike = (
    id: string,
    ownerId: string,
    likes: number,
    seen: number
) => {
    const user = useSelector((state: RootState) => state.user.user);
    const [isLiked, setIsLiked] = useState(
        user ? user!.likedProducts.includes(id) : false
    );

    const isView = user ? user!.viewedProducts.includes(id) : false;

    const dispatch: AppDispatch = useDispatch();
    const handleLikeClick = useCallback(() => {
        if (isLiked) {
            dispatch(
                removeActionsUser({
                    body: { likedProducts: id },
                    id: ownerId,
                })
            );
            dispatch(
                updateActions({
                    id,
                    likedProducts: likes - 1,
                })
            );
            setIsLiked(false);
        } else {
            dispatch(
                updateActionsUser({
                    body: { likedProducts: id },
                    id: ownerId,
                })
            );
            dispatch(
                updateActions({
                    id,
                    likedProducts: likes + 1,
                })
            );
            setIsLiked(true);
        }
    }, [isLiked, likes]);

    const handleViewChange = () => {
        if (!isView && user) {
            dispatch(
                updateActionsUser({
                    body: { viewedProducts: id },
                    id: user._id,
                })
            );
            dispatch(
                updateActions({
                    id: id,
                    viewedProducts: seen + 1,
                })
            );
        }
    };

    return { isLiked, handleLikeClick, handleViewChange };
};

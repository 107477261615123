import { configureStore } from '@reduxjs/toolkit';
import collectionItemsSlice from './slices/collectionItemSlice';
import collectionSlice from './slices/collectionSlice';
import userSlice from './slices/userSlice';
import countrySlice from './slices/countrySlice';

export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        collections: collectionSlice.reducer,
        collectionItems: collectionItemsSlice.reducer,
        country: countrySlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;

import { Form, Button, Checkbox, Spin } from 'antd';
import { useHistory, useLocation } from 'react-router';
import { WALLET_ADAPTERS } from '@web3auth/base';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useWeb3Auth } from 'common/services/web3auth';
import { LOGIN_WITH_LINKS } from 'components/render-buttons/constants';
import { RenderButtons } from '../../components/render-buttons/renderButtons';
import { FormItem } from 'components/auth/components/FormItem';
import { errorCheck } from 'components/auth/helpers';
import { LocationProps } from 'common/types';
import { getPathName } from 'common/helpers';
import { AuthLayout } from 'components/auth/AuthLayout';
import { RootState } from 'store';

import {
    LoadingContainer,
    LoginLayout as LoginBox,
    NewUserContainer,
} from './login.styled';

export const LoginLayout = () => {
    const isWeb3Initialized = useSelector(
        (state: RootState) => state.user.setIsWeb3Initialized
    );
    const isAuth = useSelector((state: RootState) => state.user.isAuth);

    const { login, isLoading } = useWeb3Auth();
    const [form] = Form.useForm();
    const history = useHistory();
    const location = useLocation() as unknown as LocationProps;

    const pathName = getPathName(location);

    const onFinish = async (values: any) => {
        delete values.remember;
        if (!isLoading) {
            await login(
                WALLET_ADAPTERS.OPENLOGIN,
                'email_passwordless',
                'login',
                values.email
            );
            errorCheck(() => history.push(`${pathName}`), isAuth, form);
        }
    };

    if (!isWeb3Initialized) {
        return (
            <LoadingContainer>
                <Spin />
            </LoadingContainer>
        );
    }

    return (
        <AuthLayout>
            <LoginBox>
                <div className="formBox">
                    <div>
                        <h1>Sign in</h1>
                        <Form
                            form={form}
                            name="normal_login"
                            className="login-form form"
                            onFinish={onFinish}
                        >
                            <label className="textColor font-16">
                                Email address
                            </label>
                            <FormItem
                                name="email"
                                message="Please enter your valid email"
                                placeholder="youremail@email.com"
                                type="email"
                            />
                            <Form.Item>
                                <div className="textColor checkBox">
                                    <Form.Item
                                        name="remember"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Checkbox className="textSmall">
                                            <span className="textColor font-16">
                                                Remember me
                                            </span>
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </Form.Item>

                            <Form.Item>
                                <div className="buttonBox">
                                    <Button
                                        disabled={!isWeb3Initialized}
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        size="large"
                                    >
                                        Log in
                                    </Button>
                                    <span className="textColor">Or</span>

                                    <RenderButtons
                                        buttons={LOGIN_WITH_LINKS}
                                        path={pathName}
                                    />
                                </div>
                            </Form.Item>
                        </Form>
                        <NewUserContainer>
                            New user?
                            <pre> </pre>
                            <Link to="/registration">Create an Account</Link>
                        </NewUserContainer>
                    </div>
                </div>
            </LoginBox>
        </AuthLayout>
    );
};

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'theme';

export const BoxRenderCollectionItems = styled.div<{ isAccountPage: boolean }>`
    width: 100%;
    display: grid;
    gap: ${theme.spaces.xl};
    grid-template-columns: ${({ isAccountPage }) =>
        isAccountPage ? '1fr 1fr 1fr' : ' 1fr 1fr 1fr 1fr'};
    background-color: #fff;

    @media screen and (max-width: 1650px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 1450px) {
        grid-template-columns: ${({ isAccountPage }) =>
            isAccountPage ? '1fr 1fr' : ' 1fr 1fr 1fr'};
    }

    @media screen and (max-width: 1380px) {
        grid-template-columns: ${({ isAccountPage }) =>
            isAccountPage ? '1fr 1fr' : '1fr 1fr 1fr'};
    }

    @media screen and (max-width: 1280px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 1110px) {
        grid-template-columns: ${({ isAccountPage }) =>
            isAccountPage ? '1fr ' : '1fr 1fr'};
    }

    @media screen and (max-width: 780px) {
        grid-template-columns: 1fr;
        gap: ${({ isAccountPage }) =>
            isAccountPage ? theme.spaces.md : theme.spaces.xl};
        width: 100%;
    }

    @media screen and (max-width: 710px) {
        grid-template-columns: 1fr;
        gap: ${theme.spaces.md};
        width: 100%;
    }

    @media screen and (max-width: 580px) {
        margin-left: 0;
        width: 100%;
    }
`;

export const CollectionItemBox = styled.div<{
    isAccountPage: boolean | undefined;
}>`
    width: 100%;
    max-width: 400px;
    max-height: 370px;
    min-width: 380px;
    height: 333px;
    background: ${theme.colors.background_grey};
    display: flex;
    flex-direction: column;
    margin: ${theme.spaces.sm};
    font-color: ${theme.colors.dark_grey};
    padding: ${theme.spaces.sm};
    border-radius: ${theme.spaces.xxs};
    justify-content: space-between;
    margin: 0 auto !important;
    @media screen and (max-width: 1850px) {
        height: ${({ isAccountPage }) => (isAccountPage ? '270px' : '300px')};
        min-width: ${({ isAccountPage }) =>
            isAccountPage ? '325px' : '325px'};
        max-width: ${({ isAccountPage }) =>
            isAccountPage ? '345px' : '390px'};
    }
    @media screen and (max-width: 1650px) {
        height: ${({ isAccountPage }) => (isAccountPage ? '265px' : '360px')};
        min-width: ${({ isAccountPage }) =>
            isAccountPage ? '300px' : '390px'};
        max-width: ${({ isAccountPage }) =>
            isAccountPage ? '320px' : '460px'};
    }
    @media screen and (max-width: 1550px) {
        height: ${({ isAccountPage }) => (isAccountPage ? '260px' : '340px')};
        min-width: ${({ isAccountPage }) =>
            isAccountPage ? '270px' : '390px'};
        max-width: ${({ isAccountPage }) =>
            isAccountPage ? '280px' : '420px'};
    }
    @media screen and (max-width: 1450px) {
        height: ${({ isAccountPage }) => (isAccountPage ? '320px' : '320px')};
        min-width: ${({ isAccountPage }) =>
            isAccountPage ? '370px' : '370px'};
        max-width: ${({ isAccountPage }) =>
            isAccountPage ? '390px' : '390px'};
    }
    @media screen and (max-width: 1380px) {
        height: ${({ isAccountPage }) => (isAccountPage ? '280px' : '340px')};
        min-width: ${({ isAccountPage }) =>
            isAccountPage ? '295px' : '390px'};
        max-width: ${({ isAccountPage }) =>
            isAccountPage ? '325px' : '460px'};
    }
    @media screen and (max-width: 1190px) {
        height: ${({ isAccountPage }) => (isAccountPage ? '250px' : '340px')};
        min-width: ${({ isAccountPage }) =>
            isAccountPage ? '260px' : '390px'};
        max-width: ${({ isAccountPage }) =>
            isAccountPage ? '280px' : '420px'};
    }

    @media screen and (max-width: 1110px) {
        height: ${({ isAccountPage }) => (isAccountPage ? '290px' : '325px')};
        min-width: ${({ isAccountPage }) =>
            isAccountPage ? '355px' : '370px'};
        max-width: ${({ isAccountPage }) =>
            isAccountPage ? '375px' : '420px'};
    }

    @media screen and (max-width: 1060px) {
        height: 290px;
        min-width: 350px;
        max-width: 375px;
    }

    @media screen and (max-width: 780px) {
        height: ${({ isAccountPage }) => (isAccountPage ? '290px' : '340px')};
        min-width: ${({ isAccountPage }) =>
            isAccountPage ? '355px' : '390px'};
        max-width: ${({ isAccountPage }) =>
            isAccountPage ? '375px' : '420px'};
    }

    @media screen and (max-width: 580px) {
        min-height: 340px;
        max-height: 350px;
        min-width: 400px;
        max-width: 420px;
    }
    @media screen and (max-width: 460px) {
        min-height: 280px;
        max-height: 290px;
        min-width: 355px;
        max-width: 375px;
    }
    @media screen and (max-width: 400px) {
        min-height: 240px;
        max-height: 260px;
        min-width: 90%;
        max-width: 90%;
    }
`;

export const CollectionImage = styled.div<{ src: string }>`
    width: 100%;
    overflow: hidden;
    height: 90%;
    border-radius: ${theme.spaces.xxs};
    background-image: url(${({ src }) => src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const CollectionVideo = styled.video`
    width: 100%;
    overflow: hidden;
    height: 90%;
    border: none;
    object-fit: cover;
    border-radius: ${theme.spaces.xxs};
`;

export const CardContent = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spaces.sm};
`;

export const InfoBlock = styled.div`
    display: flex;
    min-width: 25%;
    justify-content: space-between;
`;

export const InfoBlockItem = styled.div<{ isLiked: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: ${theme.spaces.sm};

    span {
        color: ${({ isLiked }) =>
            isLiked ? 'rgba(81, 88, 255, 1)' : 'rgba(51, 53, 96, 1)'};
        font-weight: ${theme.fonts.weights.regular};
        font-size: ${theme.fonts.sizes.xxxs};
        line-height: 18px;
        margin-left: ${theme.spaces.xxs};
        @media screen and (max-width: 1100px) {
            font-size: 10px;
            line-height: 15px;
        }
    }
`;

export const ActionButton = styled.button`
    background: none;
    border: none;
`;

export const LikeImg = styled.img`
    width: 12px;
    height: 12px;
    z-index: 3;
`;
export const ViewImg = styled.img`
    width: 17px;
    height: 10px;
    z-index: 4;
`;

export const NameCollectionItem = styled(Link)`
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${theme.fonts.sizes.xs};
    line-height: 28px;
    color: ${theme.colors.dark_grey};
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 1100px) {
        font-size: ${theme.fonts.sizes.xxs};
    }
    @media screen and (max-width: 1100px) {
        font-size: 14px;
        line-height: 21px;
    }
`;

export const UserNameCollectionItem = styled(Link)`
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxxs};
    line-height: 18px;
    color: ${theme.colors.dark_grey};
    padding-top: ${theme.spaces.xxs};
    @media screen and (max-width: 1100px) {
        font-size: ${theme.fonts.sizes.xxxs};
    }

    @media screen and (max-width: 1100px) {
        font-size: 10px;
        line-height: 15px;
    }
`;

export const ItemContent = styled.div`
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

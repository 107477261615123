import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { IFormItemProps } from 'containers/create-page/types';
import { ICollection } from 'common/types';
import { validateNewCollection } from 'containers/create-page/helpers';

import {
    OptionField,
    SelectField,
    ErrorMessage,
} from 'containers/create-page/create-page.styled';

export const FormSelectItem = ({
    name,
    message,
    placeholder,
    optionsArray,
    isCreateNft,
    setIsError,
    isError,
}: IFormItemProps) => {
    const allCollections: ICollection[] = useSelector(
        (state: RootState) => state.collections.allCollections
    );

    const [data, setData] = useState<any[]>([]);
    const [value, setValue] = useState<string>();

    const handleChange = (newValue: any) => {
        if (data.length && newValue === data[0].name) {
            validateNewCollection(
                newValue,
                setIsError,
                allCollections,
                isCreateNft
            );
        } else {
            setIsError('');
        }
        setValue(newValue);
    };

    const handleSearch = (newValue: any) => {
        if (newValue) {
            const isExist = optionsArray.find(({ name }) =>
                name
                    .toLocaleLowerCase()
                    .startsWith(newValue.toLocaleLowerCase())
            );

            isExist ? setData([]) : setData([{ name: newValue }]);
        }
        if (data.length) {
            validateNewCollection(
                data[0].name,
                setIsError,
                allCollections,
                isCreateNft
            );
        }
    };
    useEffect(() => {
        setValue(undefined);
    }, [isCreateNft]);

    const options = data.map((item, i) => (
        <OptionField key={item} value={item.name}>
            {item.name}
        </OptionField>
    ));
    const placement = optionsArray.length > 2 ? 'topLeft' : 'bottomLeft';
    return (
        <>
            <Form.Item
                name={name}
                rules={[
                    {
                        required: true,
                        message: message,
                    },
                ]}
            >
                {optionsArray.length && (
                    <SelectField
                        placement={placement}
                        virtual={false}
                        showSearch
                        value={value}
                        onSearch={handleSearch}
                        onChange={handleChange}
                        notFoundContent={null}
                        placeholder={placeholder}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option!.children as unknown as string).includes(
                                input
                            )
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA!.children as unknown as string)
                                .toLowerCase()
                                .localeCompare(
                                    (
                                        optionB!.children as unknown as string
                                    ).toLowerCase()
                                )
                        }
                    >
                        {optionsArray.map((item, i) => {
                            return (
                                <OptionField key={i} value={item.name}>
                                    {item.name}
                                </OptionField>
                            );
                        })}
                        {isCreateNft && options}
                    </SelectField>
                )}
            </Form.Item>
            {isError && <ErrorMessage>{isError}</ErrorMessage>}
        </>
    );
};

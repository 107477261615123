import { Button, Select } from 'antd';
import styled from 'styled-components';

import { theme } from 'theme';
import { ReactComponent as SearchIconSVG } from '../../../../assets/SearchIconSVG.svg';

export const SearchWithAvatar = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: ${theme.spaces.xxs};
    @media screen and (max-width: 850px) {
        width: 40%;
    }
`;

export const SearchInput = styled(Select)`
    width: 20vw;
    min-width: 100px;
    align-self: center;
    size: large;
    font-family: ${theme.fonts.families.poppins};
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${theme.fonts.sizes.xxs};
    & .ant-select-selector {
        border-radius: 50px !important;
        border: 1.2px solid #dddddd !important;
        background-color: rgba(238, 238, 238, 0.3) !important;
    }
    & .anticon {
        background-color: rgba(58, 58, 58, 0);
    }

    @media screen and (max-width: 750px) {
        display: none;
    }
`;

export const Icon = styled.img`
    margin-left: ${theme.spaces.sm};
    width: 15px;
`;

export const UploadButton = styled(Button)<{ isMobile?: boolean }>`
    width: 134px;
    height: 40px;
    background-color: ${theme.colors.light_purple};
    border-radius: ${theme.spaces.xxxl};
    color: ${theme.colors.primary_white};
    font-weight: ${theme.fonts.weights.medium};
    font-size: ${({ isMobile }) => (isMobile ? `16px` : '100%')};
    font-family: ${theme.fonts.families.poppins};
    margin: ${({ isMobile }) => (isMobile ? `${theme.spaces.md} 0` : '0')};
    font-size: 16px;
`;

export const LogoutButton = styled.button`
    background: ${theme.colors.shadow_grey};
    border-radius: ${theme.spaces.xxxl};
    font-weight: ${theme.fonts.weights.medium};
    color: black;
    padding: ${theme.spaces.xxs} ${theme.spaces.sm};
    margin: ${theme.spaces.xxs};
    border: none;
    &: hover {
        color: white;
        background: ${theme.colors.light_purple};
    }
`;

export const SearchIcon = styled(SearchIconSVG)`
    width: 15px;
    height: 15px;

    & path {
        fill: ${theme.colors.shadow_grey};
    }
`;
